import { ReactSVG } from 'react-svg';
import styled from 'styled-components';
import { ModalTitle } from '@common/features/productGroup/styles';

export const ModalIcon = styled(ReactSVG)`
  margin: 20px 0;
`;

export const ModalContent = styled.div`
  display: flex;
  flex-flow: column;
  max-width: 430px;

  ${ModalTitle} {
    margin-bottom: 10px;
  }

  .tooltip {
    max-width: 330px;
    text-align: left !important;
  }
`;

export const ModalButtons = styled.div`
  display: flex;
  justify-content: right;
  margin-top: 20px;
`;

export const Description = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.small};
  color: ${({ theme }) => theme.elements.textDarkGrey};
  margin-top: 6px;
  margin-bottom: 16px;
`;
