import styled from 'styled-components';
import { StyledSVG } from '@common/styles';
import { StyledLink } from '@components/Link/styles';

interface IUploadedFileWrapper {
  active: boolean;
}

export const UploadedFileWrapper = styled.div<IUploadedFileWrapper>`
  display: flex;
  align-items: center;
  padding: 15px 0 15px 20px;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.elements.disabledInputBg};  
  

  ${StyledLink} {
    margin-left: 10px;
    margin-right:15px;
    font-size: ${({ theme }) => theme.fontSizes.small};
    pointer-events: ${(props) => (props.active ? 'auto' : 'none')};
    text-decoration: ${(props) => (props.active ? 'underline' : 'none')};;
  }

  ${StyledSVG} {
    fill: ${({ theme }) => theme.colors.mediumGrey};
  }
`;

export const UploadedFileWrapperContainer = styled.div`
  display: flex;
  flex-direction:row;
  flex-wrap: wrap;
  gap: 10px;
  width:100%;
  align-items: center;`  
;
