import { RegisterOptions, useFormContext } from 'react-hook-form';
import { HookFormInput } from '../FormInput/HookFormInput';
import { Title, Text, InputWrap } from '@common/styles';
import { generalFormErrors } from '@common/constants';

interface IFormDocumentTitle {
  fieldName: string;
  onClearInput: () => void;
  rules?: RegisterOptions<any, string>;
  title: string;
}

const FormDocumentTitle = ({ fieldName, onClearInput, rules, title }: IFormDocumentTitle) => {
  const {
    formState: { errors },
  } = useFormContext();

  return (
    <>
      <Title small>{title}</Title>
      <Text>Please complete the document title (e.g. include brand name, strength(s) and form)</Text>
      <InputWrap>
        <HookFormInput
          label="Document title"
          labelEndSign="*"
          name={fieldName}
          id="documentTitleInput"
          placeholder="Document title"
          onClearInput={onClearInput}
          width={389}
          height={48}
          error={errors[fieldName]?.message?.toString()}
          rules={{
            ...rules,
            maxLength: {
              value: 200,
              message: generalFormErrors.documentTitleLength,
            }
          }}
          data-testid="document-title-input"
        />
      </InputWrap>
    </>
  );
};

export default FormDocumentTitle;
