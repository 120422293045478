import React from 'react';
import { checkIfItemIsEmpty } from '@common/utils/utils';
import { ProductForm } from '@common/features/product/components/ProductContent/hooks/useProductForm';
import { StyledSVG } from '@common/styles';
import * as Styled from './styles';

interface ISidebarEntry {
  anchor: string;
  name: string;
  formValues: any;
  onClick: (e: React.MouseEvent<HTMLAnchorElement>) => void;
  disabled?: boolean;
}

export const SidebarEntry = ({ anchor, name, formValues, disabled, onClick }: ISidebarEntry) => {
  const shouldRenderWarningIcon = () => {
    if (anchor === ProductForm.contactGroup) return;

    if (anchor === ProductForm.maNumbers) {
      return (
        checkIfItemIsEmpty(formValues.productDetails.maNumbersForm[anchor]) &&
        formValues.productDetails.maNumbersForm[ProductForm.noMaNumbers] === false
      );
    }
    if (anchor === ProductForm.atcCodes) {
      return (
        checkIfItemIsEmpty(formValues.productDetails.atcCodesForm[anchor]) &&
        formValues.productDetails.atcCodesForm[ProductForm.noAtcCodes] === false
      );
    }
    if (anchor === ProductForm.ampIds) {
      return (
        checkIfItemIsEmpty(formValues.productDetails.ampIdForm[anchor]) &&
        formValues.productDetails.ampIdForm[ProductForm.noAmpIds] === false
      );
    }
    return checkIfItemIsEmpty(formValues.productDetails[anchor]);
  };

  return (
    <Styled.SidebarLink isActive={false} to={`#${anchor}`} onClick={onClick}>
      {name}
      {!disabled && shouldRenderWarningIcon() && <StyledSVG src="/icons/small_warning_icon.svg" />}
    </Styled.SidebarLink>
  );
};
