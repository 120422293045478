import { StyledLink } from '@common/components/Link/styles';
import { StyledSVG } from '@common/styles';
import { Table } from '@common/components/DatapharmTable/styles';
import styled, { css } from 'styled-components';

export const TabContentWrap = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  padding: 40px 60px;
  background-color: ${({ theme }) => theme.elements.defaultWhite};
`;

export const Title = styled.div`
  display: flex;
  font-size: ${({ theme }) => theme.elementFontSizes.sectionTitle};
  font-weight: bold;
  margin-bottom: 5px;
`;

export const Heading = styled.div`
  display: flex;
  flex-flow: column;
  margin-bottom: 25px;
`;

export const Content = styled.div`
  display: flex;
  height: 100%;
`;

export const SectionWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 1.5rem;
  width: 100%;
`;

export const TableWrapper = styled.div`
  overflow: auto;
  max-height: 40rem;
  width: 100%;

  ${Table} {
    width: 100%;
    th:first-child,
    tr td:first-child {
      text-align: center;
    }
  }

  ${StyledSVG} {
    display: flex;
    align-items: center;
    align-self: baseline;

    * {
      margin: 0px;
    }
  }

  ${StyledLink} {
    text-decoration: underline;
  }
`;
