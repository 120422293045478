import React, { useRef, useState, SyntheticEvent } from 'react';
import { ReactSVG } from 'react-svg';
import { FileDrop } from 'react-file-drop';
import { Wrap, Text, SubText, StyledInput } from './styles';

interface IFileInput {
  onFileSubmit: (files: FileList | null, event: SyntheticEvent) => void;
  subText?: string;
  disabled?: boolean;
}

export const DragAndDropFileInput = ({ onFileSubmit, subText, disabled }: IFileInput) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [dragActive, setDragActive] = useState(false);
  const onWrapClick = () => {
    if (disabled) {
      return;
    }
    inputRef?.current?.click();
  };

  const onFileInputChange = (event: SyntheticEvent | any) => {
    if (disabled) {
      return;
    }

    const { files } = event.target;
    handleFileSubmit(files, event);
    event.target.value = null;
  };

  const handleFileSubmit = (files: any, event: SyntheticEvent | any) => {
    if (disabled) {
      return;
    }

    onFileSubmit(files, event);
  };

  return (
    <Wrap dragActive={dragActive} onClick={onWrapClick}>
      <FileDrop
        onDragOver={() => setDragActive(true)}
        onDragLeave={() => setDragActive(false)}
        className="fileDrop"
        onDrop={handleFileSubmit}
      >
        <ReactSVG src={`/icons/upload.svg`} />
        <Text>
          Drag & Drop or <span>Choose file</span> to upload
        </Text>
        {subText && <SubText>{subText}</SubText>}
        <StyledInput onChange={onFileInputChange} hidden ref={inputRef} />
      </FileDrop>
    </Wrap>
  );
};
