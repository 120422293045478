import Divider from '../Divider/Divider';
import * as Styled from './styles';
import { Button, ButtonTypes, ModalWrapper } from '@common/components';

interface IProps {
  onCancel: () => void;
  onConfirm: () => void;
  isLoading?: boolean;
  productName: string;
}

export const RetireProductModal = ({ onCancel, onConfirm, isLoading, productName }: IProps) => {
  return (
    <ModalWrapper isOpen={true} contentStyles={{ width: '35rem' }}>
      <Styled.TitleIcon src={'/icons/warning-sign.svg'} />
      <Styled.Title>Confirm product retirement</Styled.Title>
      <Styled.Subtext>You are about to retire {productName}, do you want to continue?</Styled.Subtext>
      <Styled.Footer>
        <Button onClick={onCancel} text="Cancel" type={ButtonTypes.TEXT} />
        <Divider type="vertical" size="30px" />
        <Button onClick={onConfirm} text="Confirm" type={ButtonTypes.PRIMARY} isLoading={isLoading} />
      </Styled.Footer>
    </ModalWrapper>
  );
};
