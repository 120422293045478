import React from 'react';
import { useFetchSubmission } from '@common/features/submission/hooks/useFetchSubmission';
import SubmissionProductContent from '@common/features/product/components/ProductContent/SubmissionProductContent';
import { useParams } from 'react-router';
import useAppNavigation from '../../routing/useAppNavigation';
import { ReadonlyProductContent } from '@common/features/product/components/ProductContent/ReadOnlyProductContent';
import ProductContentSkeletonLoader from '@common/features/product/components/ProductContent/ProductContentSkeletonLoader';
import {
  getSingleProduct,
  isProductDiscontinued,
  isProductRetired,
} from '@common/features/product/components/ProductContent/helpers';
import { AssociatedContentReadonlySection } from '@common/features/product/components/ProductContent/types';

interface ISubmissionProductDetails {
  readonly?: boolean;
}

export const SubmissionProduct = ({ readonly }: ISubmissionProductDetails) => {
  const { goToProductGroup } = useAppNavigation();
  const { submissionId = '', productId = '' } = useParams();

  const { submission, isFetchingSubmission, isSubmissionFetched } = useFetchSubmission(submissionId);

  const handleRedirectToProductGroup = () => {
    goToProductGroup(submissionId);
  };

  const product = getSingleProduct(productId, submission?.products!);
  const isDiscontinued = isProductDiscontinued(product!);
  const isRetired = isProductRetired(product!);

  const associatedContentReadonlySections = {
    [AssociatedContentReadonlySection.editDocumentName]: true,
    [AssociatedContentReadonlySection.secondaryDocumentsSelection]: isRetired,
  };

  const shouldBeEditable = submission?.isEditable;

  if (!isSubmissionFetched || isFetchingSubmission) {
    return <ProductContentSkeletonLoader />;
  }

  if (!readonly && shouldBeEditable) {
    return (
      <SubmissionProductContent
        product={product}
        submission={submission}
        onCancel={handleRedirectToProductGroup}
        onResultSuccess={handleRedirectToProductGroup}
        shouldDisplayAmpIds={false}
        shouldDisplayContactGroup={false}
        associatedContentReadonlySections={associatedContentReadonlySections}
        isProductDiscontinued={isDiscontinued}
        isProductRetired={isRetired}
      />
    );
  }

  return (
    <ReadonlyProductContent
      shouldDisplayAmpIds={false}
      shouldDisplayContactGroup={false}
      dataSource={submission!}
      productId={productId}
      onCancel={handleRedirectToProductGroup}
    />
  );
};
