import { createColumnHelper } from '@tanstack/react-table';
import { Controller, useFormContext } from 'react-hook-form';
import { ProductSection } from '../ProductSection/ProductSection';
import { Button, ButtonTypes, Input } from '@common/components';
import { ErrorMessage } from '../ErrorMessage';
import DatapharmTable from '@components/DatapharmTable/DatapharmTable';
import { IContractGroupDto } from '@common/features/submission/types';
import { StyledSVG } from '@common/styles';
import * as Styled from './styles';

import * as CommonStyles from '../styles';
import { IProductForm, ProductFormPaths } from '@common/features/product/components/ProductContent/hooks/useProductForm';
import { generalFormErrors } from '@common/constants';

interface IContactGroup {
  elementId: string;
  contactGroup: string | null;
  contractGroupToAdd: string;
  readonly?: boolean;
  onContractGroupAdd: () => void;
  onContractRemove: (index: number) => void;
}

export const ContactGroup = ({ elementId, contactGroup, onContractGroupAdd, onContractRemove, readonly }: IContactGroup) => {
  const {
    control,
    formState: { errors },
  } = useFormContext<IProductForm>();

  const columnHelper = createColumnHelper<IContractGroupDto>();

  const columns = [
    columnHelper.accessor('code', {
      header: 'Code',
    }),
    ...(readonly
      ? []
      : [
          columnHelper.display({
            header: 'Action',
            cell: (item) => {
              return (
                <CommonStyles.RemoveButton onClick={() => onContractRemove(parseInt(item.cell.id))}>
                  <StyledSVG src={`/icons/trash_bin.svg`} />
                </CommonStyles.RemoveButton>
              );
            },
          }),
        ]),
  ];

  return (
    <ProductSection
      elementId={elementId}
      heading="Contact group"
      subtext=""
    >
      <Styled.ContractGroupWrap>
        {!readonly && (
          <>
            <CommonStyles.InputButtonWrapper>
              <Controller
                control={control}
                name={ProductFormPaths.contactGroupInput}
                rules={{ maxLength: { value: 200, message: generalFormErrors.contactGroupMaxLength } }}
                render={({ field: { value = '', onChange } }) => (
                  <Input  label="Enter contact group" name="Contract Group" value={value} onChange={onChange} />
                )}
              />
              <Button disabled={!!contactGroup} text="Add" type={ButtonTypes.PRIMARY_BORDER} onClick={onContractGroupAdd} />
            </CommonStyles.InputButtonWrapper>
            {errors?.productDetails?.contactGroupForm?.contactGroupInput?.message && <ErrorMessage>{errors?.productDetails?.contactGroupForm?.contactGroupInput.message?.toString()}</ErrorMessage>}
          </>
        )}
        {!!contactGroup && <DatapharmTable columns={columns} documents={[ { code : contactGroup }]} />}
      </Styled.ContractGroupWrap>
    </ProductSection>
  );
};
