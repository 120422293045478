import { useFetchSubmission } from '@common/features/submission/hooks/useFetchSubmission';
import { useFetchTaskProductGroupData } from '@common/features/submissionTask/hooks/useFetchTaskProductGroupData';
import { useFetchSubmissionTaskData } from '@common/hooks/useFetchSubmissionTaskData';
import React from 'react';
import { useParams } from 'react-router';
import { TaskDetails } from './TaskDetails';
import { SubmissionTaskSkeletonLoader } from '@common/features/submission/SubmissionTaskDetails/components';

export const TaskDetailsWrapper = () => {
  const { submissionTaskId = '' } = useParams();
  const { submissionTask, isFetchingSubmissionTaskData, refetchSubmissionTaskData } = useFetchSubmissionTaskData(submissionTaskId as string);
  const { submission, isFetchingSubmission, refetchFetchSubmission } = useFetchSubmission(submissionTask?.submissionId.toString() as string);
  const { productGroupWithTaskApplied, isFetchingTaskProductGroup, refetchTaskProductGroupData } = useFetchTaskProductGroupData(
    submissionTaskId as string,
  );
  const submissionId = submissionTask?.submissionId.toString();

  const isLoading = isFetchingSubmissionTaskData || isFetchingSubmission || isFetchingTaskProductGroup;

  const refetchData = () => {
    refetchFetchSubmission();
    refetchTaskProductGroupData();
    refetchSubmissionTaskData();
  }

  if (isLoading) {
    return <SubmissionTaskSkeletonLoader />;
  }

  return (
    <TaskDetails
      taskId={submissionTaskId}
      submissionId={submissionId!}
      submission={submission}
      submissionTask={submissionTask}
      productGroupWithTaskApplied={productGroupWithTaskApplied}
      onDocumentAssociationSuccess={refetchTaskProductGroupData}
      onProductGroupUpdateSuccess={refetchData}
    />
  );
};
