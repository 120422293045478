import { Select } from '@common/components/Select/styles';
import styled from 'styled-components';

export const UpdateProductGroupModal = styled.div`
  height: 100%;
  display: flex;
  flex-flow: column;
  min-width: 400px;
  width: 700px;

  ${Select} {
    width: 350px;
  }
`;

export const ModalContent = styled.div`
  overflow: auto;
  display: flex;
  flex-flow: column;
  max-height: 80vh;
  row-gap: 30px;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const ModalTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const TitleText = styled.span`
  font-size: ${({ theme }) => theme.elementFontSizes.modalTitle};
  font-weight: bold;
`;

export const HeadingText = styled.h3`
  font-size: ${({ theme }) => theme.elementFontSizes.subSectionTitle};
  color: ${({ theme }) => theme.elements.submissionHeading};
  margin-bottom: 5px;
`;

export const ModalEntry = styled.div`
  margin-bottom: 10px;
`;

export const ModalError = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.red};
  font-size: 14px;
`;

export const ModalFooter = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

export const ModalButtons = styled.div`
  display: flex;
  justify-content: right;
`;
