import { EmcProductPanelWrapper, EmcProductPanelTitle } from './styles';
import { CopyToClipboardWrapper } from './CopyToClipboardWrapper';

interface IEmcProductLinkPanel {
  productUrl: string;
}

const EmcProductLinkPanel = ({ productUrl }: IEmcProductLinkPanel) => {
  return (
    <EmcProductPanelWrapper>
      <EmcProductPanelTitle>emc product url</EmcProductPanelTitle>
      <CopyToClipboardWrapper displayText={productUrl} />
    </EmcProductPanelWrapper>
  );
};

export default EmcProductLinkPanel;
