import { ICheckboxEntry } from '@common/types';
import { CheckboxOption } from '../CheckboxOption/CheckboxOption';
import { FiltersItem } from './FiltersItem';

interface IProps {
  options: any[];
  label: string;
  values: ICheckboxEntry[];
  'data-testid'?: string;
  onChange: (item: ICheckboxEntry) => void;
  onClear: () => void;
}

export const CheckboxFilter = ({ options, label, values, onChange, onClear, ...props }: IProps) => {
  return (
    <FiltersItem label={label} onClear={onClear} appliedFiltersCount={values.length} data-testid={props['data-testid']}>
      {options.map((item) => {
        const isSelected = !!values.find((v) => v.id === item.id);

        return (
          <CheckboxOption
            key={item.id}
            name={`submission task type ${item.id}`}
            item={item}
            onChange={onChange}
            isChecked={isSelected}
          />
        );
      })}
    </FiltersItem>
  );
};
