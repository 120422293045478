export enum SubmissionTaskStatusLabels {
  None = 'None',
  Draft = 'Draft',
  WithProduction = 'With production',
  WithQC = 'With QC',
  WithDataQuality = 'With data quality',
  WithClientMoreInformation = 'With Client - More information',
  WithClientApproval = 'With Client - Approval',
  Approved = 'Approved',
  Cancelled = 'Cancelled',
  Published = 'Published',
}

export enum SubmissionTaskStatusCustomerLabels {
  None = 'None',
  NeedsAttention = 'Needs attention',
  WithDatapharm = 'With datapharm',
  NeedsApproval = 'Needs approval',
  Approved = 'Approved',
  Published = 'Published',
  Cancelled = 'Cancelled',
}