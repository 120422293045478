import * as Styled from './styles';

interface ISideDecorationContent {
  children: JSX.Element | (JSX.Element | undefined | null)[];
}

export const SideDecorationContent = ({ children }: ISideDecorationContent) => {
  const renderDecoratedComponents = () => {
    if (Array.isArray(children)) {
      return children.map(
        (child, index) =>
          !!child && (
            <Styled.DecoratedContentItem key={index}>
              <Styled.DecoratedContentInner>
                <Styled.DecoratedContentItemIcon src={`/icons/side_decoration.svg`} />
                {child}
              </Styled.DecoratedContentInner>
            </Styled.DecoratedContentItem>
          ),
      );
    } else {
      return (
        <Styled.DecoratedContentItem>
          <Styled.DecoratedContentInner>
            <Styled.DecoratedContentItemIcon src={`/icons/side_decoration.svg`} />
            {children}
          </Styled.DecoratedContentInner>
        </Styled.DecoratedContentItem>
      );
    }
  };

  return (
    <Styled.ContentWithDecorationWrap>
      <Styled.DecoratedContentWrap>{renderDecoratedComponents()}</Styled.DecoratedContentWrap>
    </Styled.ContentWithDecorationWrap>
  );
};
