import styled, { css } from 'styled-components';

interface ISelect {
  inlineLabel?: boolean;
}

export const Select = styled.div<ISelect>`
  display: flex;

  ${({ inlineLabel = false }) =>
    inlineLabel
      ? css`
          flex-flow: row;
          align-items: center;
          column-gap: 1rem;
        `
      : css`
          flex-flow: column;
        `}
`;

export const StyledLabel = styled.label`
  font-size: ${({ theme }) => theme.elementFontSizes.label};
  margin-bottom: 5px;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

interface ICustomOption {
  isFocused: boolean;
}

export const CustomOption = styled.div<ICustomOption>`
  border: 2px solid ${({ isFocused }) => (isFocused ? 'white' : 'gray')};
  border-radius: 10px;
  padding: 0.25rem;
  font-size: 12px;
  font-weight: bold;
  color: ${({ isFocused }) => (isFocused ? 'white' : 'gray')};
  display: inline-block;
`;

export const DisplayValue = styled.div`
  font-weight: 600;
`;
