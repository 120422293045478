import { buildUrl, handleFileRequest, handleRequest } from '@common/services/requestHelper';
import { RequestType } from '@common/services/types';
import { DocumentType, Id } from '@common/types';

const basePath = process.env.REACT_APP_API_PATH;

const fetchSubmissionTaskList = async (token: string, queryString: string) => {
  const url = buildUrl('/submission-task', basePath, queryString);

  return handleRequest({ token, url, requestType: RequestType.GET });
};

const fetchSubmissionTaskData = async (token: string, taskId: string) => {
  const url = buildUrl(`/submission-task/${taskId}`, basePath);

  return handleRequest({ token, url, requestType: RequestType.GET });
};

const fetchSubmissionTaskNextStatuses = async (token: string, taskId: Id, queryString: string) => {
  const url = buildUrl(`/submission-task/${taskId}/next-statuses`, basePath, queryString);

  return handleRequest({ token, url, requestType: RequestType.GET });
};

const putSubmissionTaskStatus = async (token: string, taskId: string, dataSet: unknown) => {
  const url = buildUrl(`/submission-task/${taskId}/status`, basePath);

  return handleRequest({ token, url, requestType: RequestType.PUT, data: dataSet });
};

const fetchSubmissionTaskNotes = async (token: string, taskId: string) => {
  const url = buildUrl(`/submission-task/${taskId}/notes`, basePath);

  return handleRequest({ token, url, requestType: RequestType.GET });
};

const fetchSubmissionTaskProductGroupData = async (token: string, taskId: string) => {
  const url = buildUrl(`/submission-task/${taskId}/product-group`, basePath);

  return handleRequest({ token, url, requestType: RequestType.GET });
};

const resubmitSubmissionTask = async (token: string, taskId: string, dataSet: unknown) => {
  const url = buildUrl(`/submission-task/${taskId}/resubmit`, basePath);

  return handleRequest({ token, url, requestType: RequestType.PUT, data: dataSet });
};

const validateSubmissionTask = async (token: string, taskId: Id) => {
  const url = buildUrl(`/submission-task/${taskId}/validate`, basePath);

  return handleRequest({ token, url, requestType: RequestType.GET });
};

const getPublishedDocument = async (
  token: string,
  documentId: string,
  version: string,
  documentType: DocumentType,
  queryString: string,
  handleAsFileStream: boolean,
) => {
  const url = buildUrl(`/document/${documentId}/version/${version}/document-type/${documentType}`, basePath, queryString);

  return handleAsFileStream
    ? handleFileRequest({ token, url, requestType: RequestType.GET })
    : handleRequest({ token, url, requestType: RequestType.GET });
};

export {
  fetchSubmissionTaskList,
  fetchSubmissionTaskData,
  putSubmissionTaskStatus,
  fetchSubmissionTaskNotes,
  fetchSubmissionTaskProductGroupData,
  resubmitSubmissionTask,
  validateSubmissionTask,
  fetchSubmissionTaskNextStatuses,
  getPublishedDocument,
};
