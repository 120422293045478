import { isStringNullOrEmpty } from '@common/helpers';
import { ReadonlyWeblink } from './ReadonlyWeblink';
import * as Styled from './styles';

interface IProps {
  customerUrl: string | undefined;
  url: string | null;
}

export const ReadonlyContentDetailsWeblink = ({ customerUrl, url }: IProps) => {
  const originalLink = isStringNullOrEmpty(customerUrl) ? url : customerUrl;

  if ((customerUrl && isStringNullOrEmpty(url)) || (isStringNullOrEmpty(customerUrl) && url)) {
    return (
      <Styled.WeblinksWrap>
        <ReadonlyWeblink url={originalLink ?? ''} />
      </Styled.WeblinksWrap>
    );
  }

  return (
    <Styled.WeblinksWrap>
      <ReadonlyWeblink url={customerUrl!} title="Customer Link" />
      <ReadonlyWeblink url={url!} title="Datapharm Link" />
    </Styled.WeblinksWrap>
  );
};
