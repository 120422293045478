import { useQuery } from '@tanstack/react-query';
import { selectToken } from '@common/features/user/selectors';
import { useAppSelector } from '@common/hooks/redux';
import { fetchSubmissionTaskData } from '@common/services/submissionTaskService';
import { ISubmissionTaskDto } from '@common/features/submission/types';

export const queryKey = 'fetchSubmissionTaskData';

export const useFetchSubmissionTaskData = (taskId: string, staleTime?: number, enabled: boolean = true) => {
  const token = useAppSelector(selectToken);

  const {
    data: submissionTask,
    isFetching,
    isLoading,
    isFetched,
    refetch
  } = useQuery<ISubmissionTaskDto>([queryKey, taskId], () => fetchSubmissionTaskData(token, taskId), {
    enabled: !!token && !!taskId && enabled,
    staleTime,
  });

  return {
    submissionTask,
    isFetchingSubmissionTaskData: isFetching || isLoading,
    isSubmissionTaskDataFetched: isFetched,
    refetchSubmissionTaskData: refetch
  };
};
