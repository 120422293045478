import { useEffect, useState } from 'react';
import { useSearchParams  } from "react-router-dom";


export const useSearch = () => {

  const [searchParams] = useSearchParams();

  const [displayPhrase, setDisplayPhrase] = useState(searchParams.get('searchTerm') ?? '');
  const [searchPhrase, setSearchPhrase] = useState('');

  const handleChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.currentTarget.value;
    if (inputValue.trim() === '') {
      setDisplayPhrase('');
    } else {
      setDisplayPhrase(e.currentTarget.value);
    }
  };

  const handleClearSearch = () => {
    setDisplayPhrase('');
    setSearchPhrase('');
  };

  const searchError = displayPhrase.length < 3 && displayPhrase !== '' ? 'Please enter at least 3 characters' : undefined;

  useEffect(() => {
    const delay = setTimeout(() => {
      if (displayPhrase.length === 0 || displayPhrase.length >= 3) setSearchPhrase(encodeURIComponent(displayPhrase));
    }, 500);

    return () => clearTimeout(delay);
  }, [displayPhrase]);

  return { searchPhrase, displayPhrase, searchError, handleChangeSearch, handleClearSearch };
};
