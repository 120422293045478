import { UnsavedChangesContext } from '@common/context/UnsavedChangesContext';
import { useContext, useEffect } from 'react';

interface Props {
  shouldRenderModal?: boolean;
  title?: string;
  subText?: string;
}

export const useUnsavedChanges = ({ shouldRenderModal, title, subText }: Props) => {
  const { setHandleConfirm, setHasUnsavedChanges, hasUnsavedChanges, setTitle, setSubText } = useContext(UnsavedChangesContext);

  const handleUnsavedChanges = (handleConfirm: () => void) => {
    if (hasUnsavedChanges) setHandleConfirm(() => () => handleConfirm());
    else handleConfirm();
  };

  useEffect(() => {
    title && setTitle(title);
  }, [title, setTitle]);

  useEffect(() => {
    setSubText && setSubText(subText);
  }, [subText, setSubText]);

  useEffect(() => {
    shouldRenderModal !== undefined && setHasUnsavedChanges(shouldRenderModal);
  }, [shouldRenderModal, setHasUnsavedChanges]);

  return { handleUnsavedChanges };
};
