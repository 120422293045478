import React, { CSSProperties, ReactNode } from 'react';
import Modal from 'react-modal';

import theme from '@common/theme';
import { StyledWrapper, StyledWrapperUseFooter } from './styles';

interface IModalWrapper {
  isOpen: boolean;
  label?: string;
  children?: ReactNode;
  className?: string;
  zIndex?: number;
  contentStyles?: CSSProperties;
  overrideWrap?: boolean;
}

interface IModalWrapperAddDocument extends IModalWrapper {
  footer: JSX.Element;
}

export const ModalWrapper = ({
  isOpen,
  label,
  children,
  zIndex,
  contentStyles,
  className,
  overrideWrap = false,
}: IModalWrapper) => {
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      borderRadius: 14,
      borderColor: theme.elements.borderGrey,
      padding: '20px',
      ...contentStyles,
    },
    overlay: {
      zIndex,
      background: '#0000001A 0% 0% no-repeat padding-box',
    },
  };

  const onOpen = () => {
    document.body.classList.add('disabled-scrollbar');
  };

  const onClose = () => {
    document.body.classList.remove('disabled-scrollbar');
  };

  return (
    <Modal onAfterOpen={onOpen} onAfterClose={onClose} isOpen={isOpen} style={customStyles} contentLabel={label}>
      {overrideWrap ? children : <StyledWrapper>{children}</StyledWrapper>}
    </Modal>
  );
};

export const ModalWithFooterWrapper = ({
  isOpen,
  label,
  children,
  zIndex,
  contentStyles,
  className,
  overrideWrap = false,
  footer,
}: IModalWrapperAddDocument) => {
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      borderRadius: 14,
      borderColor: theme.elements.borderGrey,
      padding: '20px',
      ...contentStyles,
    },
    overlay: {
      zIndex,
      background: '#0000001A 0% 0% no-repeat padding-box',
    },
  };

  const onOpen = () => {
    document.body.classList.add('disabled-scrollbar');
  };

  const onClose = () => {
    document.body.classList.remove('disabled-scrollbar');
  };

  return (
    <Modal onAfterOpen={onOpen} onAfterClose={onClose} isOpen={isOpen} style={customStyles} contentLabel={label}>
      {overrideWrap ? children : <StyledWrapperUseFooter>{children}</StyledWrapperUseFooter>}
      { footer }
    </Modal>
  );
};
