import { useUploadFile } from '@hooks/useUploadFile';
import { useDeleteFile } from '@hooks/useDeleteFile';
import { abortUpload } from '@api/uploadFileWithProgression';
import { DocumentType, ValidationError } from '@common/types';
import { getIsFileValid } from '@common/features/document/helpers';
import { FileRoles, FormFields, IAddDocumentForm } from '@common/features/document/types';
import { UseFormSetValue } from 'react-hook-form';
import { Dispatch, SetStateAction } from 'react';

interface IProps {
  contentType: DocumentType;
  setValue: UseFormSetValue<IAddDocumentForm>;
}

export interface ISecondaryDocumentProps {
  fileName: string;
  handleFileSubmit: (files: FileList | null) => void;
  handleRemove: () => void;
  setFileErrors: Dispatch<SetStateAction<ValidationError[]>>;
  isLoading: boolean;
  uploadProgress: number;
  fileErrors: ValidationError[];
}

export const useAddSecondaryDocumentOnModal = ({ contentType, setValue }: IProps): ISecondaryDocumentProps => {
  const { deleteFile } = useDeleteFile();

  const handleRemove = () => {
    setValue(FormFields.secondaryfile, undefined);
    clearFileName();
    abortUpload();
    if (!isLoading) {
      deleteFile(fileName);
    }
  };

  const { uploadFile, isLoading, uploadProgress, fileName, fileErrors, setFileErrors, clearFileName } =
    useUploadFile(handleRemove);

  const handleFileSubmit = (files: FileList | null) => {
    const file = files?.item(0);
    if (file) {
      const errors = getIsFileValid(contentType, file, FileRoles.SecondaryPDFFile);
      if (errors.length > 0) setFileErrors(errors);
      else {
        setValue(FormFields.secondaryfile, file);
        uploadFile({ file, documentType: contentType, fileRole: FileRoles.SecondaryPDFFile });
      }
    }
  };

  return {
    handleFileSubmit,
    handleRemove,
    fileName,
    setFileErrors,
    isLoading,
    uploadProgress,
    fileErrors,
  };
};
