import useWaitingMutation from '@common/hooks/useWaitingMutation';
import { useAppSelector } from '@common/hooks/redux';
import { selectToken } from '@common/features/user/selectors';
import { Id } from '@common/types';
import { addDocument, addDocumentVersion } from '@common/services/submissionService';
import { usePleaseWaitOverlay } from '@components/PleaseWaitOverlay/usePleaseWaitOverlay';
import { DocumentType } from '@common/types';
import { getDocumentAttributes } from '@common/features/document/helpers';
import { enhanceWithHttps } from '@common/helpers';

export const queryKey = 'contentDetailsData';

interface IPostInitialDocumentDetails {
  documentId: number | null;
  title: string;
  hasDigitalVersion?: boolean;
  includeSecondaryDocument?: boolean;
}

export const useAddDocument = (
  submissionId: Id,
  documentType: DocumentType,
  fileName?: string,
  originalFileName?: string,
  link?: string,
  isCustomerApprovalRequired?: boolean,
  htmlContent?: string,
  includeSecondaryDocument?: boolean,
  originalSecondaryFileName?: string,
  secondaryFileName?: string,
) => {
  const token = useAppSelector(selectToken);
  const { handleShowOverlay, handleHideOverlay } = usePleaseWaitOverlay();

  const documentAttributes = getDocumentAttributes(documentType, false);

  const { mutate, data, isLoading } = useWaitingMutation(
    ({ documentId, title, hasDigitalVersion }: IPostInitialDocumentDetails) => {
      const linkValue = !link || link.length === 0 ? null : link;
      const payload = {
        originalFileName,
        fileName,
        documentType,
        title,
        hasDigitalVersion: hasDigitalVersion ?? false,
        isCustomerApprovalRequired,
        htmlContent,
        includeSecondaryDocument,
        originalSecondaryFileName,
        secondaryFileName,
        ...(documentAttributes.shouldHandleCustomerLink ? { customerLink: enhanceWithHttps(linkValue!) } : { link: enhanceWithHttps(linkValue!) }),
      };

      return documentId
        ? // TODO: .then() should be removed when stories #40833, #45001, #40954 are finished and documentId will always be returned from service
          addDocumentVersion(token, submissionId, documentId, payload).then((data) => {
            return { documentId: data === '' ? documentId : data?.documentId };
          })
        : addDocument(token, submissionId, payload);
    },
    { onMutate: handleShowOverlay, onSuccess: handleHideOverlay, onError: handleHideOverlay },
  );

  return { addDocument: mutate, data, isLoading };
};
