import React from 'react';
import { ModalTitle } from '@common/features/productGroup/styles';
import { Button, ButtonTypes } from '@common/components';
import { ModalWithFooterWrapper } from '@common/components/ModalWrapper/ModalWrapper';
import * as Styled from './styles';
import Divider from '@common/components/Divider/Divider';

interface IUnknownProductGroupPopup {
  isOpen: boolean;
  handleClose: () => void;
}

const Mailto = () => {
  return (
    <span>
      Unknown product group, please contact&nbsp;
      <a href={`mailto:servicedesk@datapharm.com`}>servicedesk@datapharm.com</a>
    </span>
  );
};

const renderFooter = (handleClose: () => void) => {
  return (
    <Styled.ModalButtons>
      <Divider type="vertical" size="50px" thickness={1} />
      <Button
        data-testid="blank-product-group-popup__cancel-btn"
        onClick={handleClose}
        text="Cancel"
        type={ButtonTypes.TEXT}
      />
    </Styled.ModalButtons>
  );
};

const UnknownProductGroupPopup = ({ isOpen, handleClose }: IUnknownProductGroupPopup) => {
  const label = 'Uknown Product Group';

  return (
    <ModalWithFooterWrapper isOpen={isOpen} label={label} footer={renderFooter(handleClose)}>
      <Styled.ModalContent>
        <Styled.ModalIcon src={`/icons/warning-sign.svg`} />
        <ModalTitle>
          <Mailto />
        </ModalTitle>
      </Styled.ModalContent>
    </ModalWithFooterWrapper>
  );
};

export default UnknownProductGroupPopup;
