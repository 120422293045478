import React from 'react';
import * as Styled from './styles';
import { Button, ButtonTypes, ModalWrapper } from '@common/components';
import Divider from '../Divider/Divider';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  subText?: string;
  onConfirm?: () => void;
};

export const UnsavedChangesModal = ({ onConfirm, onClose, isOpen, title, subText }: IProps) => (
  <ModalWrapper isOpen={isOpen}>
    <Styled.TitleIcon src={'/icons/warning-sign.svg'} />
    <Styled.Title isSubtextPresent={!!subText}>
      {title}
    </Styled.Title>
    {subText && <Styled.Subtext>{subText}</Styled.Subtext>}
    <Styled.Footer>
      <Button onClick={onClose} text="Cancel" type={ButtonTypes.TEXT} data-testid="cancel-button" />
      <Divider type="vertical" size="50px" thickness={1} />
      <Button onClick={onConfirm} text="Confirm" type={ButtonTypes.PRIMARY} />
    </Styled.Footer>
  </ModalWrapper>
);
