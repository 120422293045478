import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { IActiveIngredient, IDocumentDto, IMaNumber, IProductDto } from '@common/features/submission/types';
import { prepareAmpIds, prepareAtcCodes, prepareLegalCategories } from '../helpers';
import { generateFormProductName, prepareIsTruthyDropdownDefaultOption } from '@common/helpers';
import { isTruthySelectOptions, glutenSelectOptions, lactoseSelectOptions } from '@common/constants';
import { createSelectOption } from '@common/components/Select/helpers';
import { DocumentStatus, ICheckboxEntry, ISelectOption, DocumentType, Id } from '@common/types';
import { IActiveIngredientsStrength, IAtcCode, IAmpId } from '@common/features/product/types';
import { useParams } from 'react-router';
import { useDownloadAndOpenFile } from '@common/hooks/useDownloadAndOpenFile';
import { ISingleSelectOption } from '@common/components';
import { prepareActiveIngredientsForForm } from '@common/features/product/helpers';
import { associatedContentStatuses } from '@common/constants';
import { getDocumentAttributes } from '@common/features/document/helpers';

export enum ProductForm {
  maNumberInput = 'maNumberInput',
  maNumbers = 'maNumbers',
  noMaNumbers = 'noMaNumbers',
  legalCategories = 'legalCategories',
  hasBlackTriangle = 'hasBlackTriangle',
  allergensAndPregnancyRisk = 'allergensAndPregnancyRisk',
  isGlutenFree = 'isGlutenFree',
  isLactoseFree = 'isLactoseFree',
  isSuitableForVegans = 'isSuitableForVegans',
  isPregnancyRisk = 'isPregnancyRisk',
  atcCodeInput = 'atcCodeInput',
  atcCodes = 'atcCodes',
  noAtcCodes = 'noAtcCodes',
  ampIds = 'ampIds',
  ampIdInput = 'ampIdInput',
  noAmpIds = 'noAmpIds',
  name = 'name',
  regulatorApprovedName = 'regulatorApprovedName',
  discontinuationReason = 'discontinuationReason',
  productForm = 'productForm',
  extraInformation = 'extraInformation',
  activeIngredientsStrength = 'activeIngredientsStrength',
  pil = 'pil',
  smpc = 'smpc',
  associatedContent = 'associatedContent',
  documents = 'documents',
  contactGroup = 'contactGroup',
}

export enum ProductFormPaths {
  maNumberInput = 'productDetails.maNumbersForm.maNumberInput',
  maNumbers = 'productDetails.maNumbersForm.maNumbers',
  noMaNumbers = 'productDetails.maNumbersForm.noMaNumbers',
  legalCategories = 'productDetails.legalCategories',
  hasBlackTriangle = 'productDetails.hasBlackTriangle',
  allergensAndPregnancyRisk = 'productDetails.allergensAndPregnancyRisk',
  isGlutenFree = 'productDetails.allergensAndPregnancyRisk.isGlutenFree',
  isLactoseFree = 'productDetails.allergensAndPregnancyRisk.isLactoseFree',
  isSuitableForVegans = 'productDetails.allergensAndPregnancyRisk.isSuitableForVegans',
  isPregnancyRisk = 'productDetails.allergensAndPregnancyRisk.isPregnancyRisk',
  atcCodes = 'productDetails.atcCodesForm.atcCodes',
  atcCodeInput = 'productDetails.atcCodesForm.atcCodeInput',
  noAtcCodes = 'productDetails.atcCodesForm.noAtcCodes',
  ampIds = 'productDetails.ampIdForm.ampIds',
  ampIdInput = 'productDetails.ampIdForm.ampIdInput',
  noAmpIds = 'productDetails.ampIdForm.noAmpIds',
  name = 'productInfo.name',
  regulatorApprovedName = 'productInfo.regulatorApprovedName',
  discontinuationReason = 'productInfo.discontinuationReason',
  extraInformation = 'productInfo.extraInformation',
  productForm = 'productInfo.productForm',
  activeIngredientsStrength = 'productInfo.activeIngredientsStrength',
  denominatorValue = 'productInfo.activeIngredientsStrength.denominator.denominatorValue',
  numeratorValue = 'productInfo.activeIngredientsStrength.ingredientsWithNumerators.numeratorValue',
  denominatorUnit = 'productInfo.activeIngredientsStrength.denominator.denominatorUnit',
  numeratorUnit = 'productInfo.activeIngredientsStrength.ingredientsWithNumerators.numeratorUnit',
  pil = 'associatedContent.pil',
  smpc = 'associatedContent.smpc',
  contactGroupInput = 'productDetails.contactGroupForm.contactGroupInput',
  contactGroup = 'productDetails.contactGroupForm.contactGroup',
}

interface IAssociatedContent {
  type: DocumentType;
  id: number;
}

export interface IProductForm {
  productDetails: {
    maNumbersForm: {
      maNumberInput: string;
      maNumbers: IMaNumber[] | null;
      noMaNumbers: boolean;
    };
    legalCategories: ICheckboxEntry[];
    hasBlackTriangle: ISingleSelectOption | null;
    allergensAndPregnancyRisk: {
      isSuitableForVegans: ISelectOption | null;
      isLactoseFree: ISelectOption | null;
      isGlutenFree: ISelectOption | null;
      isPregnancyRisk: ISelectOption | null;
    };
    contactGroupForm: {
      contactGroupInput: string;
      contactGroup: string;
    };
    atcCodesForm: {
      atcCodeInput: string;
      atcCodes: IAtcCode[] | null;
      noAtcCodes: boolean;
    };
    ampIdForm: {
      ampIdInput: string;
      ampIds: IAmpId[] | null;
      noAmpIds: boolean;
    };
  };

  companyId: string;

  productInfo: {
    name: string;
    regulatorApprovedName: string;
    discontinuationReason: ISelectOption | null;
    extraInformation: string;
    productForm: ISelectOption | null;
    activeIngredientsStrength: IActiveIngredientsStrength;
  };

  associatedContent: IAssociatedContent[];

  documents: IDocumentDto[];
}

const { Smpc, Pil } = DocumentType;

interface IProps {
  productGroupName: string;
  documents: IDocumentDto[];
  contentStatuses?: DocumentStatus[];
  product?: IProductDto;
  activeIngredients: IActiveIngredient[];
  shouldDisplayAmpIds: boolean;
}

export const useProductForm = ({ productGroupName, documents, product, activeIngredients = [], shouldDisplayAmpIds }: IProps) => {
  const { productId = '' } = useParams();

  const associatedDocumentsIds = documents
    ?.filter((d) => d.linkedProductIds.includes(Number(productId)) && associatedContentStatuses.includes(d.status))
    .map((d) => ({ id: d.documentId, type: d.type }));

  const methods = useForm<IProductForm>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      productDetails: {
        maNumbersForm: {
          [ProductForm.maNumberInput]: '',
          [ProductForm.maNumbers]: product?.maNumbers ?? [],
          [ProductForm.noMaNumbers]: product?.maNumbers?.length === 0,
        },
        [ProductForm.legalCategories]: prepareLegalCategories(product?.legalCategories ?? []),
        [ProductForm.hasBlackTriangle]: prepareIsTruthyDropdownDefaultOption(product?.hasBlackTriangle, isTruthySelectOptions) as ISingleSelectOption,
        [ProductForm.allergensAndPregnancyRisk]: {
          [ProductForm.isGlutenFree]: prepareIsTruthyDropdownDefaultOption(product?.isGlutenFree, glutenSelectOptions),
          [ProductForm.isLactoseFree]: prepareIsTruthyDropdownDefaultOption(product?.isLactoseFree, lactoseSelectOptions),
          [ProductForm.isSuitableForVegans]: prepareIsTruthyDropdownDefaultOption(product?.isSuitableForVegans, isTruthySelectOptions),
          [ProductForm.isPregnancyRisk]: prepareIsTruthyDropdownDefaultOption(product?.isPregnancyRisk, isTruthySelectOptions),
        },
        atcCodesForm: {
          [ProductForm.atcCodeInput]: '',
          [ProductForm.atcCodes]: prepareAtcCodes(product?.atcCodes ?? []),
          [ProductForm.noAtcCodes]: product?.atcCodes?.length === 0,
        },
        contactGroupForm: {
          [ProductForm.contactGroup]: product?.contactGroup,
        },
        ampIdForm: {
          [ProductForm.ampIdInput]: '',
          [ProductForm.ampIds]: prepareAmpIds(product?.ampIds ?? []),
          [ProductForm.noAmpIds]: product?.ampIds?.length === 0,
        },
      },

      productInfo: {
        [ProductForm.name]: product?.name ?? '',
        [ProductForm.regulatorApprovedName]: product?.regulatorApprovedName ?? '',
        [ProductForm.discontinuationReason]: product?.discontinuationReasonId
          ? createSelectOption(product?.discontinuationReason!, product?.discontinuationReasonId!)
          : null,
        [ProductForm.productForm]: createSelectOption(product?.productForm ?? ''),
        [ProductForm.extraInformation]: product?.extraInformation ?? '',
        [ProductForm.activeIngredientsStrength]: prepareActiveIngredientsForForm(activeIngredients),
      },

      associatedContent: associatedDocumentsIds,

      [ProductForm.documents]: documents,
    },
  });

  const {
    register,
    getValues,
    setValue,
    trigger,
    watch,
    formState: { errors, dirtyFields },
  } = methods;

  register(ProductForm.associatedContent, {
    validate: (data) => {
      const associatedDocumentsIds = data.map((d) => d.id);

      return documents.some((d) => associatedDocumentsIds.includes(d.documentId) && [Pil, Smpc].includes(d.type));
    },
  });
  register(ProductFormPaths.ampIds, {
    validate: (data) => {
      if (!shouldDisplayAmpIds) return true;

      return data?.length !== 0 || getValues(ProductFormPaths.noAmpIds);
    },
  });
  register(ProductFormPaths.legalCategories, {
    validate: (data) => {
      return data?.length !== 0 || !!getValues(ProductFormPaths.legalCategories).length;
    },
  });
  register(ProductFormPaths.hasBlackTriangle, {
    validate: (data) => {
      return !!data;
    },
  });
  register(ProductFormPaths.maNumbers, {
    validate: (data) => {
      return data?.length !== 0 || getValues(ProductFormPaths.noMaNumbers);
    },
  });
  register(ProductFormPaths.atcCodes, {
    validate: (data) => {
      return data?.length !== 0 || getValues(ProductFormPaths.noAtcCodes);
    },
  });

  useEffect(() => {
    trigger();
  }, [trigger]);

  const isProductInfoValid = !errors.productInfo;
  const isProductDetailsValid = !errors.productDetails;
  const isAssociatedContentValid = !errors.associatedContent;

  const [documentIdAfterSelectionChanged, setDocumentIdAfterSelectionChanged] = useState<number | undefined>(undefined);

  const { downloadAndOpen } = useDownloadAndOpenFile();

  const activeIngredientsStrength = watch(ProductFormPaths.activeIngredientsStrength);
  const extraInformation = watch(ProductFormPaths.extraInformation);
  const productForm = watch(ProductFormPaths.productForm);

  const generatedName = generateFormProductName(
    productGroupName,
    activeIngredientsStrength?.ingredientsWithNumerators,
    activeIngredientsStrength?.denominator,
    productForm ? productForm.label : '',
    extraInformation,
  );

  useEffect(() => {
    if (!product?.isNameSetByUser && !dirtyFields.productInfo?.name) {
      setValue(ProductFormPaths.name, generatedName, { shouldDirty: false });
    }

    if (!product?.isRegulatorApprovedNameSetByUser && !dirtyFields.productInfo?.regulatorApprovedName) {
      setValue(ProductFormPaths.regulatorApprovedName, generatedName, { shouldDirty: false });
    }
  }, [
    dirtyFields.productInfo?.name,
    dirtyFields.productInfo?.regulatorApprovedName,
    generatedName,
    product?.isNameSetByUser,
    product?.isRegulatorApprovedNameSetByUser,
    setValue,
  ]);

  const showAssociatedContentWarning = !!errors.associatedContent;

  const draftOrPublishedDocuments =
    watch(ProductForm.documents).filter((d) => associatedContentStatuses.includes(d.status)) ?? [];

  const handleConfirm = () => setDocumentIdAfterSelectionChanged(undefined);

  const handleSelect = (id: number, type: DocumentType) => {
    let prevAssociatedContent = structuredClone(getValues(ProductForm.associatedContent));

    const prevDocumentId = prevAssociatedContent?.find((ac) => ac.type === type)?.id;
    const prevDocument = draftOrPublishedDocuments?.find((d) => d.documentId === prevDocumentId);
    const documentAttributes = getDocumentAttributes(type, false);

    if (prevDocument?.status === DocumentStatus.Published) {
      setDocumentIdAfterSelectionChanged(prevDocument.documentId);
    }

    const item = prevAssociatedContent.find((ac) => ac.id === id);
    if (item) {
      prevAssociatedContent = prevAssociatedContent.filter((ac) => ac.id !== item.id);
    } else {
      if (!documentAttributes.allowMultipleSelect) {
        prevAssociatedContent = prevAssociatedContent.filter((ac) => ac.type !== type);
      }

      prevAssociatedContent.push({ id, type });
    }

    setValue(ProductForm.associatedContent, prevAssociatedContent, { shouldValidate: true });
  };

  const handleFileOpen = (submissionId: Id, documentId: Id) => downloadAndOpen({ submissionId, documentId });

  return {
    productFormMethods: methods,
    ...methods,
    isProductInfoValid,
    isProductDetailsValid,
    isAssociatedContentValid,
    documentIdAfterSelectionChanged,
    showAssociatedContentWarning,
    draftOrPublishedDocuments,
    handleFileOpen,
    handleSelect,
    handleConfirm,
  };
};
