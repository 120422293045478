import { useAppDispatch, useAppSelector } from '@common/hooks/redux';
import { selectUserEmail } from '../features/user/selectors';
import { useState } from 'react';
import { fetchIdentityUserData } from '../features/user/thunks';

export const useFetchEmail = () => {
  const [email, setEmail] = useState(useAppSelector(selectUserEmail));
  const dispatch = useAppDispatch();

  return {
    email,
    refetchEmail: async () => {
      const data = await dispatch(fetchIdentityUserData());
      setEmail(data.payload.email ?? '');
      // 'email' will be updated on the next render, return the actual email
      return data.payload.email ?? '';
    }
  };
};
