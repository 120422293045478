import React from 'react';
import { LayoutWrapper, SidebarWrapper, HeaderWrapper, ContentWrapper, FooterWrapper } from './styles';

interface ILayoutSections {
  sidebar: JSX.Element;
  header: JSX.Element;
  content: JSX.Element;
  footer: JSX.Element;
};

const MainLayout = ({ sidebar, header, content, footer }: ILayoutSections) => {
  return (
    <LayoutWrapper>
      <SidebarWrapper>
        {sidebar}
      </SidebarWrapper>
      <HeaderWrapper>
        {header}
      </HeaderWrapper>
      <ContentWrapper>
        {content}
      </ContentWrapper>
      <FooterWrapper>
        {footer}
      </FooterWrapper>
    </LayoutWrapper>
  );
};

export default MainLayout;
