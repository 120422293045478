import { IProductTypeSelectOption } from '@common/types';
import { IProductGroup, ISubstanceDto, SubmissionTypes } from '@common/features/submission/types';
import { IActiveIngredientOption } from '@common/features/product/types';

export enum FormFields {
  submissionType = 'submissionType',
  productType = 'productType',
  genericName = 'genericName',
  activeIngredients = 'activeIngredients',
  noActiveIngredientsCheckbox = 'noActiveIngredientsCheckbox',
  selectedProductGroup = 'selectedProductGroup',
  productGroup = 'productGroup'
}

export enum FormErrors {
  submissionType = 'Please select submission type',
  genericName = 'Please complete Brand/Generic Name',
  activeIngredients = 'Please provide an active ingredient(s) or select the no active ingredient checkbox',
  Details = 'Max. length: 500 characters',
  searchTooShort = 'Please enter at least 3 characters',
}

export interface ISubmissionForm {
  submissionType: SubmissionTypes;
  productType: IProductTypeSelectOption;
  genericName: string;
  activeIngredients: IActiveIngredientOption[];
  noActiveIngredientsCheckbox: boolean;
  search: string;
  selectedProductGroup: IProductGroupTableEntry;
}

export interface IProductGroupTableEntry extends IProductGroup {
  type: string;
  familyName: string;
  familyId: string;
  substances: ISubstanceDto[];
}
