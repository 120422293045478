import styled from 'styled-components';

export const HomeWrap = styled.div`
  display: flex;
  padding: 20px;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.lightGreen};
  flex-grow: 1;
`;

export const HeaderWrap = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  height: fit-content;
  padding: 20px;
  margin-bottom: 20px;
  background-color: ${({ theme }) => theme.elements.defaultWhite};
  border: 1px solid ${({ theme }) => theme.elements.primaryLight};
  border-radius: 10px;
`;

export const ContentWrap = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 20px;
`;

export const LeftPanel = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 60%;
  row-gap: 20px;
`;

export const RightPanel = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 40%;
  row-gap: 20px;
`;

export const RowWrap = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 20px;
  > * {
    flex-basis: 50%;
  }
`;

export const SectionDescription = styled.div`
  font-size: ${({ theme }) => theme.elementFontSizes.sectionText};
`;

export const TutorialsWrap = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  width: 100%;
`;

export const TutorialsDisclaimer = styled.span`
  text-align: center;
  font-size: ${({ theme }) => theme.fontSizes.tiny};
  color: ${({ theme }) => theme.colors.darkGrey};
`;

export const Image = styled.img`
  max-height: 275px;
  object-fit: scale-down;
`;

export const BorderedBox = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  border: 1px solid ${({ theme }) => theme.colors.mediumGreen};
  border-radius: 10px;
  padding: 10px 10px 25px;
`;

export const Link = styled.a`
  color: ${({ theme }) => theme.elements.primaryMedium};
`;
