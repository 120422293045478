import styled from 'styled-components';
import { Title } from '@common/components/NotifyModal/styles';

export const SubmissionTaskDetailsUnitWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.defaultWhite};
  border: 1px solid ${({ theme }) => theme.colors.borderGray};
  border-radius: 14px;
  width: auto;
  margin: 28px 25px 30px 28px;
`;

export const StyledLink = styled.span`
  cursor: pointer;
  font-weight: 600;
  color: ${({ theme }) => theme.elements.linkBlue};
`;

export const ProductManagementWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${Title} {
    font-size: ${({ theme }) => theme.fontSizes.large};
  }
`;

export const SelectsWrapper = styled.div`
  display: flex;
  column-gap: 50px;
  align-items: center;
  margin-left: 25px;

  .task-status-select {
    width: 300px;
  }

  .fast-track-select {
    width: 100px;
  }

  .user-select {
    width: 200px;
  }
`;

export const TableContentWrapper = styled.div`
  display: flex;
  flex-flow: column;
  row-gap: 1rem;
  margin-top: 2rem;
`;

export const LoaderTableWrapper = styled.div`
  margin: 28px 25px 30px 28px;
`;

export const ErrorContent = styled.div`
  display: flex;
  flex-flow: column;
`;

export const ErrorContentText = styled.span`
  font-size: ${({ theme }) => theme.fontSizes.large};
  font-weight: 600;
  margin-bottom: 20px;
`;

export const ErrorContentButtons = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const DetailsWithButton = styled.div`
  border-radius: 14px;
  background-color: ${({ theme }) => theme.colors.defaultWhite};
  border: 1px solid ${({ theme }) => theme.colors.borderGray};
  width: auto;
  margin: 28px 25px 30px 28px;

  ${SubmissionTaskDetailsUnitWrapper} {
    margin: 0;
    border: none;
  }
`;

export const DetailsButtonWrapper = styled.div`
  margin: 15px 25px 0;
  display: flex;
  justify-content: flex-end;
`;

export const LoaderHeaderWrap = styled.div`
  padding: 28px 25px 8px 28px;
  height: 8rem;
  margin-bottom: 2rem;
`;
