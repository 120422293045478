import { useState } from 'react';
import { mapValidationErrors } from '@common/helpers';
import { IValidationModalErrors } from '@common/types';
import { useValidateSubmissionTask } from './useValidateSubmissionTask';


interface IUseValidateSubmissionTaskDataProps {
  taskId?: number;
}

interface IValidateSubmissionTaskDataCallbacks {
  handleErrors: () => void;
  handleSuccess: () => void;
}

export const useValidateSubmissionTaskData = ({ taskId }: IUseValidateSubmissionTaskDataProps) => {
  const [validationErrors, setValidationErrors] = useState({} as IValidationModalErrors);

  const { validateSubmissionTask } = useValidateSubmissionTask();

  const validateSubmissionTaskData = ({handleErrors, handleSuccess}: IValidateSubmissionTaskDataCallbacks) => {
    validateSubmissionTask(taskId!, {
      onSuccess: (data) => {
        if (data.length > 0) {
          setValidationErrors(mapValidationErrors(data));
          handleErrors();
        } else {
          handleSuccess();
        }
      },
    });
  };

  return {
    validationErrors,
    validateSubmissionTaskData
  };
};
