import { useQuery } from '@tanstack/react-query';
import { useAppSelector } from './redux';
import { selectToken } from '@common/features/user/selectors';
import { Id } from '@common/types';
import { downloadNoteFile } from '@common/services/submissionService';

export const useDownloadNoteFile = (taskId: Id, noteId: number) => {
  const token = useAppSelector(selectToken);

  const { data, refetch } = useQuery(['downloadNoteFile', taskId, noteId], () => downloadNoteFile(token, taskId, noteId), {
    enabled: false,
  });

  return { noteFile: data, refetchDownloadNoteFile: refetch };
};
