import React from 'react';
import { useParams } from 'react-router';
import { useFetchSubmission } from '@common/features/submission/hooks/useFetchSubmission';
import useAppNavigation from '../../routing/useAppNavigation';
import { CustomerAllowedTaskActions, DocumentType } from '@common/types';
import { DocumentDetailsParentContent } from '@common/features/document/pages/ContentDetails/DocumentDetailsParentContent';
import { DocumentDetailsReadonlySection } from '@common/features/document/components/DocumentContentDetails/types';
import { useFetchSubmissionTaskData } from '@common/hooks/useFetchSubmissionTaskData';
import DocumentDetailsSkeletonLoader from '@common/features/document/pages/ContentDetails/DocumentDetailsSkeletonLoader';

interface ISubmissionTaskContentDetails {
  contentType: DocumentType;
}

export const SubmissionTaskContentDetails = ({ contentType }: ISubmissionTaskContentDetails) => {
  const { submissionId = '', documentId = '', taskId = '' } = useParams();
  const { submission, isFetchingSubmission, isSubmissionFetched } = useFetchSubmission(submissionId!);
  const { submissionTask } = useFetchSubmissionTaskData(taskId);

  const { goToSubmissionTaskDetails } = useAppNavigation();

  const redirectToSubmissionTaskDetails = () => {
    goToSubmissionTaskDetails(submissionId, taskId);
  };

  const isCustomerApprovalRequired = submissionTask?.isCustomerApprovalRequired;
  const isEditable = submissionTask?.allowedCustomerActions.includes(CustomerAllowedTaskActions.CustomerCanEditTaskDetails);

  const taskDocumentDetailsReadonlySections = {
    [DocumentDetailsReadonlySection.documentTitle]: !isEditable,
  };

  if (isFetchingSubmission || !isSubmissionFetched) {
    return <DocumentDetailsSkeletonLoader />;
  }
  return (
    <DocumentDetailsParentContent
      isEditable={isEditable}
      isFormExtended={isEditable ?? false}
      documentDetailsReadonlySections={taskDocumentDetailsReadonlySections}
      isCustomerApprovalRequired={isCustomerApprovalRequired!}
      submission={submission!}
      submissionId={submissionId!}
      documentId={documentId!}
      contentType={contentType}
      isAdmin={false}
      customerUrl={submissionTask?.customerLink}
      onCancel={redirectToSubmissionTaskDetails}
      onSuccess={redirectToSubmissionTaskDetails}
    />
  );
};
