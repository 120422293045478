import { useState } from 'react';
import DatapharmTable from '@common/components/DatapharmTable/DatapharmTable';
import { Row } from '@tanstack/react-table';
import { prepareDetailsColumns, prepareDocumentColumns } from './versionHistoryTableSetup';
import { IDocumentHistoryVersion } from './model/IDocumentHistoryVersion';
import { useFetchDocumentVersionHistory } from './hooks/useFetchDocumentVersionHistory';
import * as Styled from './styles';
import { useDownloadAndOpenFile } from '@common/hooks/useDownloadAndOpenFile';
import { CheckboxOption } from '@common/components/CheckboxOption/CheckboxOption';
import { DocumentType } from '@common/types';
import { TableOverflowContainer } from '@common/styles';

export const DocumentVersionHistory = ({
  documentType,
  documentId,
  showHistoryFiltering,
}: {
  documentType?: DocumentType;
  documentId: number;
  showHistoryFiltering: boolean;
}) => {
  const { items, isLoading } = useFetchDocumentVersionHistory(documentId, documentType);
  const [expanded, setExpanded] = useState<Record<string, boolean>>({});
  const [isFiltered, setIsFiltered] = useState<boolean>(false);

  const { downloadAndOpen } = useDownloadAndOpenFile();

  const handleRowExpand = async (row: Row<IDocumentHistoryVersion>, isExpanded: boolean) => {
    setExpanded((prevExpanded) =>
      !!prevExpanded[row?.index] ? { ...prevExpanded, [row?.index]: false } : { ...prevExpanded, [row?.index]: true },
    );
  };

  const handleFilter = () => {
    setIsFiltered(!isFiltered);
  };

  const handleContentDownload = (taskId: number) => {
    downloadAndOpen({ taskId });
  };

  const documentVersions = items
    .filter((s) => !isFiltered || s.documentNotes.some((note) => note.isPostPublicationEntry))
    .sort((a, b) => b.version - a.version)
    .map((s) => {
      var notes = isFiltered ? s.documentNotes.filter((x) => x.isPostPublicationEntry) : s.documentNotes;
      return {
        ...s,
        expandableContent: (
          <Styled.DetailsTableWrapper>
            <DatapharmTable columns={prepareDetailsColumns()} documents={notes} />
          </Styled.DetailsTableWrapper>
        ),
      };
    });

  return (
    <Styled.ContentWrap>
      <Styled.HeadingText>Version history</Styled.HeadingText>
      <Styled.SubHeadingText>
        This page shows any previous versions for this content. These versions would have been superseded when an updated version
        of this content was submitted to our system
      </Styled.SubHeadingText>
      {showHistoryFiltering && (
        <Styled.FilterWrap>
          <CheckboxOption
            name={'Post Publication Change'}
            item={{ text: 'Post Publication Change' }}
            onChange={handleFilter}
            isChecked={isFiltered}
          />
        </Styled.FilterWrap>
      )}
      <TableOverflowContainer>
        <DatapharmTable
          customMessage="There are no previous versions of this content"
          columns={prepareDocumentColumns(handleContentDownload)}
          documents={documentVersions ?? []}
          expanded={expanded}
          onRowExpand={handleRowExpand}
          showLoader={isLoading}
          data-testid="document-version-table"
        />
      </TableOverflowContainer>
    </Styled.ContentWrap>
  );
};
