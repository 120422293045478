import { ISubmissionDto } from '@common/features/submission/types';
import { useFeatureFlags } from '@common/hooks/useFeatureFlags';
import { DocumentType } from '@common/types';

export const useGetPrimaryAndSecondaryFiles = (contentType: DocumentType, submission: ISubmissionDto, documentId: number) => {
  const flags = useFeatureFlags();

  if (flags.enableWordPil && contentType === DocumentType.Pil) {
    const pilDocument = submission?.documents.find((d) => d.documentId === documentId && d.type === DocumentType.Pil);

    const epilDocument = submission?.documents.find((d) => d.documentId === documentId && d.type === DocumentType.Epil);

    if (epilDocument?.originalFileName?.toLowerCase().endsWith('.docx')) {
      return {
        primaryFile: epilDocument,
        secondaryFile: pilDocument,
      };
    } else {
      return {
        primaryFile: pilDocument,
        secondaryFile: undefined,
      };
    }
  }

  const primaryFile = submission?.documents.find((d) => d.documentId === documentId && d.type === contentType);

  return {
    primaryFile,
    secondaryFile : undefined,
  };
};
