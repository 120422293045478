import { useState } from 'react';
import { IValidationModalErrors } from '../../ValidateSubmissionErrors/ValidateSubmissionErrors';
import { mapValidationErrors } from '@common/helpers';
import { useValidateSubmission } from './useValidateSubmission';


interface IUseValidateSubmissionDataProps {
  submissionId?: string;
  handleErrors: () => void;
  handleSuccess: () => void;
}

export const useValidateSubmissionData = ({ submissionId, handleErrors, handleSuccess }: IUseValidateSubmissionDataProps) => {
  const [validationErrors, setValidationErrors] = useState({} as IValidationModalErrors);

  const { validateSubmission } = useValidateSubmission();

  const validateSubmissionData = () => {
    validateSubmission(submissionId!, {
      onSuccess: (data) => {
        if (data.length > 0) {
          setValidationErrors(mapValidationErrors(data));
          handleErrors();
        } else {
          handleSuccess();
        }
      },
    });
  };

  return {
    validationErrors,
    validateSubmissionData
  };
};
