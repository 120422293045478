import React, { useState } from 'react';
import Header from '@common/components/Header/Header';
import Footer from '@common/components/Footer/Footer';
import { MainContainerWrap, DefaultContent, CustomContent } from './styles';
import { useLocation } from 'react-router';
import { routes } from '../../routing/routes';
import { ButtonTypes } from '@common/components/Button/types';
import { Button } from '@common/components';
import useAppNavigation from '../../routing/useAppNavigation';
import { globalRoutes } from '@common/routing/routes';
import GlobalSidebar from '@components/GlobalSidebar/GlobalSidebar';
import { useFeatureFlags } from '@common/hooks/useFeatureFlags';
import { Icons } from '@common/icons';
import { ThirdPartyLinkWarning } from '@common/features/global/ThirdPartyLinkWarning/ThirdPartyLinkWarning';
import { selectUserEmail } from '@common/features/user/selectors';
import { useAppSelector } from '@common/hooks/redux';
import { selectConfiguration } from '@common/features/configuration/selectors';
import { NotifyModal } from '@common/components/NotifyModal';
import { MainLayout } from '@common/components/MainLayout';

const getEntries = (showAuditReports: boolean) => {
  var items = [
    { display: 'Dashboard', to: globalRoutes.home, getIcon: () => <Icons.Home /> },
    { display: 'Submissions', to: routes.submissions, getIcon: () => <Icons.Submissions /> },
    { display: 'All Products', to: routes.products, getIcon: () => <Icons.Products /> },
  ];

  if (showAuditReports) items.push({ display: 'Audit Reports', to: routes.audits, getIcon: () => <Icons.Audits /> });

  return items;
};

interface IMainContainer {
  children: string | JSX.Element | JSX.Element[];
}

const MainContainer = ({ children }: IMainContainer) => {
  const { pathname } = useLocation();
  const { goToCreateSubmission } = useAppNavigation();
  const { showAuditReports } = useFeatureFlags();
  const email = useAppSelector(selectUserEmail);
  const [showUpdateEmail, setShowUpdateEmail] = useState(email.trimEnd().length === 0);
  const configuration = useAppSelector(selectConfiguration);

  const hasCustomLayout =
    (pathname.startsWith(routes.submissions) && !pathname.endsWith(routes.submissions)) ||
    pathname.includes(routes.productGroups);

  const handleRedirect = () => {
    goToCreateSubmission();
  };

  const renderSidebar = () => {
    return (
      <GlobalSidebar entries={getEntries(showAuditReports)} />
    );
  };

  const renderHeader = () => {
    return (
      <Header>
        <Button text="Create Submission" type={ButtonTypes.PRIMARY} onClick={handleRedirect} />
      </Header>
    );
  };

  const renderContent = () => {
    return (
      <>
        {!hasCustomLayout ? (<MainContainerWrap>
          <DefaultContent>{children}</DefaultContent>
          {showUpdateEmail && (
            <NotifyModal
              confirmText={'Confirm'}
              onConfirm={() => setShowUpdateEmail(false)}
              title="Please update your details"
              isOpen={showUpdateEmail}
              width="36rem"
            >
              <p>
                You don't have an email address associated to your account, please update your email using our&nbsp;
                <a target="_blank" rel="noreferrer" href={configuration.cmsLink}>Contact Management System (CMS)</a>
              </p>
            </NotifyModal>
          )}
        </MainContainerWrap >)
          : (
            <MainContainerWrap>
              <CustomContent>{children}</CustomContent>
            </MainContainerWrap>
          )}
        <ThirdPartyLinkWarning />
      </>
    );
  };

  const renderFooter = () => {
    return (
      <Footer />
    );
  };

  return (
    <MainLayout sidebar={renderSidebar()} header={renderHeader()} content={renderContent()} footer={renderFooter()} />
  );
};

export default MainContainer;
