import { StyledLink } from '@common/components/Link/styles';
import { useThirdPartyLinkWarning } from '@common/features/global/ThirdPartyLinkWarning/useThirdPartyLinkWarning';

interface Props {
  id?: number;
  text: string;
  htmlContent: string | null;
  link: string | null;
  disabled: boolean;
  onViewContent?: (text: string, title: string) => void;
  onFileOpen?: (id: string) => void;
}

export const TitleWeblinkCell = ({ id, htmlContent, link, text, disabled, onViewContent, onFileOpen }: Props): JSX.Element => {
  const { handleUrlClick } = useThirdPartyLinkWarning();

  const handleClick = (e: any) => {
    e.preventDefault();
    !!id && onFileOpen!(id!.toString());
  };

  const url = link ?? '';

  if (!text || text === '') {
    text = '-';
  }

  if (disabled) {
    return <span>{text}</span>;
  }

  if (!!htmlContent) {
    return (
      <StyledLink onClick={onViewContent ? () => onViewContent(htmlContent!, 'HTML') : () => {}} to="#">
        {text}
      </StyledLink>
    );
  }

  if (!!link && link !== '') {
    return (
      <StyledLink target="_blank" to={url} onClick={(e) => handleUrlClick(e, url)}>
        {text}
      </StyledLink>
    );
  }

  return (
    <StyledLink onClick={handleClick} to="#">
      {text}
    </StyledLink>
  );
};
