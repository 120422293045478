import { useState } from 'react';
import { useDeleteFile } from '@hooks/useDeleteFile';
import { abortUpload } from '@api/uploadFileWithProgression';
import { getIsFileValid, getSubmitValidationErrors } from '@common/features/document/helpers';
import { FileRoles, FormFields } from '@common/features/document/types';
import { useForm } from 'react-hook-form';
import { useUploadFile } from '@common/hooks/useUploadFile';
import { DocumentType } from '@common/types';
import { useRequestEpil } from './useRequestEpil';
import { useParams } from 'react-router';

interface IRequestEpilForm {
  file: File | undefined;
}

export const useRequestEpilModal = (onUploadSuccess: () => void) => {
  const { submissionId } = useParams();

  const { deleteFile } = useDeleteFile();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [documentId, setDocumentId] = useState<string | undefined>(undefined);

  const methods = useForm<IRequestEpilForm>({
    mode: 'onChange',
    defaultValues: {
      file: undefined,
    },
  });

  const { setValue, reset, getValues, handleSubmit } = methods;

  const handleRemove = () => {
    setValue(FormFields.file, undefined);
    clearFileName();
    abortUpload();
    if (!isLoading && fileName) {
      deleteFile(fileName);
    }
  };

  const { uploadFile, isLoading, uploadProgress, fileName, fileErrors, setFileErrors, clearFileName } =
    useUploadFile(handleRemove);

  const handleFileSubmit = (files: FileList | null) => {
    const file = files?.item(0);
    const fileRole = FileRoles.PrimaryDocFile;
    if (file) {
      const errors = getIsFileValid(DocumentType.Epil, file, fileRole);
      if (errors.length > 0) {
        setFileErrors(errors);
      } else {
        setValue(FormFields.file, file);
        uploadFile({ file, documentType: DocumentType.Epil, fileRole });
      }
    }
  };

  const { requestEpil } = useRequestEpil();

  const handleUploadClick = () => {
    const submitValidationErrors = getSubmitValidationErrors();

    if (!getValues('file')?.name) setFileErrors([submitValidationErrors.WORD_PIL_NOT_UPLOADED]);
    else {
      handleSubmit(({ file }) =>
        requestEpil(
          { documentId, submissionId, fileName, originalFileName: file?.name },
          {
            onSuccess: () => {
              setIsModalOpen(false);
              setValue(FormFields.file, undefined);
              onUploadSuccess();
            },
          },
        ),
      )();
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    clearErrors();
    reset({});
    abortUpload();
    if (!isLoading && fileName) {
      deleteFile(fileName);
    }
  };

  const clearErrors = () => {
    setFileErrors([]);
  };

  const handleOpenModal = (documentId?: string) => {
    setIsModalOpen(true);
    setDocumentId(documentId);
  };

  return {
    handleRequestEpilModalFileSubmit: handleFileSubmit,
    handleRequestEpilModalUploadClick: handleUploadClick,
    handleRequestEpilModalCancel: handleCancel,
    handleRequestEpilModalRemove: handleRemove,
    handleRequestEpilModalOpen: handleOpenModal,
    clearRequestEpilModalErrors: clearErrors,
    requestEpilModalFileErrors: fileErrors,
    isRequestEpilModalOpen: isModalOpen,
    isRequestEpilModalLoading: isLoading,
    requestEpilModalUploadProgress: uploadProgress,
    requestEpilModalMethods: methods,
  };
};
