import { Id } from '@common/types';
import { buildUrl, handleRequest, handleFileRequest } from '@common/services/requestHelper';
import { RequestType } from '@common/services/types';
import { DocumentType } from '@common/types';

const basePath = process.env.REACT_APP_API_PATH ?? '';

const fetchSubmission = async (token: string, id: string) => {
  const url = buildUrl(`/submission/${id}`, basePath);

  return handleRequest({ token, url, requestType: RequestType.GET });
};

const updateSubmission = async (token: string, id: string, dataSet: unknown) => {
  const url = buildUrl(`/submission/${id}`, basePath);

  return handleRequest({
    token,
    url,
    requestType: RequestType.PATCH,
    data: dataSet,
  });
};

const updateSubmissionActiveIngredients = async (token: string, id: string, dataSet: unknown) => {
  const url = buildUrl(`/submission/${id}/active-ingredients`, basePath);

  return handleRequest({
    token,
    url,
    requestType: RequestType.PATCH,
    data: dataSet,
  });
};

const updateSubmissionSubstances = async (token: string, id: string, dataSet: unknown) => {
  const url = buildUrl(`/submission/${id}/substances`, basePath);

  return handleRequest({
    token,
    url,
    requestType: RequestType.PATCH,
    data: dataSet,
  });
};

const fetchCompanySubmissions = async (token: string, queryString: string) => {
  const url = buildUrl(`/submission`, basePath, queryString);

  return handleRequest({ token, url, requestType: RequestType.GET });
};

const postDraftFromProductGroup = async (token: string, dataSet: unknown) => {
  const url = buildUrl('/submission/draft/product-group', basePath);

  return handleRequest({
    token,
    url,
    requestType: RequestType.POST,
    data: dataSet,
  });
};

const generateProductGroupPlan = async (token: string, companyId: string, dataSet: unknown) => {
  const url = buildUrl('/product-group/plan', basePath);

  return handleRequest({
    token,
    url,
    requestType: RequestType.POST,
    data: dataSet,
    companyId: companyId,
  });
};

const generateProductFamilyPlan = async (token: string, dataSet: unknown) => {
  const url = buildUrl('/product-family/plan', basePath);

  return handleRequest({
    token,
    url,
    requestType: RequestType.POST,
    data: dataSet,
  });
};

const submitSubmission = async (token: string, submissionId: string, dataSet: unknown) => {
  const url = buildUrl(`/submission/${submissionId}/submit`, basePath);

  return handleRequest({
    token,
    url,
    requestType: RequestType.PATCH,
    data: dataSet,
  });
};

const validateSubmission = async (token: string, submissionId: string) => {
  const url = buildUrl(`/submission/${submissionId}/validate`, basePath);

  return handleRequest({ token, url, requestType: RequestType.GET });
};

const cancelSubmission = async (token: string, submissionId: number, dataSet: unknown) => {
  const url = buildUrl(`/submission/${submissionId}/cancel`, basePath);

  return handleRequest({ token, url, requestType: RequestType.POST, data: dataSet });
};

const removeSubmissionDocument = async (token: string, submissionId: Id, documentId: Id) => {
  const url = buildUrl(`/submission/${submissionId}/document/${documentId}`, basePath);

  return handleRequest({
    token,
    url,
    requestType: RequestType.DELETE,
    data: {},
  });
};

const createProduct = async (token: string, submissionId: Id, dataSet: unknown) => {
  const url = buildUrl(`/submission/${submissionId}/product`, basePath);

  return handleRequest({
    token,
    url,
    requestType: RequestType.POST,
    data: dataSet,
  });
};

const updateProduct = async (token: string, submissionId: Id, productId: Id, dataSet: unknown) => {
  const url = buildUrl(`/submission/${submissionId}/product/${productId}`, basePath);

  return handleRequest({ token, url, requestType: RequestType.PATCH, data: dataSet });
};

const removeProduct = async (token: string, submissionId: Id, documentId: Id) => {
  const url = buildUrl(`/submission/${submissionId}/product/${documentId}`, basePath);

  return handleRequest({
    token,
    url,
    requestType: RequestType.DELETE,
    data: {},
  });
};

const updateAssociatedContent = async (token: string, submissionId: Id, productId: string, dataSet: unknown) => {
  const url = buildUrl(`/submission/${submissionId}/product/${productId}/document-assignments/`, basePath);

  return handleRequest({ token, url, requestType: RequestType.POST, data: dataSet });
};

const addDocument = async (token: string, submissionId: Id, dataSet: unknown) => {
  const url = buildUrl(`/submission/${submissionId}/document`, basePath);

  return handleRequest({ token, url, requestType: RequestType.POST, data: dataSet });
};

const addDocumentVersion = async (token: string, submissionId: Id, documentId: Id, dataSet: unknown) => {
  const url = buildUrl(`/submission/${submissionId}/document/${documentId}`, basePath);

  return handleRequest({ token, url, requestType: RequestType.POST, data: dataSet });
};

const updateDocument = async (token: string, submissionId: Id, documentId: Id, dataSet: unknown) => {
  const url = buildUrl(`/submission/${submissionId}/document/${documentId}`, basePath);

  return handleRequest({ token, url, requestType: RequestType.PUT, data: dataSet });
};

const completeRetireDocument = async (token: string, submissionId: Id, documentId: Id, dataSet: unknown) => {
  const url = buildUrl(`/submission/${submissionId}/retire-document/${documentId}`, basePath);

  return handleRequest({ token, url, requestType: RequestType.PUT, data: dataSet });
};

const prepareDocumentForRetire = async (token: string, submissionId: number, documentId: string, dataSet: unknown) => {
  const url = buildUrl(`/submission/${submissionId}/document/${documentId}/retire`, basePath);

  return handleRequest({ token, url, requestType: RequestType.PUT, data: dataSet });
};

const downloadTaskFile = async (token: string, taskId: Id) => {
  const url = buildUrl(`/submission-task/${taskId}/original-file`, basePath);

  return handleFileRequest({ token, url, requestType: RequestType.GET });
};

const downloadTaskFileByRole = async (token: string, taskId: Id, fileRole: string, propagateNotFound?: boolean) => {
  const url = buildUrl(`/submission-task/${taskId}/fileRole/${fileRole}/file`, basePath);

  return handleFileRequest({ token, url, requestType: RequestType.GET, propagateNotFound: propagateNotFound ?? true });
};

const downloadNoteFile = async (token: string, taskId: Id, noteId: number, propagateNotFound?: boolean) => {
  const url = buildUrl(`/submission-task/${taskId}/note/${noteId}/file`, basePath);

  return handleFileRequest({ token, url, requestType: RequestType.GET, propagateNotFound: propagateNotFound ?? true });
};

const replaceTaskContent = async (token: string, taskId: Id, dataSet: unknown) => {
  const url = buildUrl(`/submission-task/${taskId}/content`, basePath);

  return handleRequest({ token, url, requestType: RequestType.PUT, data: dataSet });
};

const downloadDocumentFile = async (token: string, submissionId: Id, documentId: Id) => {
  const url = buildUrl(`/submission/${submissionId}/document/${documentId}/original-file`, basePath);

  return handleFileRequest({ token, url, requestType: RequestType.GET });
};

const downloadDocumentFileWithDocumentType = async (
  token: string,
  submissionId: Id,
  documentId: Id,
  documentType: DocumentType,
) => {
  const url = buildUrl(`/submission/${submissionId}/document/${documentId}/original-file/${documentType}`, basePath);

  return handleFileRequest({ token, url, requestType: RequestType.GET });
};

const discontinueProduct = async (token: string, submissionId: Id, productId: Id, dataSet: unknown) => {
  const url = buildUrl(`/submission/${submissionId}/product/${productId}/discontinuation`, basePath);

  return handleRequest({ token, url, requestType: RequestType.POST, data: dataSet });
};

const retireProduct = async (token: string, submissionId: Id, productId: Id, dataSet: unknown) => {
  const url = buildUrl(`/submission/${submissionId}/product/${productId}/retirement`, basePath);

  return handleRequest({ token, url, requestType: RequestType.POST, data: dataSet });
};

const reinstateProduct = async (token: string, submissionId: Id, productId: Id) => {
  const url = buildUrl(`/submission/${submissionId}/product/${productId}/reinstatement`, basePath);

  return handleRequest({ token, url, requestType: RequestType.POST });
};

const softDeleteSubmission = async (token: string, submissionId: Id) => {
  const url = buildUrl(`/submission/${submissionId}`, basePath);

  return handleRequest({ token, url, requestType: RequestType.DELETE, data: {} });
};

const getProductReportData = async (token: string, queryString: string) => {
  const url = buildUrl(`/report/product`, basePath, queryString);

  return handleFileRequest({ token, url, requestType: RequestType.GET, data: {} });
};

const getContentReportData = async (token: string, queryString: string) => {
  const url = buildUrl(`/report/content`, basePath, queryString);

  return handleFileRequest({ token, url, requestType: RequestType.GET, data: {} });
};

const fetchSubmissionTaskDetailsByDocumentAndType = async (
  token: string,
  submissionId: Id,
  documentId: Id,
  contentType: string,
) => {
  const url = buildUrl(`/submission/${submissionId}/document/${documentId}/type/${contentType}/task`, basePath);

  return handleRequest({ token, url, requestType: RequestType.GET, propagateNotFound: false });
};

export {
  addDocument,
  addDocumentVersion,
  updateDocument,
  prepareDocumentForRetire,
  fetchSubmission,
  fetchCompanySubmissions,
  postDraftFromProductGroup,
  updateSubmission,
  updateSubmissionSubstances,
  submitSubmission,
  validateSubmission,
  cancelSubmission,
  removeSubmissionDocument,
  createProduct,
  updateProduct,
  removeProduct,
  updateAssociatedContent,
  downloadTaskFile,
  downloadTaskFileByRole,
  replaceTaskContent,
  downloadDocumentFile,
  discontinueProduct,
  completeRetireDocument,
  reinstateProduct,
  softDeleteSubmission,
  fetchSubmissionTaskDetailsByDocumentAndType,
  getProductReportData,
  getContentReportData,
  generateProductGroupPlan,
  generateProductFamilyPlan,
  downloadNoteFile,
  updateSubmissionActiveIngredients,
  retireProduct,
  downloadDocumentFileWithDocumentType,
};
