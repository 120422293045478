import { IAnchorParams, ResponseContentType } from '@common/types';
import { FileRoles } from '@common/features/document/types';
import { useAppSelector } from '@common/hooks/redux';
import { selectToken } from '@common/features/user/selectors';
import { useQuery } from '@tanstack/react-query';
import { downloadTaskFileByRole, fetchSubmissionTaskDetailsByDocumentAndType } from '@common/services/submissionService';
import { DocumentType } from '@common/types';
import { IDocumentAttributes } from '@common/features/document/helpers';

const getFileName = (contentDisposition?: string) => contentDisposition?.split('filename=')[1].split(';')[0] as string;

export interface IContextHandleTaskFileByRole {
  alternativeTextFileName: string;
  openAlternativeTextFile: () => void;
}

const getAnchorParams = (response: any): IAnchorParams => {
  const fileName = getFileName(response?.headers['content-disposition'] as string) || 'Document file';
  const cleanFileName = fileName.replace(/"/g, '');
  const contentType = response?.headers['content-type'] as string;
  const file = new File([response.data], cleanFileName, { type: contentType });

  const url = window.URL.createObjectURL(file);

  return {
    url,
    cleanFileName,
    contentType,
  };
};

const openFile = (fileInfo: IAnchorParams) => {
  const a = document.createElement('a');
  a.href = fileInfo.url;
  if (fileInfo.contentType === ResponseContentType.docx || fileInfo.contentType === ResponseContentType.csv) {
    a.download = fileInfo.cleanFileName;
  }
  a.target = '_blank';
  a.click();
  setTimeout(function () {
    a.remove();
    URL.revokeObjectURL(fileInfo.url);
  }, 100);
};

export const useGetAlternativeTextFile = (submissionId: string, documentId: string, attributes: IDocumentAttributes) => {
  const token = useAppSelector(selectToken);

  const { data: alternativeTextFile } = useQuery<IAnchorParams | null>(
    ['fetchSubmissionTaskDetailsByDocumentAndType', submissionId, documentId, DocumentType.Epil],
    async () => {
      var taskDetails = await fetchSubmissionTaskDetailsByDocumentAndType(token, submissionId, documentId, DocumentType.Epil);
      if (!taskDetails) {
        return null;
      }

      var file = await downloadTaskFileByRole(token, taskDetails.submissionTaskId, FileRoles.AlternativeText, false);
      return file ? getAnchorParams(file) : null;
    },
    { enabled: !!token && !!submissionId && !!documentId && attributes?.canHaveAlternativeText },
  );

  return {
    alternativeTextFileName: alternativeTextFile?.cleanFileName,
    openAlternativeTextFile: () => {
      if (alternativeTextFile) {
        openFile(alternativeTextFile);
      }
    },
  };
};
