import DatapharmTable from '@components/DatapharmTable/DatapharmTable';
import { mapToProductGroupData, getProductGroupTableColumnsData } from './helpers';
import { createColumns } from '../../helpers';
import { SubmissionTaskDetailsUnitWrapper } from '../../styles';
import { IProductGroupTable } from './types';
import { HeaderWrapper, TableWrapper } from './styles';
import { TableOverflowContainer } from '@common/styles';

export const ProductGroupTable = ({
  submissionTask,
  productGroup,
  isLoading,
  productGroupChanges,
  onUpdateClick,
  documentAttributes,
  isReadonly,
}: IProductGroupTable) => {
  const columnsData = getProductGroupTableColumnsData(productGroupChanges, isReadonly, onUpdateClick);
  const columns = createColumns(columnsData);
  const documents = mapToProductGroupData(submissionTask, productGroup, productGroupChanges, documentAttributes);

  return (
    <div>
      {!isLoading && (
        <SubmissionTaskDetailsUnitWrapper>
          <HeaderWrapper>
            <span>Product group</span>
          </HeaderWrapper>
          <TableWrapper>
            <TableOverflowContainer>
              <DatapharmTable columns={columns} documents={documents!} data-testid="product-group-table" />
            </TableOverflowContainer>
          </TableWrapper>
        </SubmissionTaskDetailsUnitWrapper>
      )}
    </div>
  );
};
