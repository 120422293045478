import {
  IsTruthySelectItemLabel,
  IsTruthySelectItemValue,
  SubmissionTaskType,
  TaskTypes,
  OperationType,
  DocumentType,
  ErrorLog,
  IValidationModalErrors,
} from './types';
import { differenceInBusinessDays, formatDistanceStrict, format } from 'date-fns';
import { IActiveIngredient, ProductState } from './features/submission/types';
import { IActiveIngredientsDenominator, IIngredientWithNumerator } from './features/product/types';
import {
  documentTypeToCustomLabelMap,
  productTypeSelectOptions,
  dateFormat,
  dateTimeFormat,
  dateTimeFormatNoSeconds,
} from './constants';

export const generateFormProductName = (
  productName: string | undefined,
  productStrengths: IIngredientWithNumerator[],
  denominator: IActiveIngredientsDenominator,
  productForm: string,
  extraInformation: string,
) => {
  const strengths = productStrengths?.map((strength) => {
    const numeratorUnitText = strength.numeratorUnit?.label;
    const denominatorUnitText = denominator.denominatorUnit?.label;
    const numeratorText = numeratorUnitText ? `${strength.numeratorValue} ${numeratorUnitText}` : '';
    const denominatorText = denominatorUnitText ? `${denominator.denominatorValue} ${denominatorUnitText}` : '';

    return [numeratorText, denominatorText].filter((text) => text).join('/');
  });

  const name = [productName, strengths?.filter((text: string) => text).join(', '), productForm, extraInformation]
    .filter((x) => x)
    .join(' ');

  return name;
};

export const prepareIsTruthyDropdownDefaultOption = (dataSource: boolean | null = null, options: {
  label: IsTruthySelectItemLabel;
  value: IsTruthySelectItemValue;
}[]) => {
  if (dataSource !== null) {
    return {
      label: dataSource === true ? options[0].label : options[1].label,
      value: dataSource === true ? options[0].value : options[1].value,
    };
  }

  return null;
};


export const parseContentTypeToNew = (contentType: DocumentType): TaskTypes => {
  switch (contentType) {
    case DocumentType.Smpc:
      return TaskTypes.SmpcNew;
    case DocumentType.Pil:
      return TaskTypes.PilNew;
    case DocumentType.Epil:
      return TaskTypes.EpilNew;
    default:
      return TaskTypes.None;
  }
};

type SubmissionTaskTypeToString = {
  [key in SubmissionTaskType]?: string;
};

export const TaskTypeToStringMap: SubmissionTaskTypeToString = {
  [SubmissionTaskType.Smpc]: 'SmPC',
  [SubmissionTaskType.SmpcNew]: 'SmPC',
  [SubmissionTaskType.SmpcUpdate]: 'SmPC',
  [SubmissionTaskType.Pil]: 'PIL',
  [SubmissionTaskType.PilNew]: 'PIL',
  [SubmissionTaskType.QRDPilNew]: 'PIL',
  [SubmissionTaskType.PilUpdate]: 'PIL',
  [SubmissionTaskType.ePILNew]: 'ePIL',
  [SubmissionTaskType.ePILUpdate]: 'ePIL',
  [SubmissionTaskType.ePIL]: 'ePIL',
  [SubmissionTaskType.QRDEpilNew]: 'ePIL',
  [SubmissionTaskType.RMM]: 'RMM',
  [SubmissionTaskType.RMMNew]: 'RMM',
  [SubmissionTaskType.RMMUpdate]: 'RMM',
  [SubmissionTaskType.DHPC]: 'DHPC',
  [SubmissionTaskType.DHPCNew]: 'DHPC',
  [SubmissionTaskType.DHPCUpdate]: 'DHPC',
  [SubmissionTaskType.UserManual]: 'User Manual',
  [SubmissionTaskType.UserManualNew]: 'User Manual',
  [SubmissionTaskType.UserManualUpdate]: 'User Manual',
  [SubmissionTaskType.SafetyAlertNew]: 'Safety Alert',
  [SubmissionTaskType.SafetyAlertUpdate]: 'Safety Alert',
  [SubmissionTaskType.ProductInformationNew]: 'Product Information',
  [SubmissionTaskType.ProductInformationUpdate]: 'Product Information',
  [SubmissionTaskType.ProductGroupUpdate]: 'ProductGroupUpdate',
  [SubmissionTaskType.ProductGroupRename]: 'ProductGroupUpdate',
  [SubmissionTaskType.DocumentRetire]: 'DocumentRetire',
  [SubmissionTaskType.DocumentsRename]: 'DocumentsRename',
  [SubmissionTaskType.DocumentXmlUpdate]: 'DocumentXmlUpdate',
  [SubmissionTaskType.ProductDiscontinue]: 'Product',
  [SubmissionTaskType.AudioNew]: 'Audio',
  [SubmissionTaskType.AudioUpdate]: 'Audio',
  [SubmissionTaskType.VideoNew]: 'Video',
  [SubmissionTaskType.VideoUpdate]: 'Video',
};

type SubmissionTaskTypeToOperationType = {
  [key in SubmissionTaskType]?: OperationType;
};

export const TaskTypeToOperationMap: SubmissionTaskTypeToOperationType = {
  [SubmissionTaskType.SmpcNew]: OperationType.New,
  [SubmissionTaskType.SmpcUpdate]: OperationType.Update,
  [SubmissionTaskType.PilNew]: OperationType.New,
  [SubmissionTaskType.PilUpdate]: OperationType.Update,
  [SubmissionTaskType.ePILNew]: OperationType.New,
  [SubmissionTaskType.QRDEpilNew]: OperationType.New,
  [SubmissionTaskType.QRDPilNew]: OperationType.New,
  [SubmissionTaskType.ePILUpdate]: OperationType.Update,
  [SubmissionTaskType.ProductDiscontinue]: OperationType.Update,
  [SubmissionTaskType.RMMNew]: OperationType.New,
  [SubmissionTaskType.RMMUpdate]: OperationType.Update,
  [SubmissionTaskType.DHPCNew]: OperationType.New,
  [SubmissionTaskType.DHPCUpdate]: OperationType.Update,
  [SubmissionTaskType.UserManualNew]: OperationType.New,
  [SubmissionTaskType.UserManualUpdate]: OperationType.Update,
  [SubmissionTaskType.SafetyAlertNew]: OperationType.New,
  [SubmissionTaskType.SafetyAlertUpdate]: OperationType.Update,
  [SubmissionTaskType.ProductInformationNew]: OperationType.New,
  [SubmissionTaskType.ProductInformationUpdate]: OperationType.Update,
  [SubmissionTaskType.AudioNew]: OperationType.New,
  [SubmissionTaskType.AudioUpdate]: OperationType.Update,
  [SubmissionTaskType.VideoNew]: OperationType.New,
  [SubmissionTaskType.VideoUpdate]: OperationType.Update,
};

export const getTaskIcon = (value: SubmissionTaskType) => {
  var operation = TaskTypeToOperationMap[value];
  switch (operation) {
    case OperationType.New:
      return '/icons/new_tag.svg';
    case OperationType.Update:
      return '/icons/update_tag.svg';
    default:
      return '';
  }
};

export const getXmlIcon = (isValid: boolean) => {
  return isValid ? '/icons/valid_xml.svg' : '/icons/invalid_xml.svg';
};

export const getProductIcon = (value?: ProductState) => {
  switch (value) {
    case ProductState.New:
      return '/icons/new_tag.svg';
    case ProductState.Updated:
    case ProductState.Discontinued:
    case ProductState.Retired:
      return '/icons/update_tag.svg';
    default:
      return '';
  }
};

export const getDaysSince = (date: Date) => differenceInBusinessDays(new Date(), date);

export const getDaysSinceWithDistanceFormat = (date?: Date) => {
  if (!date) {
    return '-';
  }

  const value = !!date ? new Date(date!) : null;
  var diff = value ? formatDistanceStrict(new Date(), new Date(value), { unit: 'day', roundingMethod: 'floor' }) : null;
  return diff ? `${diff}` : '-';
};

export const formatDate = (date: Date) => format(date, dateFormat);

export const formatDateTime = (date: Date) => {
  const timeZone = new Intl.DateTimeFormat(undefined, { timeZoneName: 'short' }).formatToParts(date)[6].value;

  return format(date, dateTimeFormat) + ' ' + timeZone;
};

export const formatDateTimeNoSeconds = (date: Date) => format(date, dateTimeFormatNoSeconds);

export const downloadFile = (fileContent: string, fileName: string) => {
  const a = document.createElement('a');
  a.href = fileContent;
  a.download = fileName;
  a.click();
};

export const mapActiveIngredients = (activeIngredients: IActiveIngredient[]) => ({
  denominator:
    activeIngredients?.length > 0
      ? {
          value: activeIngredients[0].denominatorValue ? Number(activeIngredients[0].denominatorValue) : null,
          unit: activeIngredients[0].denominatorUnit ?? '',
        }
      : undefined,
  numerators:
    activeIngredients?.map((a) => ({
      value: a.numeratorValue ? Number(a.numeratorValue) : null,
      unit: a.numeratorUnit ?? '',
      name: a.name,
    })) ?? [],
});

export const getDocumentTypeLabel = (documentType: DocumentType) => {
  return documentTypeToCustomLabelMap[documentType] || documentType;
};

export const getProductTypeOption = (productType: string | undefined) => {
  return productTypeSelectOptions.find((o) => o.value === productType) ?? productTypeSelectOptions[0];
};

export const getProductTypeLabel = (productType: string | undefined) => {
  return productTypeSelectOptions.find((o) => o.value.toLowerCase() === productType?.toLowerCase())?.label ?? 'Unknown';
};

export const getIsoStringWithTimezoneHandling = (date: Date) => {
  const offsetInMinutes = date.getTimezoneOffset() * 60000;
  const parsedTimezoneIso = new Date(date.getTime() - offsetInMinutes).toISOString();

  return parsedTimezoneIso;
};

export const convertToDateWithTimezoneCompensation = (date: string | Date) => {
  var dateWithTimezone = new Date(date);
  return new Date(dateWithTimezone.getTime() + dateWithTimezone.getTimezoneOffset() * 60000);
};

export const handleHelpClick = () => window.open(process.env.REACT_APP_HELP_URL!);

export const boolToYesNo = (boolVal: boolean | null) => {
  if (boolVal === null) {
    return '-';
  }

  return boolVal ? 'Yes' : 'No';
};

export const getSubmissionTaskTypeDisplayValue = (taskType: SubmissionTaskType, contentType: DocumentType | null) => {
  return taskType === SubmissionTaskType.DocumentRetire && contentType
    ? `${TaskTypeToStringMap[taskType]} - ${contentType}`
    : TaskTypeToStringMap[taskType];
};

export const shouldRenderAlternativeTextFileSection = (contentType: DocumentType, hasDigitalVersion: boolean) =>
  [DocumentType.Pil, DocumentType.Epil].includes(contentType) && hasDigitalVersion;

export const isStringNullOrEmpty = (str: string | undefined | null) => {
  return !str || str.trim().length === 0;
};

export const mapValidationErrors = (errors: ErrorLog[]): IValidationModalErrors => ({
  productGroupErrors: errors.filter((e) => e.mainError === 'Product group information'),
  productsErrors: errors.filter((e) => e.mainError === 'Product Information'),
  contentsErrors: errors.filter((e) => e.mainError === 'Content Information'),
  productAssociationErrors: errors.filter((e) => e.mainError === 'Product does not have an associated SmPC or PIL'),
  contentAssociationErrors: errors.filter((e) => e.mainError === 'Content is not associated to a Product'),
  productUniquenessErrors: errors.filter((e) => e.mainError === 'Product is not unique'),
});

export const enhanceWithHttps = (val?: string) => {
  if (!val) {
    return val;
  }

  return `https://${val}`;
};

export const removeHttpPrefixFromLink = (link?: string) => {
  if (!link) {
    return undefined;
  }
  const splitByChar = '://';
  const valAfterPrefix = link.split(splitByChar)[1];

  return valAfterPrefix;
};

export const richTextEditorHttpsValidator = (value: string) => {
  if (!value) {
    return undefined;
  }

  const anchorTagPattern = new RegExp(/href="([^'"]+)/g);
  const noHttpsAnchors = Array.from(value.matchAll(anchorTagPattern), ([href, url]: any[]) => url).filter(
    (y) => !y.startsWith('https://'),
  );

  return noHttpsAnchors.length > 0 ? 'Please check that your links include https:// at the beginning' : undefined;
};
