import styled from 'styled-components';
import { SwitcherWrapper } from '@common/components/Switcher/styles';
import { DecoratedContentItem } from '@common/components/SideDecorationContent/styles';
import { StyledLink } from '@common/components/Link/styles';

export const ProductGroupWrap = styled.div`
  display: flex;
  flex-flow: column;
  padding: 20px;
  flex: 1;
  width: 100%;
  height: 100%;
  row-gap: 40px;
`;

export const ModalContentWrap = styled.div`
  display: flex;
  flex-flow: column;
`;

export const ModalTitle = styled.div`
  display: flex;
  font-size: ${({ theme }) => theme.elementFontSizes.modalTitle};
  font-weight: bold;
`;

export const LayoutWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: ${({ theme }) => theme.elements.customLayoutGrey};
  flex-grow: 1;
`;

export const Card = styled.div`
  background-color: white;
  border-radius: 14px;
  border: 1px solid ${({ theme }) => theme.elements.primaryLight};
  padding-bottom: 20px;
`;

export const Title = styled.span`
  display: inline-block;
  font-weight: 600;
  font-size: 22px;
`;

export const Subtitle = styled.span`
  display: inline-block;
  font-weight: 600;
  font-size: 18px;
`;

export const Description = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.small};
  color: ${({ theme }) => theme.elements.mediumGrey};
`;

export const BadgeElementWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 4px 0;
  width: fit-content;
  column-gap: 5px;
`;

export const ContentWrapper = styled.div`
  padding: 20px 25px 0 25px;
`;

export const TableWrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  margin-bottom: 35px;
  overflow-y: auto;

  ${StyledLink} {
    text-decoration: underline;
  }
`;

export const ContentHeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 28px 0;
`;

export const ContentHeaderSummary = styled.div``;

export const MainContentHeaderWrapper = styled(ContentHeaderWrapper)`
  margin: 15px 0 28px 25px;
`;

export const TabContentWrapper = styled(ContentWrapper)`
  ${SwitcherWrapper} {
    margin-bottom: 25px;
  }

  .addModal {
    z-index: 1;
  }

  .errorModal {
    z-index: 2;
  }
`;

export const RadioWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 1rem;
  column-gap: 1rem;
`;

export const InputWrapper = styled.div`
  width: 25rem;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  margin-bottom: 2rem;
`;

export const ProductGroupTabForm = styled.div`
  display: flex;
  max-width: 400px;
  flex-direction: column;
  row-gap: 1rem;
`;

export const TitleTableWrapper = styled.div`
  overflow: auto;
  max-height: 250px;
  flex: 1;
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  margin-right: 1rem;
`;

export const CreateSubmissionCompactWrap = styled.div`
  width: 100%;

  ${DecoratedContentItem} {
    margin-left: 1rem;

    &::before {
      display: none;
    }
  }

  table {
    margin-bottom: 1px;
  }
`;

export const TextWrapper = styled.div`
  white-space: pre;
  line-height: 3rem;
  margin-bottom: 1rem;
  margin-top: 0.5rem;
`;

export const ConfirmCard = styled(Card)`
  max-width: 54rem;
`;

export const AdditionalInfoCard = styled(Card)`
  max-width: 64rem;
`;

export const ShortTableWrapper = styled(TableWrapper)`
  max-width: 50rem;
`;

export const TaskType = styled.span`
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
  font-weight: 600;
  font-size: 16px;
`;
