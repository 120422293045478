import { useState } from 'react';
import { useUpdateSubmissionActiveIngredients } from './useUpdateSubmissionActiveIngredients';
import { ISubstanceDto, UpdateSubmissionActiveIngredientsCommand } from '../types';
import {
  UpdateProductGroupFormFields,
  useUpdateProductGroupForm,
} from '../SubmissionTaskDetails/hooks/useUpdateProductGroupForm';
import { generalFormErrors } from '@common/constants';

interface IUseUpdateProductGroupModal {
  submissionId: string;
  substances?: ISubstanceDto[];
  productGroupName?: string;
  onProductGroupUpdateSuccess?: () => void;
}

export const useUpdateProductGroupModal = ({ submissionId, substances, productGroupName, onProductGroupUpdateSuccess }: IUseUpdateProductGroupModal) => {
  const [isUpdateProductGroupModalOpen, setIsUpdateProductGroupModalOpen] = useState(false);
  const { updateSubmissionActiveIngredients } = useUpdateSubmissionActiveIngredients();
  const {
    methods: updateProductGroupMethods,
    handleSubmit,
    setError,
  } = useUpdateProductGroupForm({ productGroupName, substances });

  const updateProductGroupNameAndActiveIngredients = () => {
    handleSubmit((data) => {
      const payload: UpdateSubmissionActiveIngredientsCommand = {
        name: data.productGroup!,
        substances: data.activeIngredients.map((ingredient) => ({
          name: ingredient.label,
          id: ingredient.value,
        })),
      };

      updateSubmissionActiveIngredients(
        { submissionId, payload },
        {
          onSuccess: (data) => {
            if (data.doesProductGroupExist) {
              setError(UpdateProductGroupFormFields.productGroup, {
                type: 'alreadyExists',
                message: generalFormErrors.productGroupAlreadyExist,
              });
            } else {
              onProductGroupUpdateSuccess && onProductGroupUpdateSuccess();
              closeUpdateProductGroupModal();
            }
          },
        },
      );
    })();
  };

  const openUpdateProductGroupModal = () => {
    setIsUpdateProductGroupModalOpen(true);
  };

  const closeUpdateProductGroupModal = () => {
    setIsUpdateProductGroupModalOpen(false);
  };

  return {
    isUpdateProductGroupModalOpen,
    updateProductGroupMethods,
    updateProductGroupNameAndActiveIngredients,
    openUpdateProductGroupModal,
    closeUpdateProductGroupModal,
  };
};
