import { Button, ButtonTypes, HtmlContentModal, ModalWrapper, useHtmlContentModal } from '@common/components';
import { NotifyModal } from '@common/components/NotifyModal';
import { FileError } from '@common/components/NotifyModal/component/FileError';
import SubmissionFlowFooter from '@common/components/SubmissionFlowFooter/SubmissionFlowFooter';
import { documentTypeToCustomLabelMap } from '@common/constants';
import { getContentTypeFromTask, canUpdateProductDetailsInTask, getDocumentAttributes } from '@common/features/document/helpers';
import {
  DetailsTabs,
  ProductGroupTable,
  TaskDocumentTable,
  UpdateProductGroupModal,
} from '@common/features/submission/SubmissionTaskDetails/components';
import { mapHasDigitalVersion } from '@common/features/submission/SubmissionTaskDetails/components/TaskDocumentTable/helpers';
import { getChangedDocuments, getChangedProducts } from '@common/features/submission/SubmissionTaskDetails/helpers';
import { ValidateSubmissionErrors } from '@common/features/submission/ValidateSubmissionErrors/ValidateSubmissionErrors';
import { useChangeDocumentAssociation } from '@common/features/submissionTask/hooks/useChangeDocumentAssociation';
import { handleHelpClick } from '@common/helpers';
import { useDownloadAndOpenFile } from '@common/hooks/useDownloadAndOpenFile';
import { CustomerAllowedTaskActions, DocumentType, Id } from '@common/types';
import { HeaderBreadcrumb, Link } from '@components/SubmissionFlowHeader/styles';
import React, { useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router';
import AddDocumentModal from '../../components/AddDocumentModal/AddDocumentModal';
import { useReplaceDocumentModal } from '../../components/AddDocumentModal/hooks/useReplaceDocumentModal';
import AddProductPopup from '../../components/Product/AddProductPopup/AddProductPopup';
import SubmissionFlowHeader from '../../components/SubmissionFlowHeader/SubmissionFlowHeader';
import { DuplicateProductError } from '../../features/productGroup/components/DuplicateProductError';
import { ITaskDocumentTableCallbacks } from '@common/components/DatapharmTable/types';
import { useAddProduct } from '../../features/productGroup/hooks/useAddProduct';
import { useValidateSubmissionModal } from '../../features/productGroup/hooks/useValidateSubmissionModal';
import useAppNavigation from '../../routing/useAppNavigation';
import { ResubmitModal } from './components/ResubmitModal';
import { useAddResubmitNoteModal } from './hooks/useAddResubmitNoteModal';
import * as Styled from './styles';
import { useReplaceAlternativeTextFile } from '../../components/AddDocumentModal/hooks/useReplaceFile';
import ReplaceAlternativeTextFileModal from '../../components/AddDocumentModal/ReplaceAlternativeTextFileModal';
import { FileRoles } from '@common/features/document/types';
import { useUpdateProductGroupModal } from '@common/features/submission/hooks/useUpdateProductGroupModal';
import { useValidateSubmissionTaskData } from '@common/features/submissionTask/hooks/useValidateSubmissionTaskData';
import { ISubmissionTaskProps } from '@common/features/submission/types';
import { useUnsavedChanges } from '@common/context/hooks/useUnsavedChanges';
import { SubmissionTaskStatuses } from '@common/services/types';

export const TaskDetails = ({
  taskId,
  submissionId,
  submissionTask,
  submission,
  productGroupWithTaskApplied,
  onDocumentAssociationSuccess,
  onProductGroupUpdateSuccess,
}: ISubmissionTaskProps) => {
  const { submissionTaskId = '' } = useParams();

  const methods = useForm({
    mode: 'onChange',
    defaultValues: {
      file: undefined,
    },
  });

  const shouldRenderChangesModal = submissionTask?.status === SubmissionTaskStatuses.WithClientMoreInformation;

  const { handleUnsavedChanges } = useUnsavedChanges({
    shouldRenderModal: shouldRenderChangesModal,
    title: 'You currently have unsubmitted changes.\nAre you sure you want to continue?',
    subText: 'Any changes you have made will be saved.',
  });

  const { htmlContentModalParams, handleViewContent, closeHtmlContentModal, handleContentDescriptionModalOpen } =
    useHtmlContentModal();

  const {
    goToSubmissions,
    goToUpdateTaskDocumentContentDetails,
    goToReadOnlySubmissionTaskProductDetails,
    goToSubmissionTaskProductDetails,
  } = useAppNavigation();
  const { changeDocumentAssociation } = useChangeDocumentAssociation(submissionTask?.submissionId!);
  const { downloadAndOpen } = useDownloadAndOpenFile();

  const changedDocuments = getChangedDocuments(productGroupWithTaskApplied, submissionTask?.documentId);
  const changedDocumentsWithHasDigitalVersion = mapHasDigitalVersion(changedDocuments, submission?.documents);
  const changedProducts = getChangedProducts(productGroupWithTaskApplied, submissionTask?.documentId);
  const documentsInSubmission = productGroupWithTaskApplied?.documents ?? [];

  const linkedProductIds = changedProducts?.map((p) => p.productId);

  const taskDisplayName = `${submissionTask?.submissionId}/${submissionTask?.submissionTaskId}_${productGroupWithTaskApplied?.productGroup.productGroupName}`;
  const pageTitle = `Task details - ${taskDisplayName}`;
  const isEditable = submissionTask?.allowedCustomerActions.includes(CustomerAllowedTaskActions.CustomerCanEditTaskDetails);

  const contentType = useMemo(() => getContentTypeFromTask(submissionTask?.type!) as DocumentType, [submissionTask]);
  const documentId = submissionTask?.documentId.toString();

  const errorTitle = `There was an issue uploading your ${documentTypeToCustomLabelMap[contentType]}`;

  const { statusModalState, isResubmitting, handleCloseResubmitModal, handleStatusChange, resubmitCallback } =
    useAddResubmitNoteModal({ taskId: submissionTaskId! });

  const handleRedirectToContentDetails = (documentId: Id) => {
    const type = contentType ?? changedDocuments?.find((d) => d.documentId.toString() === documentId)?.type;
    goToUpdateTaskDocumentContentDetails(submissionId!, type, documentId, submissionTaskId);
  };

  const handleRedirectToProductPage = (productId: Id) => {
    goToSubmissionTaskProductDetails(submissionId!, productId, submissionTaskId);
  };

  const handleRedirectToReadOnlyProductPage = (productId: Id) => {
    goToReadOnlySubmissionTaskProductDetails(submissionId!, productId, submissionTaskId);
  };

  const handleReplaceAltTextModalOpen = (documentId: number) => {
    handleReplaceFileModalOpen();
  };

  const {
    isUpdateProductGroupModalOpen,
    updateProductGroupMethods,
    updateProductGroupNameAndActiveIngredients,
    openUpdateProductGroupModal,
    closeUpdateProductGroupModal,
  } = useUpdateProductGroupModal({
    submissionId: submissionId!,
    productGroupName: submission?.productGroup.productGroupName,
    substances: submission?.productGroup.substances,
    onProductGroupUpdateSuccess,
  });

  const handleAddProduct = (productId: number) => {
    const productsToAssociate = Array.from(new Set([...linkedProductIds!, productId]));
    return changeDocumentAssociation(
      { productIds: productsToAssociate, documentId: submissionTask?.documentId! },
      {
        onSuccess: () => {
          onDocumentAssociationSuccess && onDocumentAssociationSuccess();
          return handleRedirectToProductPage(productId);
        },
      },
    );
  };

  const handleFileDownload = (documentId: string) => downloadAndOpen({ submissionId, documentId });

  const {
    isProductModalOpen,
    duplicateProduct,
    isCreatingProduct,
    handleProductPopupOpen,
    handleProductCreate,
    handleDuplicateErrorClose,
    handleProductView,
    handleProductModalClose,
  } = useAddProduct({
    submission: productGroupWithTaskApplied!,
    handleSuccess: handleAddProduct,
    handleDuplicateProductCallback: handleRedirectToProductPage,
  });

  const {
    handleFileSubmit,
    handleUploadClick,
    handleRemove: handleFileRemove,
    handleCancel: handleDocumentAddCancel,
    handleOpenModal: handleDocumentAddModalOpen,
    clearErrors: clearDocumentAddErrors,
    uploadProgress: documentUploadProgress,
    isModalOpen: isAddDocumentModalOpen,
    isLoading: isFileLoading,
    secondaryDocument,
    fileErrors,
  } = useReplaceDocumentModal({
    contentType,
    methods,
    submissionId,
    taskId: submissionTaskId,
    existingDocuments: submission?.documents,
    customerLink: submissionTask?.customerLink,
  });

  const {
    handleFileSubmit: handleReplaceFileSubmit,
    handleUploadClick: handleReplaceFileUpload,
    handleCancel: handleReplaceFileCancel,
    handleOpenModal: handleReplaceFileModalOpen,
    uploadProgress: replaceFileProgress,
    isOpen: isReplaceFileModalOpen,
    isModalVariant,
    isLoading: isReplaceFileLoading,
  } = useReplaceAlternativeTextFile({
    contentType,
    methods,
    submissionId,
    existingDocuments: submission?.documents,
    fileRole: FileRoles.AlternativeText,
  });

  const { isValidateOpen, setIsValidateOpen, handleCancelValidationModal } = useValidateSubmissionModal(changedDocuments ?? []);

  const handleDocumentReplaceModalOpen = (documentId: number) => {
    handleDocumentAddModalOpen(submissionTask?.productGroupName!, documentId);
  };

  const { validationErrors, validateSubmissionTaskData } = useValidateSubmissionTaskData({
    taskId: submissionTask?.submissionTaskId!,
  });

  const handleValidateSubmissionTaskData = () => {
    validateSubmissionTaskData({ handleErrors: () => setIsValidateOpen(true), handleSuccess: handleStatusChange });
  };

  const documentTableActionCallbacks = (type?: DocumentType): ITaskDocumentTableCallbacks => {
    switch (type) {
      case DocumentType.Epil:
        return {
          onDocumentUpdate: handleRedirectToContentDetails,
          onDocumentReplace: handleDocumentReplaceModalOpen,
          onReplaceAltText: handleReplaceAltTextModalOpen,
        };
      default:
        return {
          onDocumentUpdate: handleRedirectToContentDetails,
          onDocumentReplace: handleDocumentReplaceModalOpen,
        };
    }
  };

  const documentAttributes = getDocumentAttributes(contentType!, false);

  const handleCancel = () => {
    handleUnsavedChanges(() => {
      goToSubmissions();
    });
  };

  return (
    <Styled.ProductManagementWrap>
      <SubmissionFlowHeader
        onBackClick={goToSubmissions}
        title={pageTitle}
        shouldRenderSubmissionInfo={false}
        productGroupId={productGroupWithTaskApplied?.productGroupId ?? null}
      >
        <HeaderBreadcrumb>
          <Link>Submissions</Link> &gt; <Link>Task details</Link> &gt; {taskDisplayName}
        </HeaderBreadcrumb>
      </SubmissionFlowHeader>
      <ProductGroupTable
        isLoading={false}
        submissionTask={submissionTask!}
        productGroup={productGroupWithTaskApplied?.productGroup}
        onUpdateClick={openUpdateProductGroupModal}
        documentAttributes={documentAttributes}
        isReadonly={!isEditable}
      />
      {submissionTask && changedDocumentsWithHasDigitalVersion?.length !== 0 && (
        <TaskDocumentTable
          isLoading={false}
          documents={changedDocumentsWithHasDigitalVersion!}
          submissionTask={submissionTask!}
          actionCallbacks={documentTableActionCallbacks}
          isReadonly={!isEditable}
          onDetailsClick={handleRedirectToContentDetails}
          onContentTitleClick={handleFileDownload}
          onContentDescriptionClick={handleContentDescriptionModalOpen}
          onViewContent={handleViewContent}
          isAdmin={false}
        />
      )}
      <Styled.DetailsWithButton>
        <Styled.DetailsButtonWrapper>
          {isEditable && canUpdateProductDetailsInTask(submissionTask?.type!) && (
            <Button
              type={ButtonTypes.PRIMARY_MEDIUM_BORDER}
              icon="/icons/plus-sign.svg"
              text="Add product"
              onClick={handleProductPopupOpen}
            />
          )}
        </Styled.DetailsButtonWrapper>
        <DetailsTabs
          submissionTaskId={submissionTaskId as string}
          isLoading={false}
          products={changedProducts!}
          documents={documentsInSubmission ?? []}
          isReadonly={!isEditable}
          showDocumentCountChanges={false}
          onUpdateClick={handleRedirectToProductPage}
          onDetailsClick={handleRedirectToReadOnlyProductPage}
          productsChanges={productGroupWithTaskApplied?.productsChanges}
          submissionTaskType={submissionTask?.type!}
          assignments={[]}
          showAttachmentsColumn={false}
        />
      </Styled.DetailsWithButton>
      <SubmissionFlowFooter
        cancelText="Close"
        onCancel={handleCancel}
        continueText="Submit"
        onContinue={isEditable ? handleValidateSubmissionTaskData : undefined}
      />

      {isAddDocumentModalOpen && (
        <FormProvider {...methods}>
          <AddDocumentModal
            type={contentType}
            onFileSubmit={handleFileSubmit}
            onUploadClick={handleUploadClick}
            onCancelClick={handleDocumentAddCancel}
            isOpen={isAddDocumentModalOpen}
            isUploading={isFileLoading}
            uploadProgress={documentUploadProgress}
            onRemove={handleFileRemove}
            secondaryDocumentProps={secondaryDocument}
          />
        </FormProvider>
      )}
      {isReplaceFileModalOpen && (
        <FormProvider {...methods}>
          <ReplaceAlternativeTextFileModal
            type={contentType}
            onFileSubmit={handleReplaceFileSubmit}
            onUploadClick={() => {
              handleReplaceFileUpload(submissionId ?? '', documentId ?? '');
            }}
            onCancelClick={handleReplaceFileCancel}
            isOpen={isReplaceFileModalOpen}
            modalVariant={isModalVariant}
            isUploading={isReplaceFileLoading}
            uploadProgress={replaceFileProgress}
            onRemove={handleFileRemove}
          />
        </FormProvider>
      )}
      {isProductModalOpen && (
        <AddProductPopup
          isOpen={isProductModalOpen}
          substances={productGroupWithTaskApplied?.productGroup.substances ?? []}
          productGroup={{
            id: productGroupWithTaskApplied?.productGroupId || null,
            name: productGroupWithTaskApplied?.productGroup.productGroupName || '',
            productType: productGroupWithTaskApplied?.productGroup.productType || '',
          }}
          isAddingProduct={isCreatingProduct}
          handleCreate={handleProductCreate}
          handleClose={handleProductModalClose}
        />
      )}
      <DuplicateProductError
        isOpen={!!duplicateProduct}
        productName={duplicateProduct ?? ''}
        onClose={handleDuplicateErrorClose}
        onProductView={handleProductView}
      />
      <NotifyModal title={errorTitle} isOpen={fileErrors.length > 0}>
        <FileError onClose={clearDocumentAddErrors} errors={fileErrors} type={contentType} />
      </NotifyModal>

      <ModalWrapper isOpen={statusModalState.isOpen} label="Add note">
        <ResubmitModal submitCallback={resubmitCallback} onClose={handleCloseResubmitModal} isUpdating={isResubmitting} />
      </ModalWrapper>
      <NotifyModal isOpen={isValidateOpen}>
        <ValidateSubmissionErrors
          onCancel={handleCancelValidationModal}
          validationErrors={validationErrors!}
          onProductErrorClick={handleRedirectToProductPage}
          onContentErrorClick={handleRedirectToContentDetails}
          onProductGroupErrorClick={() => {}}
          onProductAssociationErrorClick={() => {}}
          onContentAssociationErrorClick={() => {}}
        />
      </NotifyModal>
      <NotifyModal
        isOpen={isValidateOpen}
        title="Please add the following missing information"
        onClose={handleCancelValidationModal}
        onConfirm={handleHelpClick}
        confirmText="Help"
        width="50rem"
      >
        <ValidateSubmissionErrors
          onCancel={handleCancelValidationModal}
          validationErrors={validationErrors!}
          onProductErrorClick={handleRedirectToProductPage}
          onContentErrorClick={handleRedirectToContentDetails}
          onProductGroupErrorClick={() => {}}
          onProductAssociationErrorClick={() => {}}
          onContentAssociationErrorClick={() => {}}
        />
      </NotifyModal>

      <HtmlContentModal modalParams={htmlContentModalParams} onClose={() => closeHtmlContentModal()} />
      <ModalWrapper isOpen={isUpdateProductGroupModalOpen} label="Update Product Group" overrideWrap={true}>
        <FormProvider {...updateProductGroupMethods}>
          <UpdateProductGroupModal
            onConfirmation={updateProductGroupNameAndActiveIngredients}
            onClose={closeUpdateProductGroupModal}
          />
        </FormProvider>
      </ModalWrapper>
    </Styled.ProductManagementWrap>
  );
};
