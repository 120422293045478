import { Section } from '@common/components/Section/Section';
import { YesNoCheckbox } from '@common/components/YesNoCheckbox/YesNoCheckbox';
import { useFormContext } from 'react-hook-form';
import { Title } from '@common/styles';
import * as Styled from './styles';
import React from 'react';

interface IProps {
  field?: string;
  value?: boolean;
  disabled?: boolean;
  withBorderAndSubtext?: boolean;
  subtextSection?: JSX.Element;
  text: string;
  'data-testId'? : string;
}

export const FormCheckboxesSection = ({ field = '', value, disabled, withBorderAndSubtext, text, subtextSection, ...other }: IProps) => {
  const { watch, setValue } = useFormContext() ?? {};

  const handleChange = (value: boolean) => setValue(field, value);

  const renderCheckbox = () => <YesNoCheckbox value={watch ? watch(field) : value} disabled={disabled} onChange={handleChange} />;

  const dataTestId = field !== '' ? field : other['data-testId'];

  return withBorderAndSubtext ? (
    <Section data-testid={dataTestId} text={text} subtext={subtextSection}>
      {renderCheckbox()}
    </Section>
  ) : (
    <Styled.NoBorderAndSubtextWrapper>
      <Title small>{text}</Title>
      {renderCheckbox()}
    </Styled.NoBorderAndSubtextWrapper>
  );
};
