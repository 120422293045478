import { HookFormInput } from '@common/components/FormInput/HookFormInput';
import { FieldErrors } from 'react-hook-form';
import * as SharedStyled from '@common/styles';
import * as Styled from './styles';
import { ErrorMessage } from '@common/components/ErrorMessage';
import { generalFormErrors } from '@common/constants';

interface IProps {
  fieldName: string;
  disabled?: boolean;
  label?: string;
  errors?: FieldErrors<any>;
  onClearInput?: () => void;
  'data-testid'?: string;
}

export const HttpsPrefix = () => {
  return <div>https://</div>;
};

export const Weblink = ({ fieldName, label = 'WebLink', disabled, errors, onClearInput, ...props }: IProps) => {
  const error = !!errors ? errors[fieldName]?.message?.toString() : undefined;
  return (
    <Styled.TitleWrap>
      <span>{label}</span>
      <Styled.EditableUrlWrapper>
        <SharedStyled.StyledSVG src={`/icons/globe.svg`} />
        <HookFormInput
          disabled={disabled}
          labelEndSign="*"
          name={fieldName}
          id="documentWebLinkInput"
          placeholder="Web link"
          onClearInput={onClearInput}
          rules={{
            maxLength: {
              value: 200,
              message: generalFormErrors.documentTitleLength,
            },
            validate: (value) => {
              return value?.startsWith('http') || value?.startsWith('https')
                ? 'please check that your link does not include https:// or http://'
                : undefined;
            },
          }}
          PrefixElement={HttpsPrefix}
          data-testid={props['data-testid']}
        />
      </Styled.EditableUrlWrapper>
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </Styled.TitleWrap>
  );
};
