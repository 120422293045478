import { getDocumentTypeLabel } from '@common/helpers';
import { useFeatureFlags } from '@common/hooks/useFeatureFlags';
import { TaskTypes, DocumentType } from '@common/types';

export const useSubmissionTaskTypes = () => {
  const {
    showRmmDhpcUserManualOnAssociateContent,
    showSafetyAlertProductInformationOnAssociateContent,
    showAudioAndVideoOnAssociateContent,
    showLiveChat,
  } = useFeatureFlags();

  return [
    { id: 1, text: getDocumentTypeLabel(DocumentType.Smpc), value: TaskTypes.Smpc },
    { id: 2, text: getDocumentTypeLabel(DocumentType.Pil), value: TaskTypes.Pil },
    { id: 10, text: getDocumentTypeLabel(DocumentType.Epil), value: TaskTypes.Epil },

    showRmmDhpcUserManualOnAssociateContent && { id: 3, text: getDocumentTypeLabel(DocumentType.Rmm), value: TaskTypes.Rmm },
    showRmmDhpcUserManualOnAssociateContent && { id: 4, text: getDocumentTypeLabel(DocumentType.Dhcp), value: TaskTypes.Dhcp },
    showRmmDhpcUserManualOnAssociateContent && {
      id: 8,
      text: getDocumentTypeLabel(DocumentType.UserManual),
      value: TaskTypes.UserManual,
    },
    showSafetyAlertProductInformationOnAssociateContent && {
      id: 5,
      text: getDocumentTypeLabel(DocumentType.SafetyAlert),
      value: TaskTypes.SafetyAlert,
    },
    showSafetyAlertProductInformationOnAssociateContent && {
      id: 6,
      text: getDocumentTypeLabel(DocumentType.ProductInformation),
      value: TaskTypes.ProductInfo,
    },
    showAudioAndVideoOnAssociateContent && {
      id: 7,
      text: getDocumentTypeLabel(DocumentType.Audio),
      value: TaskTypes.Audio,
    },
    showAudioAndVideoOnAssociateContent && {
      id: 11,
      text: getDocumentTypeLabel(DocumentType.Video),
      value: TaskTypes.Video,
    },
    showLiveChat && { id: 9, text: getDocumentTypeLabel(DocumentType.LiveChat), value: TaskTypes.LiveChat },
  ].filter((x) => x);
};
