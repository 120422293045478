import { EmcProductLink, InfoWrapper, OpenState } from './styles';
import { StyledAccountIcon } from '@common/components/Header/styles';
import { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';

interface ICopyToClipboardWrapper {
  displayText: string;
}

export const CopyToClipboardWrapper = ({ displayText }: ICopyToClipboardWrapper) => {
  const [infoOpenState, setInfoOpenState] = useState<OpenState | undefined>();

  const handleCopyClick = () => {
    setInfoOpenState(OpenState.CLICK);
    setTimeout(() => {
      setInfoOpenState(undefined);
    }, 2000);
  };

  const handleMouseEnter = () => {
    infoOpenState !== OpenState.CLICK && setInfoOpenState(OpenState.HOVER);
  };

  const handleMouseLeave = () => {
    infoOpenState !== OpenState.CLICK && setInfoOpenState(undefined);
  };

  const infoText = {
    [OpenState.CLICK]: 'Saved to clipboard!',
    [OpenState.HOVER]: 'Copy to clipboard',
  };

  return (
    <CopyToClipboard text={displayText} onCopy={handleCopyClick}>
      <EmcProductLink onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        {displayText}
        <StyledAccountIcon src={`/icons/copy.svg`} />
        {infoOpenState && <InfoWrapper openState={infoOpenState}>{infoText[infoOpenState]}</InfoWrapper>}
      </EmcProductLink>
    </CopyToClipboard>
  );
};
