import { selectToken } from '@common/features/user/selectors';
import { useAppSelector } from '@common/hooks/redux';
import useWaitingMutation from '@common/hooks/useWaitingMutation';
import { requestEpil } from '@common/services/documentService';

export const useRequestEpil = () => {
  const token = useAppSelector(selectToken);

  const { mutate } = useWaitingMutation(({ documentId, submissionId, fileName, originalFileName }) =>
    requestEpil(token, submissionId, documentId, { fileName, originalFileName }),
  );

  return { requestEpil: mutate };
};
