import FileUpload from '@common/components/FileUpload/FileUpload';

interface Props {
  field?: string;
  value?: boolean;
  disabled?: boolean;
  withBorderAndSubtext?: boolean;
  isLoading?: boolean;
  uploadProgress: number;
  uploadedFileSize: number;
  uploadedFileName: string;
  onClose: () => void;
  handleFileSubmit: (files: FileList | null) => void;
  handleRemove: () => void;
}

export const ReplaceSecondaryFile = ({
  isLoading = false,
  uploadProgress,
  uploadedFileSize,
  uploadedFileName,
  handleFileSubmit,
  handleRemove,
  onClose,
}: Props) => {
  //Only PIL has a secondary document of a PDF
  const footer = '.PDF 3MB max';
  return (
    <>
      <FileUpload
        onFileSubmit={(files: FileList | null) => {
          handleFileSubmit(files);
          onClose();
        }}
        loadingPresents={uploadProgress}
        isLoading={isLoading}
        fileSize={uploadedFileSize + 'KB'}
        fileName={uploadedFileName}
        fileInputSubText={footer}
        onRemove={handleRemove}
        disabled={false}
      />
    </>
  );
};
