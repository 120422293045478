import { useRetireProduct } from '@common/hooks/useRetireProduct';
import { Id } from '@common/types';
import { useState } from 'react';

interface IProps {
  onRetireSuccess: () => void;
}

export const useRetireProductModal = ({ onRetireSuccess }: IProps) => {
  const [productIdToRetire, setProductIdToRetire] = useState<Id | undefined>();
  const { retireProduct, isRetiringProduct } = useRetireProduct();

  const handleRetireProductCancel = () => setProductIdToRetire(undefined);

  const handleRetireProductConfirm = () =>
    retireProduct(
      { productId: productIdToRetire ?? '' },
      {
        onSuccess: () => {
          setProductIdToRetire(undefined);
          onRetireSuccess();
        },
        onError: () => {
          onRetireSuccess();
        },
      },
    );

  const handleRetireClick = (productId: Id) => {
    setProductIdToRetire(productId);
  };

  return {
    handleRetireProductCancel,
    handleRetireProductConfirm,
    handleRetireClick,
    productIdToRetire,
    isRetiringProduct,
  };
};
