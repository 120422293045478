import { ISelectOption, Id } from '@common/types';
import useWaitingMutation from '@common/hooks/useWaitingMutation';
import { retireProduct } from '@common/services/submissionService';
import { useParams } from 'react-router';
import { useAppSelector } from './redux';
import { selectToken, selectUserId } from '@common/features/user/selectors';

interface Props {
  productId: Id;
}

export const useRetireProduct = () => {
  const { submissionId = '' } = useParams();
  const token = useAppSelector(selectToken);
  const userId = useAppSelector(selectUserId);

  const { mutate, isLoading } = useWaitingMutation(({ productId }: Props) =>
    retireProduct(token, submissionId, productId, { userId }),
  );

  return { retireProduct: mutate, isRetiringProduct: isLoading };
};
