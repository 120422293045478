import { useQuery } from '@tanstack/react-query';
import { useAppSelector } from '@common/hooks/redux';
import { selectToken } from '@common/features/user/selectors';
import { fetchProductHistory } from '@common/services/productService';

export const fetchProductHistoryQueryKey = 'fetchProductHistory';

export interface IProductHistoryDto {
  description: string;
  details: IHistoryDetail[] | null;
  performedBy: string;
  date: Date;
  smpcVersion: number | null;
  pilVersion: number | null;
  maNumbers: string[];
  legalCategories: string[];
  atcCodes: string[] | null;
  hasBlackTriangle: boolean;
}

export interface IHistoryDetail {
  text: string;
  html: string | null;
  webLink: string | null;
  isFileLink: boolean;
  isWebLink: boolean;
  isHtml: boolean;
  taskId: string | null;
}

interface IProductHistoryResult {
  items: IProductHistoryDto[];
  isLoading: boolean;
}

export const useFetchProductHistory = (id: string): IProductHistoryResult => {
  const token = useAppSelector(selectToken);

  const { data, isFetching } = useQuery<IProductHistoryDto[]>([fetchProductHistoryQueryKey], () =>
    fetchProductHistory(id, token),
  );

  return {
    items: data ?? [],
    isLoading: isFetching ?? true,
  };
};
