import { Title, Text } from '@common/styles';
import React from 'react';
import { ICheckboxEntry } from '@common/types';
import { CheckboxOption } from '@common/components/CheckboxOption/CheckboxOption';
import { useFormSelectedItems } from '@common/hooks/useFormSelectedItems';
import { FormFields } from '@common/features/document/types';
import * as Styled from '@common/features/document/styles';

interface Props {
  data: ICheckboxEntry[];
  isPrimaryDocument: boolean;
}

const ProductsToUpdate = ({ data, isPrimaryDocument }: Props) => {
  const { toggleItem, isItemSelected } = useFormSelectedItems(FormFields.productIds);

  const renderDetails = () => {
    return data!.map((item) => {
      const isSelected = isItemSelected(item);

      return (
        <CheckboxOption key={item.id} name={`product type ${item.id}`} item={item} onChange={toggleItem} isChecked={isSelected} />
      );
    });
  };

  return (
    <>
      <Styled.Wrapper>
        <Title small>Select Products to update</Title>
        {isPrimaryDocument && (
          <Text>
            Please note that products you do not select will need to be retired unless they are associated to another SmPC or PIL
          </Text>
        )}
        {renderDetails()}
      </Styled.Wrapper>
    </>
  );
};

export default ProductsToUpdate;
