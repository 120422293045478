import React from 'react';
import { ModalTitle } from '@common/features/productGroup/styles';
import { Button, ButtonTypes, ModalWithFooterWrapper } from '@common/components';
import * as Styled from './styles';
import Divider from '@common/components/Divider/Divider';

interface IProductGroupAlreadyExistsPopup {
  isOpen: boolean;
  handleClose: () => void;
  handleViewGroup: () => void;
}

const renderFooter = (handleClose: () => void, handleViewGroup: () => void) => {
  return (
    <Styled.ModalButtons>
      <Button
        data-testid="existing-product-group-popup__cancel-btn"
        onClick={handleClose}
        text="Cancel"
        type={ButtonTypes.TEXT}
      />
      <Divider type="vertical" size="50px" thickness={1} />
      <Button onClick={handleViewGroup} text="View existing product group" type={ButtonTypes.PRIMARY} />
    </Styled.ModalButtons>
  );
};

const ProductGroupAlreadyExistsPopup = ({ isOpen, handleClose, handleViewGroup }: IProductGroupAlreadyExistsPopup) => {
  return (
    <ModalWithFooterWrapper isOpen={isOpen} label="Product group already exists" footer={renderFooter(handleClose, handleViewGroup)}>
      <Styled.ModalContent>
        <Styled.ModalIcon src={`/icons/warning-sign.svg`} />
        <ModalTitle>Product group already exists</ModalTitle>
      </Styled.ModalContent>
    </ModalWithFooterWrapper>
  );
};

export default ProductGroupAlreadyExistsPopup;
