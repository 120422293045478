import { RegisterOptions, useFormContext } from 'react-hook-form';
import { IInput } from '../Input/Input';
import { RichTextEditor } from '../RichTextEditor/RichTextEditor';
import { useState } from 'react';

interface IHookFormRichTextEditor extends IInput {
  className?: string;
  rules?: RegisterOptions<any, string>;
  onChangeValue?: () => void;
  formField: string;
  toolbarOptions?: unknown[];
  readonly?: boolean;
  onRawContentChange?: (data: any) => string | undefined;
}

export const HookFormRichTextEditor = ({
  className,
  rules,
  onChangeValue,
  formField,
  toolbarOptions,
  readonly = false,
  onRawContentChange,
}: IHookFormRichTextEditor) => {
  const { setValue, watch, register } = useFormContext();

  if (rules) {
    register(formField, {
      ...rules,
    });
  }

  const [errorDetails, setErrorDetails] = useState<string | undefined>();

  const handleChange = (value: any, rawValue: any) => {
    var val = value === '<p></p>' ? undefined : value;
    setValue(formField, val, { shouldValidate: true, shouldDirty: true });
    if (onRawContentChange !== undefined) {
      setErrorDetails(onRawContentChange(rawValue));
    }
    onChangeValue && onChangeValue();
  };

  const value = watch(formField);

  return (
    <RichTextEditor
      fieldName={formField}
      value={value}
      errorInfo={errorDetails}
      onChange={handleChange}
      toolbarOptions={toolbarOptions}
      className={className}
      readOnly={readonly}
    />
  );
};
