import { documentTypeToCustomLabelMap } from '@common/constants';
import { useFeatureFlags } from '@common/hooks/useFeatureFlags';
import { DocumentType } from '@common/types';

export interface IFileTitleAndSummary {
  primaryFileDescription: string;
  secondaryFileDescription: string;
  primaryFileHeader: string;
  secondaryFileHeader: string;
}

export const useGeneratePrimaryFileDescription = (contentType: DocumentType, legacyPil: boolean): IFileTitleAndSummary => {
  const flags = useFeatureFlags();
  const documentTypeDescription = documentTypeToCustomLabelMap[contentType];

  const primaryFileDescription = `${
    flags.enableWordPil && contentType === DocumentType.Pil && !legacyPil ? 'QRD ' : ''
  }${documentTypeDescription}`;

  const secondaryFileDescription = `${
    flags.enableWordPil && contentType === DocumentType.Pil && !legacyPil ? 'PDF ' : ''
  }${documentTypeDescription}`;

  const primaryFileHeader =
    flags.enableWordPil && contentType === DocumentType.Pil && !legacyPil
      ? 'This is the word QRD document which we will use to convert to an ePil, e-Pils are specifcally formatted PILs, that are accessible to the visual impared and can be interpreted by screen readers'
      : '';

  const secondaryFileHeader =
    flags.enableWordPil && contentType === DocumentType.Pil && !legacyPil
      ? 'PDF copy of your PIL that uses can print and read it easily, please note that if you did not provide a seperate pdf version we have created one using the word document above.'
      : '';

  return {
    primaryFileDescription,
    secondaryFileDescription,
    primaryFileHeader,
    secondaryFileHeader,
  };
};
