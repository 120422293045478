import { createColumnHelper } from '@tanstack/react-table';
import DocumentStatus from '@common/components/DocumentStatus/DocumentStatus';
import { StyledLink } from '@common/components/Link/styles';
import { IDocumentDto } from '@common/features/submission/types';
import { Id, DocumentType } from '@common/types';
import { ISidebarSection } from '@common/components/Sidebar/types';
import { FeatureFlagsDto } from '@common/features/configuration/types';
import { StyledSVG } from '@common/styles';
import { EditableDocumentTitle } from './styles';
import { getDaysSinceWithDistanceFormat, formatDate } from '@common/helpers';
import { AssociatedContentReadonlySections } from '../../types';
import { IDocumentAttributes, getDocumentAttributes, getMediaContentType } from '@common/features/document/helpers';
import { DocumentContentCell } from '@common/features/document/components/DocumentContentCell';

interface IGetSections {
  showMissingAssociationWarning: boolean;
  showRetiredProductWarning: boolean;
  featureFlags: FeatureFlagsDto;
  disabledSections?: AssociatedContentReadonlySections;
}

export const getSections = ({
  showMissingAssociationWarning,
  showRetiredProductWarning,
  featureFlags: {
    showRmmDhpcUserManualOnAssociateContent,
    showSafetyAlertProductInformationOnAssociateContent,
    showAudioAndVideoOnAssociateContent,
    showImagesOnAssociateContent,
    showLiveChat,
  },
  disabledSections,
}: IGetSections) => {
  const getCommonPilSmpcAttributes = (documentType: DocumentType) => ({
    showWarning: showMissingAssociationWarning,
    disable: disabledSections?.pilSmpcSelection,
    requiresRetirementReason: getDocumentAttributes(documentType, false).requiresRetirementReason,
  });

  const getCommonSecondaryDocumentsAttributes = (documentType: DocumentType) => ({
    showWarning: showRetiredProductWarning,
    disable: disabledSections?.secondaryDocumentsSelection,
    requiresRetirementReason: getDocumentAttributes(documentType, false).requiresRetirementReason,
  });

  const items: ISidebarSection<DocumentType>[] = [
    {
      name: 'SmPC',
      additionalInformation: DocumentType.Smpc,
      ...getCommonPilSmpcAttributes(DocumentType.Smpc),
    },
    {
      name: 'PIL',
      additionalInformation: DocumentType.Pil,
      ...getCommonPilSmpcAttributes(DocumentType.Pil),
    },
  ];

  if (showRmmDhpcUserManualOnAssociateContent) {
    items.push(
      {
        name: 'RMM',
        additionalInformation: DocumentType.Rmm,
        ...getCommonSecondaryDocumentsAttributes(DocumentType.Rmm),
      },
      {
        name: 'DHPC',
        additionalInformation: DocumentType.Dhcp,
        ...getCommonSecondaryDocumentsAttributes(DocumentType.Dhcp),
      },
      {
        name: 'User manual',
        additionalInformation: DocumentType.UserManual,
        ...getCommonSecondaryDocumentsAttributes(DocumentType.UserManual),
      },
    );
  }

  if (showSafetyAlertProductInformationOnAssociateContent) {
    items.push(
      {
        name: 'Safety alert',
        additionalInformation: DocumentType.SafetyAlert,
        ...getCommonSecondaryDocumentsAttributes(DocumentType.SafetyAlert),
      },
      {
        name: 'Product information',
        additionalInformation: DocumentType.ProductInformation,
        ...getCommonSecondaryDocumentsAttributes(DocumentType.ProductInformation),
      },
    );
  }

  if (showAudioAndVideoOnAssociateContent) {
    items.push({
      name: 'Audio & video',
      additionalInformation: DocumentType.AudioVideo,
      ...getCommonSecondaryDocumentsAttributes(DocumentType.AudioVideo),
    });
  }

  if (showImagesOnAssociateContent) {
    items.push({ name: 'Images', additionalInformation: DocumentType.Images });
  }

  if (showLiveChat) {
    items.push({ name: 'Live chat', additionalInformation: DocumentType.LiveChat });
  }

  return items;
};

const ContentTitle = ({ value, documentId, onDocumentTitleClick }: any) => (
  <EditableDocumentTitle onClick={() => onDocumentTitleClick(documentId)}>
    {value}
    <StyledSVG src="/icons/update.svg" />
  </EditableDocumentTitle>
);

interface IPrepareColumns {
  disableUpdateDocumentTitle?: boolean;
  documentAttributes: IDocumentAttributes;
  onFileOpen?: (documentId: Id) => void;
  onDocumentTitleClick?: (documentId: Id) => void;
  onViewHtmlClick?: (html: string) => void;
}

export const prepareColumns = ({
  disableUpdateDocumentTitle,
  documentAttributes,
  onFileOpen,
  onDocumentTitleClick,
  onViewHtmlClick = () => {},
}: IPrepareColumns) => {
  const columnHelper = createColumnHelper<IDocumentDto>();

  return [
    columnHelper.accessor('documentTitle', {
      header: 'Content title',
      cell: ({ getValue, row }) =>
        disableUpdateDocumentTitle ? (
          getValue()
        ) : (
          <ContentTitle value={getValue()} documentId={row.original.documentId} onDocumentTitleClick={onDocumentTitleClick} />
        ),
    }),
    documentAttributes.showContentTypeColumn
      ? columnHelper.accessor('type', {
          header: 'Content type',
          cell: ({ getValue }) => getMediaContentType(getValue()),
        })
      : undefined,
    documentAttributes.showHtmlColumn
      ? columnHelper.accessor('htmlContent', {
          header: 'HTML',
          cell: ({ getValue }) => (
            <StyledLink onClick={() => onViewHtmlClick(getValue() ?? '')} to="#">
              View
            </StyledLink>
          ),
        })
      : columnHelper.accessor('originalFileName', {
          header:
            (documentAttributes.showRmmWebLink || documentAttributes.showAudioVideoWebLink) && !documentAttributes.allowFileUpload
              ? 'Weblink'
              : 'Content',
          cell: ({ getValue, row }) => <DocumentContentCell fileName={getValue()} row={row} onFileOpen={onFileOpen} />,
        }),
    columnHelper.accessor('version', {
      header: 'Version',
      cell: ({ getValue }) => getValue() ?? '-',
    }),
    documentAttributes.showHasDigitalVersion
      ? columnHelper.accessor('hasDigitalVersion', {
          header: 'ePIL',
          cell: ({ getValue }) => (getValue() ? 'Yes' : 'No'),
        })
      : undefined,
    columnHelper.accessor('lastUpdateDate', {
      header: 'Date last updated',
      cell: ({ getValue }) => getDate(getValue()),
    }),
    documentAttributes.showRegulatorApprovalDate
      ? columnHelper.accessor('regulatorApprovalDate', {
          header: 'Regulator approval date',
          cell: ({ getValue }) => getDate(getValue()),
        })
      : undefined,
    documentAttributes.showDaysSinceApproval
      ? columnHelper.accessor('regulatorApprovalDate', {
          header: 'Days since approval',
          id: 'daysSinceApproval',
          cell: ({ getValue }) => {
            const approvalDate = getValue();
            return getDaysSinceWithDistanceFormat(approvalDate);
          },
        })
      : undefined,
    documentAttributes.showHcpOnly
      ? columnHelper.accessor('isVisibleToHCP', {
          header: 'HCP only',
          cell: ({ getValue }) => (getValue() ? 'Yes' : 'No'),
        })
      : undefined,
    columnHelper.accessor('submittedDate', {
      header: 'Date submitted',
      cell: ({ getValue }) => getDate(getValue()),
    }),
    columnHelper.accessor('linkedProductIds', {
      header: 'Associated products',
      cell: ({ getValue }) => getValue()?.length ?? '-',
    }),
    columnHelper.accessor('documentId', {
      header: 'Content ID',
      cell: ({ getValue }) => getValue(),
    }),
    columnHelper.accessor('status', {
      id: 'status',
      header: 'Status',
      cell: (info) => <DocumentStatus value={info.getValue()} />,
    }),
  ].filter((x) => x);
};

const getDate = (date?: Date) => {
  const value = !!date ? new Date(date) : null;

  return value ? formatDate(value) : '-';
};
