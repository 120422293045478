import React, { useState } from 'react';
import { ActiveIngredientsSelection } from '@common/components/ActiveIngredientsSelection/ActiveIngredientsSelection';
import { useActiveIngredientSelection } from '@common/components/ActiveIngredientsSelection/hooks/useActiveIngredientSelection';
import { HookFormInput } from '@common/components/FormInput/HookFormInput';
import { useFetchActiveIngredients } from '@common/hooks/useFetchActiveIngredients';
import { useFormContext } from 'react-hook-form';
import * as Styled from './styles';
import { Button, ButtonTypes } from '@common/components';
import Divider from '@common/components/Divider/Divider';
import { UpdateProductGroupFormFields } from '../../hooks/useUpdateProductGroupForm';

interface IUpdateProductGroupModal {
  onClose?: () => void;
  onConfirmation?: () => void;
}

export const UpdateProductGroupModal = ({ onClose, onConfirmation }: IUpdateProductGroupModal) => {
  const [searchTerm, setSearchTerm] = useState('');
  const { activeIngredients, isFetchingActiveIngredients } = useFetchActiveIngredients(searchTerm);

  const {
    watch,
    formState: { errors },
  } = useFormContext();

  const handleConfirmation = () => {
    if (onConfirmation) {
      onConfirmation();
    }
  };
  const handleClose = () => {
    if (onClose) {
      onClose();
    }
  };

  const {
    handleActiveIngredientAdd,
    handleActiveIngredientInputChange,
    handleActiveIngredientRemove,
    handleDraftActiveIngredientChange,
    handleNoActiveIngredientsChange,
    activeIngredientToAdd,
  } = useActiveIngredientSelection({
    activeIngredientsFieldName: UpdateProductGroupFormFields.activeIngredients,
    noActiveIngredientsCheckboxFieldName: UpdateProductGroupFormFields.noActiveIngredientsCheckbox,
  });

  const selectedActiveIngredients = watch(UpdateProductGroupFormFields.activeIngredients);
  const isNoActiveIngredientsChecked = watch(UpdateProductGroupFormFields.noActiveIngredientsCheckbox);
  const noActiveIngredientsError = errors[UpdateProductGroupFormFields.noActiveIngredientsCheckbox]?.message?.toString();
  const alreadyExistsError = errors[UpdateProductGroupFormFields.activeIngredients]?.message?.toString();
  const productGroupAlreadyExistError = errors[UpdateProductGroupFormFields.productGroup]?.type === 'alreadyExists' ? errors[UpdateProductGroupFormFields.productGroup]?.message?.toString() : undefined;
  const productGroupRequiredError = errors[UpdateProductGroupFormFields.productGroup]?.type === 'required' ? errors[UpdateProductGroupFormFields.productGroup]?.message?.toString() : undefined;

  return (
    <Styled.UpdateProductGroupModal>
      <Styled.ModalContent>
        <Styled.ModalTitle>Update Product group</Styled.ModalTitle>
        <Styled.ModalEntry>
          <Styled.HeadingText>Product group</Styled.HeadingText>
          <HookFormInput
            name={UpdateProductGroupFormFields.productGroup}
            rules={{ required: 'Product group cannot be empty' }}
            error={productGroupRequiredError}
          />
        </Styled.ModalEntry>
        <Styled.ModalEntry>
          <Styled.HeadingText>Active Ingredient(s)</Styled.HeadingText>
          <ActiveIngredientsSelection
            activeIngredients={activeIngredients}
            isFetchingActiveIngredients={isFetchingActiveIngredients}
            activeIngredientToAdd={activeIngredientToAdd}
            selectedActiveIngredients={selectedActiveIngredients}
            isNoActiveIngredientsChecked={isNoActiveIngredientsChecked}
            noActiveIngredientsError={noActiveIngredientsError}
            alreadyExistsError={alreadyExistsError}
            onActiveIngredientAdd={handleActiveIngredientAdd}
            onActiveIngredientRemove={handleActiveIngredientRemove}
            onActiveIngredientInputChange={(value, action) => {
              setSearchTerm(value);
              handleActiveIngredientInputChange(value, action);
            }}
            onNoActiveIngredientsChange={handleNoActiveIngredientsChange}
            onDraftActiveIngredientChange={handleDraftActiveIngredientChange}
          />
        </Styled.ModalEntry>
      </Styled.ModalContent>
      <Styled.ModalFooter>
        <Styled.ModalError>{productGroupAlreadyExistError}</Styled.ModalError>
        <Styled.ModalButtons>
          <Button onClick={handleClose} text="Cancel" type={ButtonTypes.TEXT} />
          <Divider type="vertical" size="50px" thickness={1} />
          <Button onClick={handleConfirmation} text="Confirm" type={ButtonTypes.PRIMARY} />
        </Styled.ModalButtons>
      </Styled.ModalFooter>
    </Styled.UpdateProductGroupModal>
  );
};
