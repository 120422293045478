import React, { useState } from 'react';
import { MutationCache, QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import { useGetToken } from '@common/auth/msal';
import DefaultRoutes from 'apps/publisher-v3.ui.customer/src/routing/DefaultRoutes';
import MainContainer from './containers/MainContainer/MainContainer';
import { Toast } from '@common/components/Toast/Toast';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { AuthGuard } from '@auth/AuthGuard';
import { UnsavedChangesContextProvider } from '@common/context/UnsavedChangesContext';
import { useAppSelector } from '@common/hooks/redux';
import { selectShowPleaseWaitOverlay } from '@common/features/global/selectors';
import { PleaseWaitOverlay } from '@common/components/PleaseWaitOverlay';
import { useDelayedLoader } from '@common/components/PleaseWaitOverlay/useDelayedLoader';
import { selectActiveCompany } from '@common/features/user/selectors';
import { Watermark } from '@common/components/Watermark/watermark';
import axios from 'axios';
import { invokeDataLayer } from '@common/services/dataLayerService';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { MaintenanceInfo } from '@common/components/Maintenance/MaintenanceInfo';
import { selectConfiguration } from '@common/features/configuration/selectors';

Modal.setAppElement('#root');

function App() {
  useGetToken();
  invokeDataLayer();

  const configuration = useAppSelector(selectConfiguration);
  const showMaintenancePage = configuration.featureFlags.showMaintenancePage;
  const maintenanceMessage = configuration.maintenanceMessage;
  const isAuthenticated = useIsAuthenticated();
  const showPleaseWaitOverlay = useAppSelector(selectShowPleaseWaitOverlay);
  const companyId = useAppSelector(selectActiveCompany)?.id;

  axios.defaults.headers.common['Company-Id'] = companyId;
  const { showDelayedLoader } = useDelayedLoader(showPleaseWaitOverlay);
  const showReactQueryDevtools = process.env.REACT_APP_SHOW_REACT_QUERY_DEVTOOLS === 'TRUE';

  const { instance } = useMsal();

  const [queryClient] = useState<QueryClient>(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
            retry: 0,
          },
        },
        queryCache: new QueryCache({
          onError: (error: any) => {
            if (error.cause === 401) {
              instance.logoutRedirect({
                idTokenHint: instance.getActiveAccount()?.idToken,
              });
            }

            if (error.cause === 403) {
              toast.error('Not permitted to get data - 403');
            }

            if (error.cause === 404) {
              toast.error('Not found - 404');
            }
          },
        }),
        mutationCache: new MutationCache({
          onError: (error: any) => {
            if (error.cause === 401) {
              instance.logoutRedirect({
                idTokenHint: instance.getActiveAccount()?.idToken,
              });
            }

            if (error.cause === 403) {
              toast.error('Not permitted to get data - 403');
            }

            if (error.cause === 404) {
              toast.error('Not found - 404');
            }
          },
        }),
      }),
  );

  return (
    <QueryClientProvider client={queryClient!}>
      {showReactQueryDevtools && <ReactQueryDevtools />}
      <AuthGuard>
        <Watermark>{process.env.REACT_APP_PLATFORMNAME}</Watermark>
        {companyId && isAuthenticated && showMaintenancePage && (
          <MaintenanceInfo maintenanceMessage={maintenanceMessage} />
        )}
        {companyId && isAuthenticated && !showMaintenancePage && (
          <UnsavedChangesContextProvider>
            <MainContainer>
              <DefaultRoutes />
            </MainContainer>
          </UnsavedChangesContextProvider>
        )}
      </AuthGuard>
      <Toast />
      {showDelayedLoader && <PleaseWaitOverlay />}
    </QueryClientProvider>
  );
}

export default App;
