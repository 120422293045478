import * as Styled from './styles';
import { prepareColumns } from './helpers';
import { useFetchProductHistory } from './hooks/useFetchProductHistory';
import DatapharmTable from '@common/components/DatapharmTable/DatapharmTable';
import { useDownloadAndOpenFile } from '@common/hooks/useDownloadAndOpenFile';
import { Id } from '@common/types';
import { useThirdPartyLinkWarning } from '@common/features/global/ThirdPartyLinkWarning/useThirdPartyLinkWarning';
import { HtmlContentModal, useHtmlContentModal } from '@common/components';

const ProductHistory = ({ productId }: { productId: string }) => {
  const { items: productHistory, isLoading: isLoadingProductHistory } = useFetchProductHistory(productId);

  const { htmlContentModalParams, handleViewContent, closeHtmlContentModal } = useHtmlContentModal();

  const { downloadAndOpen } = useDownloadAndOpenFile();
  const handleFileOpen = (taskId: Id) => downloadAndOpen({ taskId });
  const columns = prepareColumns({
    productHistory,
    onFileOpen: handleFileOpen,
    onViewContent: handleViewContent,
  });

  return (
    <Styled.TabContentWrap>
      <Styled.Heading>
        <Styled.Title>Product History</Styled.Title>
        <span>This is the published history for your product.</span>
      </Styled.Heading>
      <Styled.Content>
        <Styled.TableWrapper>
          <DatapharmTable
            documents={productHistory}
            columns={columns}
            readonly={true}
            customMessage="There is no product history to display"
            showLoader={isLoadingProductHistory}
          />
        </Styled.TableWrapper>
      </Styled.Content>
      <HtmlContentModal modalParams={htmlContentModalParams} onClose={() => closeHtmlContentModal()} />
    </Styled.TabContentWrap>
  );
};

export default ProductHistory;
