export const ProductGroupTableColumnKeys = {
  productGroupName: 'productGroupName',
  maHolder: 'maHolder',
  mahName: 'mahName',
  companyName: 'companyName',
  productType: 'productType',
  submittedBy: 'submittedBy',
  dateSubmitted: 'dateSubmitted',
  actions: 'actions',
  changes: 'changes',
};
