import React from 'react';
import { useParams } from 'react-router';
import useAppNavigation from '../../routing/useAppNavigation';
import { ReadonlyProductContent } from '@common/features/product/components/ProductContent/ReadOnlyProductContent';
import { useFetchPublicProductGroupState } from '@common/features/productGroup/hooks/useFetchPublicProductGroupState';
import ProductContentSkeletonLoader from '@common/features/product/components/ProductContent/ProductContentSkeletonLoader';

const PublishedProduct = () => {
  const { goToPublishedProductGroup } = useAppNavigation();
  const { productId = '', productGroupId = '' } = useParams();

  const { publicProductGroupState, isFetchingPublicProductGroupState } = useFetchPublicProductGroupState(productGroupId!);

  const handleCancel = () => {
    goToPublishedProductGroup(productGroupId);
  };

  if (isFetchingPublicProductGroupState) {
    return <ProductContentSkeletonLoader />;
  }

  return (
    <ReadonlyProductContent
      shouldDisplayAmpIds={false}
      shouldDisplayContactGroup={false}
      dataSource={publicProductGroupState!}
      productId={productId}
      onCancel={handleCancel}
    />
  );
};

export default PublishedProduct;
