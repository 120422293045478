import styled, { css } from 'styled-components';

export enum OpenState {
  HOVER = 'HOVER',
  CLICK = 'CLICK',
}

interface IInfoState {
  openState?: OpenState;
}

const clickStateStyles = css`
  color: ${({ theme }) => theme.elements.defaultWhite};
  background-color: ${({ theme }) => theme.elements.success};
  border: 1px solid ${({ theme }) => theme.elements.success};
`;

const hoverStateStyles = css`
  color: ${({ theme }) => theme.elements.textGrey};
  background-color: ${({ theme }) => theme.elements.defaultWhite};
  border: 1px solid ${({ theme }) => theme.elements.borderGrey};
`;

const OPEN_STATE_STYLES = {
  [OpenState.CLICK]: clickStateStyles,
  [OpenState.HOVER]: hoverStateStyles,
};

export const EmcProductPanelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 80px;
  border: 2px solid ${({ theme }) => theme.elements.success};
  border-radius: 10px;
  padding: 8px;
  justify-content: center;
  align-items: center;
  position: relative;
`;

export const EmcProductPanelTitle = styled.span`
  color: ${({ theme }) => theme.elements.textGrey};
  font-weight: bold;
  font-size: ${({ theme }) => theme.elementFontSizes.default};
  margin-top: 12px;
`;

export const InfoWrapper = styled.div<IInfoState>`
  position: absolute;
  left: 35%;
  bottom: -65%;
  font-weight: bold;
  font-size: ${({ theme }) => theme.elementFontSizes.default};
  padding: 12px;
  border-radius: 4px;
  text-align: center;
  width: 180px;
  ${({ openState }) => openState && OPEN_STATE_STYLES[openState]};
`;

export const EmcProductLink = styled.div`
  display: flex;
  gap: 2px;
  align-items: center;
  cursor: pointer;
`;
