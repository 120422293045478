import { useQuery } from '@tanstack/react-query';
import { useAppSelector } from '@common/hooks/redux';
import { selectToken } from '@common/features/user/selectors';
import { fetchDocumentVersionHistory } from '@common/services/documentService';
import { IDocumentHistoryVersion } from '../model/IDocumentHistoryVersion';
import { DocumentType } from '@common/types';

export const fetchVersionHistory = 'fetchDocumentVersionHistory';

interface IDocumentVersionHistoryResult {
  items: IDocumentHistoryVersion[];
  isLoading: boolean;
}

export const useFetchDocumentVersionHistory = (documentId: number, documentType?: DocumentType): IDocumentVersionHistoryResult => {
  const token = useAppSelector(selectToken);

  const { data, isFetching } = useQuery<IDocumentHistoryVersion[]>(
    [fetchVersionHistory, documentType, documentId],
    () => fetchDocumentVersionHistory(token, documentType!, documentId),
    { enabled: !!token && !!documentType },
  );

  return {
    items: data ?? [],
    isLoading: isFetching,
  };
};
