import * as CommonStyles from '@common/styles';
import * as Styled from '../../styles';

export const SubmissionTaskSkeletonLoader = () => {
  return (
    <Styled.ProductManagementWrap>
      <Styled.LoaderHeaderWrap>
        <CommonStyles.RectangleSkeletonLoader height="100%" width="28rem" />
      </Styled.LoaderHeaderWrap>

      <Styled.SelectsWrapper>
        <CommonStyles.RectangleSkeletonLoader height="3rem" width="8rem" />
        <CommonStyles.RectangleSkeletonLoader height="3rem" width="15rem" />
        <CommonStyles.RectangleSkeletonLoader height="3rem" width="10rem" />
      </Styled.SelectsWrapper>
      <Styled.TableContentWrapper>
        <Styled.LoaderTableWrapper>
          <CommonStyles.RectangleSkeletonLoader height="10rem" width="100%" />
        </Styled.LoaderTableWrapper>
        <Styled.LoaderTableWrapper>
          <CommonStyles.RectangleSkeletonLoader height="15rem" width="100%" />
        </Styled.LoaderTableWrapper>
        <Styled.LoaderTableWrapper>
          <CommonStyles.RectangleSkeletonLoader height="10rem" width="100%" />
        </Styled.LoaderTableWrapper>
      </Styled.TableContentWrapper>
    </Styled.ProductManagementWrap>
  );
};
