import React from 'react';

import * as Styled from './styles';
import { Controller, useFormContext } from 'react-hook-form';
import { isTruthySelectOptions, glutenSelectOptions, lactoseSelectOptions } from '@common/constants';
import { Select } from '../Select/Select';
import { ProductSection } from '../ProductSection/ProductSection';
import {
  IProductForm,
  ProductForm,
  ProductFormPaths,
} from '@common/features/product/components/ProductContent/hooks/useProductForm';

interface IProps {
  readonly?: boolean;
}
export const AllergensAndPregnancyRisk = ({ readonly }: IProps) => {
  const { control } = useFormContext<IProductForm>();

  return (
    <Styled.Wrapper>
      <ProductSection elementId={ProductForm.allergensAndPregnancyRisk} heading="Allergens and pregnancy risk information">
        <Styled.Content>
          <Controller
            control={control}
            name={ProductFormPaths.isGlutenFree}
            render={({ field: { value, onChange } }) => (
              <Select
                label="Gluten free"
                placeholder=""
                name={`${ProductFormPaths.isGlutenFree}.select`}
                value={value ?? ''}
                onChange={onChange}
                options={glutenSelectOptions}
                disabled={readonly}
              />
            )}
          />
          <Controller
            control={control}
            name={ProductFormPaths.isLactoseFree}
            render={({ field: { value, onChange } }) => (
              <Select
                label="Lactose free"
                placeholder=""
                name={`${ProductFormPaths.isLactoseFree}.select`}
                value={value ?? ''}
                onChange={onChange}
                options={lactoseSelectOptions}
                disabled={readonly}
              />
            )}
          />
          <Controller
            control={control}
            name={ProductFormPaths.isSuitableForVegans}
            render={({ field: { value, onChange } }) => (
              <Select
                label="Suitable for vegetarians"
                placeholder=""
                name={`${ProductFormPaths.isSuitableForVegans}.select`}
                value={value ?? ''}
                onChange={onChange}
                options={isTruthySelectOptions}
                disabled={readonly}
              />
            )}
          />
          <Controller
            control={control}
            name={ProductFormPaths.isPregnancyRisk}
            render={({ field: { value, onChange } }) => (
              <Select
                label="Pregnancy risk information available"
                placeholder=""
                name={`${ProductFormPaths.isPregnancyRisk}.select`}
                value={value ?? ''}
                onChange={onChange}
                options={isTruthySelectOptions}
                disabled={readonly}
              />
            )}
          />
        </Styled.Content>
      </ProductSection>
    </Styled.Wrapper>
  );
};
