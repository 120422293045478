import styled from 'styled-components';
import { StyledButton } from '@common/components/Button/styles';
import { Button, Input } from '@common/components/';
import { ComponentWrap, InputWrap } from '@common/components/Input/styles';
import { CheckboxOptionWrap } from '@common/components/CheckboxOption/styles';
import { StyledSVG } from '@common/styles';

interface IActiveIngredientName {
  isListed?: boolean;
  readOnly?: boolean;
}

export const ActiveIngredientsContentWrap = styled.div`
  max-width: 390px;
  font-size: 14px;
  ${StyledButton} {
    width: 100%;
    margin-top: 10px;
  }
  ${ComponentWrap} {
    ${InputWrap} {
      padding: 10px 15px;
      height: auto;
      border-radius: 10px;

      input {
        font-size: ${({ theme }) => theme.elementFontSizes.input};
        margin: 2px;
        padding-top: 2px;
        padding-bottom: 2px;
      }
    }
  }

  ${CheckboxOptionWrap} {
    margin-bottom: 1rem;
    width: fit-content;
  }
`;

export const ActiveIngredientNameInput = styled(Input)`
  width: 100%;
`;

export const ActiveIngredientNameAddButton = styled(Button)`
  width: 100%;
`;

export const SelectedActiveIngredientsWrap = styled.div`
  margin-top: 10px;
  display: flex;
  flex-flow: column;
  row-gap: 10px;
`;

export const SelectedActiveIngredientWrap = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-flow: row;
`;

export const SelectedActiveIngredientName = styled.div<IActiveIngredientName>`
  font-size: ${({ theme }) => theme.fontSizes.small};
  padding: 13px 13px 13px 10px;
  width: 100%;
  border: 1px solid ${({ theme }) => theme.elements.borderGrey};
  color: ${({ theme, isListed }) => (isListed ? theme.elements.textGrey : theme.colors.red)};
  border-radius: 10px;
  margin-right: 8px;
`;

export const SelectedActiveIngredientRemoveButton = styled.div`
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.red};
  cursor: pointer;

  ${StyledSVG} {
    height: 30px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 24px;
      height: 25px;
    }
  }
`;

export const ActiveIngredientsSelectSearchHint = styled.small`
  font-style: italic;
  color: ${({ theme }) => theme.elements.textGrey};
`;
