import React from 'react';

import { ITaskDocumentTableCallbacks } from '@common/components/DatapharmTable/types';
import { ActionType, Id } from '@common/types';
import { Button, ButtonTypes } from '@common/components';
import theme from '@common/theme';

import * as Styled from './styles';
import { IDocumentAttributes } from '@common/features/document/helpers';

interface IDocumentActions {
  documentId: Id;
  actionTypes?: ActionType[];
  documentCallbacks: ITaskDocumentTableCallbacks;
  documentAttributes: IDocumentAttributes;
}

export const DocumentActions = ({ documentId, documentCallbacks, documentAttributes }: IDocumentActions) => {
  const handleDocumentUpdate = () => documentCallbacks.onDocumentUpdate!(documentId as string);
  const handleDocumentReplace = () => documentCallbacks.onDocumentReplace!(documentId as number);
  const handleDocumentRemove = () => documentCallbacks.onDocumentRemove!(documentId as string);
  const handleReplaceAltText = () => documentCallbacks.onReplaceAltText!(documentId as number);

  return (
    <div style={{ display: 'flex' }}>
      {documentCallbacks.onDocumentUpdate && (
        <Styled.ActionWrapper>
          <Button
            type={ButtonTypes.TEXT}
            text="Update"
            icon="/icons/update.svg"
            height={25}
            onClick={handleDocumentUpdate}
            color={theme.elements.primaryMedium}
          />
        </Styled.ActionWrapper>
      )}
      {documentCallbacks.onDocumentReplace && documentAttributes.showReplaceContentAction ? (
        <Styled.ActionWrapper className="white_space_nowrap">
          <Button
            type={ButtonTypes.TEXT}
            text="Replace content"
            icon="/icons/upload.svg"
            height={25}
            onClick={handleDocumentReplace}
            color={theme.elements.primaryMedium}
          />
        </Styled.ActionWrapper>
      ): null}

      {documentCallbacks.onReplaceAltText && (
        <Styled.ActionWrapper className="white_space_nowrap">
          <Styled.ColoredIcon color={theme.elements.primaryMedium}>
            <Button
              type={ButtonTypes.TEXT}
              text="Replace Alt Text"
              icon="/icons/file.svg"
              height={25}
              onClick={handleReplaceAltText}
              color={theme.elements.primaryMedium}
            />
          </Styled.ColoredIcon>
        </Styled.ActionWrapper>
      )}
      {documentCallbacks.onEditXml && (
        <Styled.ActionWrapper>
          <Button
            type={ButtonTypes.TEXT}
            text=""
            icon="/icons/edit_XML.svg"
            height={25}
            color={theme.elements.primaryMedium}
            onClick={documentCallbacks.onEditXml}
          />
        </Styled.ActionWrapper>
      )}
      {documentCallbacks.onViewHtml && (
        <Styled.ActionWrapper>
          <Button
            type={ButtonTypes.TEXT}
            text=""
            icon="/icons/view_html.svg"
            height={25}
            color={theme.elements.primaryMedium}
            onClick={documentCallbacks.onViewHtml}
          />
        </Styled.ActionWrapper>
      )}
      {documentCallbacks.onDocumentRemove && (
        <Styled.ActionWrapper>
          <Button
            type={ButtonTypes.TEXT}
            text="Remove"
            icon="/icons/trash_bin.svg"
            height={25}
            color={theme.elements.removeRed}
            onClick={handleDocumentRemove}
          />
        </Styled.ActionWrapper>
      )}
    </div>
  );
};
