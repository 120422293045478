import { useState } from 'react';
import { InputActionMeta } from 'react-select';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { formatActiveIngredientName } from './helpers';
import { ISelectOption } from '@common/types';
import { IActiveIngredientOption } from '@common/features/product/types';
import { generalFormErrors } from '@common/constants';

interface IUseActiveIngredientSelection {
  activeIngredientsFieldName: string;
  noActiveIngredientsCheckboxFieldName: string;
}

export const useActiveIngredientSelection = ({
  activeIngredientsFieldName,
  noActiveIngredientsCheckboxFieldName,
}: IUseActiveIngredientSelection) => {
  const [activeIngredientToAdd, setActiveIngredientToAdd] = useState<IActiveIngredientOption | ''>('');

  const {
    control,
    formState: { errors },
    getValues,
    setValue,
    clearErrors,
    setError,
    register,
    watch,
  } = useFormContext();

  register(noActiveIngredientsCheckboxFieldName, {
    validate: () => {
      return getValues(activeIngredientsFieldName).length === 0 && !getValues(noActiveIngredientsCheckboxFieldName)
        ? generalFormErrors.noActiveIngredients
        : undefined;
    },
  });

  const { prepend, remove } = useFieldArray({
    control,
    name: activeIngredientsFieldName,
  });

  const clearActiveIngredientsError = () => clearErrors(activeIngredientsFieldName);

  const handleDraftActiveIngredientChange = (o: ISelectOption) => {
    setActiveIngredientToAdd(o);
    clearActiveIngredientsError();
  };

  const handleActiveIngredientAdd = () => {
    if (activeIngredientToAdd !== '') {
      const activeIngredients: IActiveIngredientOption[] = getValues(activeIngredientsFieldName);

      const formattedString = formatActiveIngredientName((activeIngredientToAdd as IActiveIngredientOption).label);

      if (formattedString.trim().length === 0) return;

      const alreadyAdded = activeIngredients.find((i) => i.label === formattedString);

      if (alreadyAdded) {
        setError(activeIngredientsFieldName, { type: 'alreadyAdded', message: generalFormErrors.activeIngredientAlreadyAdded });
        return false;
      }

      prepend({ ...activeIngredientToAdd, label: formattedString });
      setActiveIngredientToAdd('');
      clearErrors([noActiveIngredientsCheckboxFieldName, activeIngredientsFieldName]);
      return true;
    }
  };

  const handleActiveIngredientRemove = (index: number) => {
    clearActiveIngredientsError();
    remove(index);
  };

  const handleActiveIngredientInputChange = (newValue: string, { action }: InputActionMeta) => {
    if (action !== 'input-change') return;

    clearActiveIngredientsError();
    setActiveIngredientToAdd({ label: newValue, value: '' });
  };

  const handleNoActiveIngredientsChange = () => {
    const isChecked = getValues(noActiveIngredientsCheckboxFieldName);
    if (!isChecked) {
      remove();
      setActiveIngredientToAdd('');
    }
    setValue(noActiveIngredientsCheckboxFieldName, !isChecked);
    clearErrors(noActiveIngredientsCheckboxFieldName);
  };

  return {
    activeIngredientToAdd,
    handleDraftActiveIngredientChange,
    handleActiveIngredientAdd,
    handleActiveIngredientRemove,
    handleActiveIngredientInputChange,
    handleNoActiveIngredientsChange,
    watch,
    errors,
  };
};
