import { StyledLink } from '@common/components/Link/styles';
import { NoContentError } from '@common/components/NoContentError/styles';
import { StyledSVG } from '@common/styles';
import { Table } from '@common/components/DatapharmTable/styles';
import styled, { css } from 'styled-components';

export const TabContentWrap = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  padding: 40px 60px;
  background-color: ${({ theme }) => theme.elements.defaultWhite};
`;

export const TabTitle = styled.div`
  display: flex;
  font-size: ${({ theme }) => theme.elementFontSizes.modalTitle};
  font-weight: bold;
  margin-bottom: 5px;
`;

export const HeadingSubtext = styled.div`
  font-size: ${({ theme }) => theme.elementFontSizes.sectionText};
  color: ${({ theme }) => theme.elements.textLightGrey};
  width: 60%;
`;

export const SidebarWrapper = styled.div`
  top: 0;
  left: 0;
  display: flex;
  flex-flow: column;
  padding-right: 20px;
  border-right: 1px solid ${({ theme }) => theme.elements.borderGrey};
  min-width: 200px;

  ${StyledLink} {
    font-size: ${({ theme }) => theme.fontSizes.small};
    padding: 15px 10px;
    border-radius: 4px;
  }
`;

const selectedStyle = css`
  background-color: ${({ theme }) => theme.elements.primaryLight};
  color: ${({ theme }) => theme.elements.primary};
`;

const unselectedStyle = css`
  background-color: white;
  color: ${({ theme }) => theme.elements.unselectedSidebarEntry};
  :hover {
    background-color: ${({ theme }) => theme.elements.primaryLight};
  }
`;

interface ISidebarEntry {
  isSelected: boolean;
}

export const SidebarEntry = styled.div<ISidebarEntry>`
  ${({ isSelected }) => (isSelected ? selectedStyle : unselectedStyle)};
  background-color: ${({ theme, isSelected }) => (isSelected ? theme.elements.primaryMedium : 'transparent')};
  color: ${({ theme, isSelected }) => (isSelected ? theme.elements.defaultWhite : theme.elements.textLightGrey)};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 10px;
  border-radius: 4px;
  font-size: 0.875rem;
  font-weight: 600;
  cursor: pointer;

  ${StyledSVG} {
    display: flex;
    align-items: center;
  }
`;

export const Heading = styled.div`
  display: flex;
  flex-flow: column;
  margin-bottom: 25px;
`;

export const Content = styled.div`
  display: flex;
  height: 100%;
`;

export const Wrapper = styled.div`
  padding: 0 0 20px 20px;
  width: 100%;
  overflow: auto;

  ${NoContentError} {
    margin-top: -0.5rem;
  }
`;

export const SectionWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 1.5rem;
  width: 100%;
`;

export const TableWrapper = styled.div`
  overflow: auto;
  max-height: 40rem;
  width: 100%;

  ${Table} {
    width: 100%;
    th:first-child,
    tr td:first-child {
      text-align: center;
    }
  }

  ${StyledLink} {
    text-decoration: underline;
  }
`;

export const EditableDocumentTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;
