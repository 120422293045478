import { DocumentType } from '@common/types';

// This reason is not used in the UI. It is added to submissionReasons in the epilCreateTask.
export const epilNewSubmissionReasons = [
  {
    id: 245,
    text: `${DocumentType.Smpc} requested for existing PIL`,
  },
  {
    id: 246,
    text: `PDF for QRD PIL`,
  }
];

export const smpcNewSubmissionReasons = [
  {
    id: 2,
    text: `New ${DocumentType.Smpc} for emc e.g. a ${DocumentType.Smpc} for an existing product, but one that is new for the emc`,
  },
  { id: 1, text: `New ${DocumentType.Smpc} for new product` },
  {
    id: 110,
    text: `New individual ${DocumentType.Smpc} (was previously included in a joint ${DocumentType.Smpc})`,
  },
];

export const pilNewSubmissionReasons = [
  {
    id: 37,
    text: `New ${DocumentType.Pil} for emc e.g. a ${DocumentType.Pil} for an existing product, but one that is new for the emc`,
  },
  { id: 36, text: `New ${DocumentType.Pil} for new product` },
  {
    id: 160,
    text: `New individual ${DocumentType.Pil} (was previously included in a joint ${DocumentType.Pil})`,
  },
];

export const smpcUpdateSubmissionReasons = [
  { id: 240, text: 'Addition of new strength to SmPC' },
  { id: 3, text: 'Change to section 1 - Name of quantative medicinal product' },
  { id: 4, text: 'Change to section 2 - Qualitative and quantative composition' },
  { id: 5, text: 'Change to section 3 - Pharmaceutical form' },
  { id: 6, text: 'Change to section 4.1 - Therapeutic indications' },
  { id: 7, text: 'Change to section 4.2 - Posology and method of administration' },
  { id: 8, text: 'Change to section 4.3 - Contraindications' },
  { id: 9, text: 'Change to section 4.4 - Special warnings and precautions for use' },
  { id: 10, text: 'Change to section 4.5 - Interaction with other medicinal products and other forms of interaction' },
  { id: 11, text: 'Change to section 4.6 - Fertility, pregnancy and lactation' },
  { id: 12, text: 'Change to section 4.7 - Effects on ability to drive and use machines' },
  { id: 13, text: 'Change to section 4.8 - Undesirable effects' },
  { id: 218, text: 'Change to section 4.8 - Undesirable effects - how to report side effect' },
  { id: 14, text: 'Change to section 4.9 - Overdose' },
  { id: 16, text: 'Change to section 5.1 - Pharmacodynamic properties' },
  { id: 17, text: 'Change to section 5.2 - Pharmacokinetic properties' },
  { id: 18, text: 'Change to section 5.3 - Preclinical safety data' },
  { id: 19, text: 'Change to section 6.1 - List of excipients' },
  { id: 20, text: 'Change to section 6.2 - Incompatibilities' },
  { id: 21, text: 'Change to section 6.3 - Shelf Life' },
  { id: 22, text: 'Change to section 6.4 - Special precautions for storage' },
  { id: 23, text: 'Change to section 6.5 - Nature and contents of container' },
  { id: 24, text: 'Change to section 6.6 - Special precautions for disposal and other handling' },
  { id: 25, text: 'Change to section 7 - Marketing authorisation holder' },
  { id: 26, text: 'Change to section 8 - Marketing authorisation number(s)' },
  { id: 27, text: 'Change to section 9 - Date of first authorisation/renewal of authorisation' },
  { id: 28, text: 'Change to section 10 - Date of revision of text' },
  { id: 134, text: 'Change to section 11 - Dosimetry' },
  { id: 135, text: 'Change to section 12 - Instructions for preparation of Radiopharmaceuticals' },
  { id: 156, text: 'Change to legal category' },
  { id: 242, text: 'Updated inline with QRD template and/or excipient guideline' },
  { id: 42, text: 'Addition of legal category' },
  { id: 117, text: 'Change to the extra statutory information' },
  { id: 31, text: 'Correction of spelling/typing errors' },
  { id: 44, text: 'Improved presentation of SmPC' },
  { id: 236, text: 'Previous version of SmPC reinstated' },
  { id: 34, text: 'Change from individual to joint SmPC' },
  { id: 35, text: 'Change from joint to individual SmPC' },
  { id: 244, text: 'Addition of black triangle' },
  { id: 40, text: 'Removal of black triangle' },
  { id: 91, text: 'Change in co-marketing arrangement' },
  { id: 87, text: 'Company name change or merger' },
  { id: 217, text: 'Product/presentation re-marketed' },
  { id: 238, text: 'Change of distributer' },
];

export const pilUpdateSubmissionReasons = [
  { id: 242, text: 'Updated inline with QRD template and/or excipient guideline' },
  { id: 233, text: 'Addition of new strength' },
  { id: 193, text: 'Change to section 1 - what the product is' },
  { id: 194, text: 'Change to section 1 - what the product is used for' },
  { id: 69, text: 'Change to section 2 - what you need to know - contraindictions' },
  { id: 68, text: 'Change to section 2 - what you need to know - warnings and precautions' },
  { id: 195, text: 'Change to section 2 - use in children/adolescents' },
  { id: 74, text: 'Change to section 2 - interactions with other medicines, food or drink' },
  { id: 76, text: 'Change to section 2 - pregnancy, breast feeding and fertility' },
  { id: 77, text: 'Change to section 2 - driving and using machines' },
  { id: 196, text: 'Change to section 2 - excipient warnings' },
  { id: 97, text: 'Change to section 3 - dose and frequency' },
  { id: 197, text: 'Change to section 3 - use in children/adolescents' },
  { id: 155, text: 'Change to section 3 - how to take/use' },
  { id: 198, text: 'Change to section 3 - duration of treatment' },
  { id: 70, text: 'Change to section 3 - overdose, missed and forgotten doses' },
  { id: 73, text: 'Change to section 4 - possible side effects' },
  { id: 72, text: 'Change to section 5 - how to store or dispose' },
  { id: 61, text: 'Change to section 6 - what the product contains' },
  { id: 63, text: 'Change to section 6 - what the product looks like and pack contents' },
  { id: 95, text: 'Change to section 6 - marketing authorisation holder' },
  { id: 169, text: 'Change to section 6 - marketing authorisation number(s)' },
  { id: 125, text: 'Change to section 6 - manufacturer' },
  { id: 80, text: 'Change to section 6 - date of revision' },
  { id: 235, text: 'Change of name of medicinal product' },
  { id: 199, text: 'Changes to other sources of information section' },
  { id: 163, text: 'Change to information for healthcare professionals' },
  { id: 81, text: 'Correction of spelling/typing errors' },
  { id: 45, text: 'Improved presentation of PIL' },
  { id: 88, text: 'Company name change or merger' },
  { id: 93, text: 'Change in co-marketing agreement' },
  { id: 190, text: 'Removal of one or more presentations from joint PIL' },
  { id: 149, text: 'Individual PILs superseded by joint PIL' },
  { id: 96, text: 'Joint PIL superseded by individual PILs' },
  { id: 217, text: 'Product/presentation remarketed' },
  { id: 237, text: 'Previous version of PIL reinstated' },
  { id: 244, text: 'Addition of black triangle' },
  { id: 168, text: 'Removal of black triangle' },
];

export const getNewSubmissionReasons = (contentType: DocumentType) => {
  if (contentType === DocumentType.Smpc) {
    return smpcNewSubmissionReasons;
  }

  if (contentType === DocumentType.Pil) {
    return pilNewSubmissionReasons;
  }

  return [];
};

export const getUpdateSubmissionReasons = (contentType: DocumentType) => {
  if (contentType === DocumentType.Smpc) {
    return smpcUpdateSubmissionReasons;
  }

  if (contentType === DocumentType.Pil) {
    return pilUpdateSubmissionReasons;
  }

  return [];
};
