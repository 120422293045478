import styled from 'styled-components';

export const StyledWrapper = styled.div`
  padding: 10px;
  overflow-y: auto;
  max-height: 80vh;
  min-height: auto;
`;

export const StyledWrapperUseFooter = styled.div`
  padding: 10px;
  overflow-y: auto;
  max-height: calc(80vh - 50px);
  min-height: auto;
`;
