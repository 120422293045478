import { buildUrl, handleRequest } from '@common/services/requestHelper';
import { RequestType } from '@common/services/types';
import { Id } from '@common/types';
import { DocumentType } from '@common/types';

const basePath = process.env.REACT_APP_API_PATH;

const fetchDocumentVersionHistory = async (token: string, documentType: DocumentType, documentId: number) => {
  const url = buildUrl(`/document/${documentType}/${documentId}/history`, basePath);

  return handleRequest({ token, url, requestType: RequestType.GET });
};

const requestEpil_Obsolete = async (token: string, submissionId: Id, documentId: Id) => {
  const url = buildUrl(`/submission/${submissionId}/document/${documentId}/request-epil`, basePath);

  return handleRequest({ token, url, requestType: RequestType.POST, data: {} });
};

const requestEpil = async (token: string, submissionId: Id, documentId: Id, data: unknown) => {
  const url = buildUrl(`/submission/${submissionId}/document/${documentId}/request-word-epil`, basePath);

  return handleRequest({ token, url, requestType: RequestType.POST, data });
};

export { fetchDocumentVersionHistory, requestEpil_Obsolete, requestEpil };
