import styled from 'styled-components';

export const LayoutWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: grid;
  min-height: 480px;
  min-width: 480px;

  @media screen and (min-width: 720px) {
    grid-template-columns: max-content 1fr;
  }

  @media screen and (min-height: 720px) {
    grid-template-rows: max-content 1fr max-content;
  }

  @media screen and (max-width: 720px) {
    grid-template-columns: max-content 1fr;
  }

  @media screen and (max-height: 720px) {
    grid-template-rows: max-content 1fr max-content;
  }
`;

export const SidebarWrapper = styled.div`
  grid-row: 1 / 2 span;
`;

export const HeaderWrapper = styled.div`
`;

export const ContentWrapper = styled.div`
  overflow: auto;
`;

export const FooterWrapper = styled.div`
  grid-column: 1 / 2 span;
`;
