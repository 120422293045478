import React, { Fragment, useEffect, useState } from 'react';
import {
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  getFilteredRowModel,
  useReactTable,
  SortingState,
  ExpandedState,
} from '@tanstack/react-table';
import * as Styled from './styles';
import { IDatapharmTable } from './types';
import NoContentPanel from '../NoContentPanel/NoContentPanel';
import { PleaseWaitLoader } from '../PleaseWaitLoader';
import { useDelayedLoader } from '../PleaseWaitOverlay/useDelayedLoader';
import { Checkbox, Radio } from '@common/components';
import { StyledSVG } from '@common/styles';

const DatapharmTable = <T,>({
  documents,
  columns,
  globalFilter,
  enableSorting = false,
  customSorting = undefined,
  readonly,
  onRowSelectionChange,
  initialColumnSort,
  selectedRowsIndices,
  expanded,
  showLoader,
  onRowExpand,
  hasSelectableRows,
  customMessage = undefined,
  ...props
}: IDatapharmTable<T>) => {
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [expandedLocal, setExpandedLocal] = useState<ExpandedState>({});

  const { showDelayedLoader } = useDelayedLoader(!!showLoader);

  const rowSelection = selectedRowsIndices
    ? selectedRowsIndices?.reduce((prev, cur) => Object.assign({ ...prev, [cur]: true }), {})
    : [];

  useEffect(() => {
    if (!initialColumnSort) {
      return;
    }

    const sortingDef = initialColumnSort.split('|');
    const sorting = [{ id: sortingDef[0], desc: sortingDef.length > 1 && sortingDef[1] === 'desc' }];

    setSorting(sorting);
  }, [initialColumnSort]);

  const handleRowSelection = (rowIndex: string) => {
    onRowSelectionChange && onRowSelectionChange(Number(rowIndex));
  };

  const table = useReactTable({
    data: documents,
    columns,
    enableSorting: !!enableSorting,
    state: {
      globalFilter,
      rowSelection,
      sorting: customSorting?.sortingState ?? sorting,
      expanded: expanded ?? expandedLocal,
    },
    manualSorting: customSorting !== undefined,
    onSortingChange: customSorting?.onSortingChange ?? setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onExpandedChange: setExpandedLocal,
    enableMultiRowSelection: false,
  });

  const { rows } = table.getRowModel();
  const someExpandable = rows.some((r) => !!(r.original as any)['expandableContent']);

  return showDelayedLoader ? (
    <Styled.CenteringWrapper>
      <PleaseWaitLoader small />
    </Styled.CenteringWrapper>
  ) : !showLoader && rows.length > 0 ? (
    <Styled.Table cellSpacing={0} data-testid={props['data-testid']}>
      <Styled.Header data-testid={props['data-testid'] + '-header'}>
        <tr data-testid={props['data-testid'] + '-header-row'}>
          {hasSelectableRows && <Styled.HeaderEntry>Select</Styled.HeaderEntry>}
          {someExpandable && <Styled.HeaderEntry />}
          {table.getFlatHeaders().map((header, i) => (
            <Styled.HeaderEntry
              key={header.id}
              className={header.column.getCanSort() ? 'sortable' : ''}
              onClick={header.column.getToggleSortingHandler()}
              data-testid={props['data-testid'] + `-header-cell-${i}`}
            >
              {flexRender(header.column.columnDef.header, header.getContext())}
              {header.column.getCanSort() && <Styled.SortIndicator className={(header.column.getIsSorted() as string) ?? null} />}
            </Styled.HeaderEntry>
          ))}
        </tr>
      </Styled.Header>
      <Styled.Body>
        {rows.map((row, i) => {
          const visibleCells = row.getVisibleCells();
          const cellsCount = visibleCells.length;
          const isExpanded = row.getIsExpanded();
          const details = (row.original as any)['expandableContent'];

          return (
            <Fragment key={row.id}>
              <Styled.Row
                isSelected={row.getIsSelected()}
                disabled={readonly}
                data-testid={props['data-testid'] && `${props['data-testid']}-row-${i}`}
              >
                {!!hasSelectableRows && (
                  <Styled.BodyEntry>
                    {hasSelectableRows === 'single' && (
                      <RadioCell
                        isChecked={row.getIsSelected()}
                        onChange={() => handleRowSelection(row.id)}
                        disabled={readonly ?? false}
                      />
                    )}
                    {hasSelectableRows === 'multiple' && (
                      <Checkbox
                        name={row.id}
                        isChecked={row.getIsSelected()}
                        onChange={() => handleRowSelection(row.id)}
                        disabled={readonly}
                      />
                    )}
                  </Styled.BodyEntry>
                )}
                {someExpandable && (
                  <Styled.BodyEntry rowSpan={isExpanded ? 2 : undefined} data-testid={props['data-testid'] + `-expand-row-${i}`}>
                    {details && (
                      <Styled.ExpandRowIcon>
                        <StyledSVG
                          data-testid={props['data-testid'] + `-expand-row-button-${i}`}
                          src={`/icons/${isExpanded ? 'collapse' : 'expand'}.svg`}
                          onClick={() => {
                            onRowExpand && onRowExpand(row, isExpanded);
                            row.toggleExpanded();
                          }}
                        />
                      </Styled.ExpandRowIcon>
                    )}
                  </Styled.BodyEntry>
                )}
                {visibleCells.map((cell, i) => (
                  <Styled.BodyEntry key={cell.id} data-testid={props['data-testid'] + `-cell-${i}`}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </Styled.BodyEntry>
                ))}
              </Styled.Row>
              {isExpanded && (
                <tr key={`${row.id}_details`}>
                  <Styled.Details colSpan={cellsCount}>{details}</Styled.Details>
                </tr>
              )}
            </Fragment>
          );
        })}
      </Styled.Body>
      <tfoot data-testid={props['data-testid'] + '-footer'}>
        {table.getFooterGroups().map((footerGroup) => {
          return (
            <tr key={footerGroup.id}>
              {footerGroup.headers.map((header) => {
                const { column } = header;
                return column.columnDef.footer ? (
                  <React.Fragment key={header.id}>{flexRender(column.columnDef.footer, header.getContext())}</React.Fragment>
                ) : null;
              })}
            </tr>
          );
        })}
      </tfoot>
    </Styled.Table>
  ) : !showLoader ? (
    <Styled.CenteringWrapper>
      <NoContentPanel title={customMessage ?? 'No results found'} />
    </Styled.CenteringWrapper>
  ) : null;
};

const RadioCell = ({
  isChecked = false,
  onChange,
  ...rest
}: {
  isChecked: boolean;
  onChange: (event: unknown) => void;
  disabled: boolean;
}) => {
  return <Radio size={24} isChecked={isChecked} name="radio" onClick={onChange} {...rest} />;
};

export default DatapharmTable;
