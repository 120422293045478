import { ReactNode } from 'react';
import { ButtonTypes } from '../Button/types';
import {
  FiltersContent,
  FiltersDropdownContent,
  FiltersDropdownCtaWrap,
  PopoverInvoker,
  PopoverInvokerButtons,
  PopoverInvokerTextWithBadge,
} from './styles';
import { Button } from '../Button/Button';
import { Popover } from 'react-tiny-popover';
import { StyledSVG } from '@common/styles';
import { FiltersBadge } from './FiltersBadge/FiltersBadge';

interface IProps {
  children: ReactNode;
  isOpen: boolean;
  appliedFiltersCount: number;
  onConfirm: () => void;
  onCancel: () => void;
  onToggle: () => void;
  onClearAll: () => void;
}

export const FiltersDropdown = ({ children, isOpen, appliedFiltersCount, onConfirm, onCancel, onToggle, onClearAll }: IProps) => {
  const handleClearAll = (e: any) => {
    onClearAll();
    e.stopPropagation();
  };

  return (
    <Popover
      isOpen={isOpen}
      positions={['bottom', 'left']}
      content={
        <FiltersDropdownContent>
          <FiltersContent>{children}</FiltersContent>
          <FiltersDropdownCtaWrap>
            <Button text="Apply" type={ButtonTypes.PRIMARY} onClick={onConfirm} />
            <Button text="Cancel" type={ButtonTypes.TEXT} onClick={onCancel} />
          </FiltersDropdownCtaWrap>
        </FiltersDropdownContent>
      }
    >
      <PopoverInvoker isOpen={isOpen} onClick={onToggle} data-testid="filters-expand">
        <PopoverInvokerTextWithBadge>
          <StyledSVG className="filter-decoration" src={`/icons/filters_icon.svg`} />
          Filters
          {appliedFiltersCount > 0 && <FiltersBadge appliedFiltersCount={appliedFiltersCount} />}
        </PopoverInvokerTextWithBadge>
        <PopoverInvokerButtons>
          {appliedFiltersCount > 0 && <StyledSVG onClick={handleClearAll} src={`/icons/cross_circle.svg`} />}
          <StyledSVG className="section-opener" src={`/icons/expand_tick.svg`} />
        </PopoverInvokerButtons>
      </PopoverInvoker>
    </Popover>
  );
};
