import { FieldValues, useForm } from 'react-hook-form';
import { IActiveIngredientOption } from '@common/features/product/types';
import { useMemo } from 'react';
import { ISubstanceDto } from '../../types';
import { mapSubstancesToActiveIngredients } from '@common/features/productGroup/helpers';

export enum UpdateProductGroupFormFields {
  productGroup = 'productGroup',
  activeIngredients = 'activeIngredients',
  noActiveIngredientsCheckbox = 'noActiveIngredientsCheckbox',
}

interface IUpdateProductGroupForm {
  [UpdateProductGroupFormFields.productGroup]?: string;
  [UpdateProductGroupFormFields.activeIngredients]: IActiveIngredientOption[];
  [UpdateProductGroupFormFields.noActiveIngredientsCheckbox]: boolean;
}

interface IUpdateProductGroupFormProps {
  productGroupName?: string;
  substances?: ISubstanceDto[];
}

export const useUpdateProductGroupForm = ({productGroupName, substances = []}: IUpdateProductGroupFormProps) => {
  const activeIngredients = useMemo(() => {
    return mapSubstancesToActiveIngredients(substances!);
  }, [substances]);

  const methods = useForm<IUpdateProductGroupForm, FieldValues>({
    mode: 'onSubmit',
    defaultValues: {
      [UpdateProductGroupFormFields.productGroup]: productGroupName,
      [UpdateProductGroupFormFields.activeIngredients]: activeIngredients,
      [UpdateProductGroupFormFields.noActiveIngredientsCheckbox]: false
    },
  });

  return { methods, ...methods };
};
