import { StyledButton } from '@common/components/Button/styles';
import { Header, Table } from '@common/components/DatapharmTable/styles';
import { HeadingText } from '@common/components/ProductDetails/styles';
import { StyledSVG } from '@common/styles';
import styled from 'styled-components';

export const SubmissionsWrap = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column;
  padding: 20px;

  ${HeadingText} {
    margin-bottom: 10px;
  }

  ${Table} {
    flex: 1;
    width: 100%;
  }

  ${Header} {
    top: -22px;
  }

  tr {
    td:first-child {
      width: 1rem;
    }
  }
`;

export const ExpandRowIcon = styled.div`
  display: flex;
  align-items: start;
  text-align: center;
  height: 100%;
  font-size: 24px;
  cursor: pointer;
  user-select: none;
`;

export const DetailsTableWrapper = styled.div`
  max-height: 500px;
  overflow: auto;

  ${Table} {
    width: 100%;
  }

  ${Header} {
    top: 0;
  }
`;

export const CenterFlex = styled.div`
  display: flex;
  justify-content: center;
`;

export const TaskType = styled.span`
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
  font-size: 16px;
`;

export const TableWrapper = styled.div`
  background-color: ${({ theme }) => theme.elements.defaultWhite};
  border: 1px solid ${({ theme }) => theme.colors.lightBlue};
  border-radius: 14px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 20px;

  ${StyledButton} {
    ${StyledSVG} {
      pointer-events: none;
    }
  }
`;

export const ResultsCountText = styled.span`
  color: ${({ theme }) => theme.elements.linkBlue};
  margin-bottom: 10px;
  font-weight: bold;
`;

export const LoadMoreButtonWrapper = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;
