import styled from 'styled-components';
import { Input } from '../Input/Input';
import { ClearIcon, InputWrap } from '../Input/styles';

export const FormInput = styled(Input)<{shouldRenderPrefix?: boolean}>`
  ${InputWrap} {
    padding-left: ${(props) => props.shouldRenderPrefix ? 0 : '10px'};
    padding-right: 15px;
    height: auto;
    border-radius: 10px;

    input {
      font-size: 16px;
      margin: 12px 0;
      padding-top: 2px;
      padding-bottom: 2px;
    }

    ${ClearIcon} {
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
