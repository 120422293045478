import React, { useMemo, useState } from 'react';
import * as Styled from './styles';
import { useParams } from 'react-router';
import { FormProvider } from 'react-hook-form';
import { useQueryClient } from '@tanstack/react-query';
import { useAddProduct } from '../../hooks/useAddProduct';
import { useProductGroupForm } from '@common/features/productGroup/hooks/useProductGroupForm';
import { useMaHolder } from '../../hooks/useMaHolder';
import { useUnsavedChanges } from '@common/context/hooks/useUnsavedChanges';
import { useUpdateSubmission } from '../../hooks/useUpdateSubmission';
import { useRemoveModal } from '../../../../components/RemoveModal/hooks/useRemoveModal';
import { queryKey } from '@common/features/submission/hooks/useFetchSubmission/useFetchSubmission';
import { useSubmissionSummary } from '../../hooks/useSubmissionSummary';
import { useValidateSubmissionModal } from '../../hooks/useValidateSubmissionModal';
import { useFetchTasks } from '@common/hooks/useFetchTasks';
import { useAddDocumentModal } from '../../../../components/AddDocumentModal/hooks/useAddDocumentModal';
import { useRetireContentModal } from '@common/features/document/components/RetireModal/hooks/useRetireContentModal';
import { useCancelModal } from '@hooks/useCancelModal';
import { usePrepareDocumentForRetire } from '../../hooks/usePrepareDocumentForRetire';
import { useRequestEpil_Obsolete } from '@common/features/document/hooks/useRequestEpil_Obsolete';
import { mapProductGroup, prepareProductsColumns } from '../../helpers';
import { handleHelpClick } from '@common/helpers';
import { default as ContentTab } from '../tabs/Content/Content';
import { default as ActiveIngredientsTab } from '@common/features/productGroup/components/ActiveIngredients/ActiveIngredientsTab';
import SubmissionFlowHeader from '../../../../components/SubmissionFlowHeader/SubmissionFlowHeader';
import NoContentPanel from '@components/NoContentPanel/NoContentPanel';
import MaHolderTab from '../tabs/MaHolder/MaHolder';
import ProductTypeTab from '../tabs/ProductType/ProductTypeTab';
import AddProductPopup from '../../../../components/Product/AddProductPopup/AddProductPopup';
import { HeaderBreadcrumb, Link } from '@components/SubmissionFlowHeader/styles';
import { DuplicateProductError } from '../DuplicateProductError';
import Tabs from '@common/components/Tabs/Tabs';
import Badge from '@common/components/Badge/Badge';
import { Button } from '@common/components/Button/Button';
import IconWithTooltip from '@common/components/IconWithTooltip/IconWithTooltip';
import DatapharmTable from '@components/DatapharmTable/DatapharmTable';
import SubmissionSummary from '../SubmissionSummary/SubmissionSummary';
import RemoveModal from '../../../../components/RemoveModal/RemoveModal';
import useAppNavigation from '../../../../routing/useAppNavigation';
import { NextStepsModal } from '@components/NextStepsModal';
import { useNextStepsModal } from '@components/NextStepsModal/useNextStepsModal';
import { Item } from '@common/components/NextStepsModal/components/Item';
import { NotifyModal } from '@common/components/NotifyModal';
import { FileError } from '@common/components/NotifyModal/component/FileError';
import AddDocumentModal from '../../../../components/AddDocumentModal/AddDocumentModal';
import { DiscontinueProductModal } from '@components/DiscontinueProductModal';
import { RetireProductModal } from '@components/RetireProductModal';
import { useDiscontinueProductModal } from '@components/DiscontinueProductModal/useDiscontinueProductModal';
import { useRetireProductModal } from '@components/RetireProductModal/useRetireProductModal';
import { HtmlContentModal, ModalWrapper, getSubstanceNames, useHtmlContentModal } from '@common/components';
import { RetireModal } from '@common/features/document/components/RetireModal/RetireModal';
import { CancelCreateSubmissionModal } from '../CancelCreateSubmissionModal/CancelCreateSubmissionModal';
import { useReinstatementProductModal } from '@components/RetainmentProductModal/useReinstatementProductModfal';
import { ReinstatementProductModal } from '@components/RetainmentProductModal';
import { ValidateSubmissionErrors } from '@common/features/submission/ValidateSubmissionErrors/ValidateSubmissionErrors';
import { ButtonTypes } from '@common/components/Button/types';
import { IProductTableCallbacks } from '@common/components/DatapharmTable/types';
import { ProductGroupTabs } from '../../types';
import { ITask, SubmissionUpdateCommand } from '../../../submission/types';
import { DocumentStatus, DocumentType, ISelectOption, SubmissionTaskType, TaskTypeToDocumentTypeMap } from '@common/types';
import {
  ProductStatus,
  ProductState,
  DocumentState,
  ISubmissionDto,
  IDocumentDto,
  IDocumentWithTask,
} from '@common/features/submission/types';
import { SubmissionConfirmationType } from '../../hooks/useSubmissionSummary/useSubmissionSummary';
import {
  ProductGroupWrap,
  LayoutWrapper,
  Card,
  Title,
  Description,
  Subtitle,
  BadgeElementWrapper,
  TableWrapper,
  ContentHeaderWrapper,
  ContentHeaderSummary,
  ContentWrapper,
  MainContentHeaderWrapper,
} from '@common/features/productGroup/styles';
import { useUpdateSubmissionSubstances } from '../../hooks/useUpdateSubmissionSubstances';
import { SubmissionStatus } from '@common/services/types';
import SubmissionFlowFooter from '@common/components/SubmissionFlowFooter/SubmissionFlowFooter';
import { PleaseWaitOverlay } from '@common/components/PleaseWaitOverlay';
import { ProductGroupFormFields } from '@common/features/productGroup/hooks/useProductGroupForm/types';
import { getDocumentAttributes } from '@common/features/document/helpers';
import { documentTypeToCustomLabelMap } from '@common/constants';
import { useValidateSubmissionData } from '@common/features/submission/SubmissionTaskDetails/hooks/useValidateSubmissionData';
import { selectConfiguration } from '@common/features/configuration/selectors';
import { useAppSelector } from '@common/hooks/redux';
import { useFetchEmail } from '@common/hooks/useFetchEmail';
import { useFeatureFlags } from '@common/hooks/useFeatureFlags';
import RequestEpilModal from '@common/features/document/components/RequestEpilModal/RequestEpilModal';
import { useRequestEpilModal } from '@common/features/document/hooks/useRequestEpilModal';

const productsTooltipText = 'We define a product as a combination of Brand/generic name, strength(s) and form';

const mapDocumentsAndTasks = (tasks: ITask[], documents: IDocumentDto[]) => {
  if (!tasks || !documents) return [];

  return documents
    .filter((document) => ![DocumentState.Unchanged, DocumentState.PendingDigitalVersion].includes(document.state))
    .map((document) => {
      const matchingTask = tasks.find(
        (task) => task.documentId === document.documentId && TaskTypeToDocumentTypeMap[task.type] === document.type,
      );

      const retireTask = tasks.find(
        (task) => task.documentId === document.documentId && task.type === SubmissionTaskType.DocumentRetire,
      );

      return {
        ...document,
        taskType: matchingTask?.type ?? retireTask?.type,
        submissionTaskId: matchingTask?.submissionTaskId ?? retireTask?.submissionTaskId,
        sla: matchingTask?.sla ?? null,
        customerLink: matchingTask?.customerLink,
      } as IDocumentWithTask;
    });
};

const errorIcon = '/icons/warning-sign-filled.svg';

interface ISubmissionEditableProductGroup {
  submission: ISubmissionDto;
  isLoading: boolean;
  successCallback: () => void;
}

const SubmissionEditableProductGroup = ({ submission, isLoading, successCallback }: ISubmissionEditableProductGroup) => {
  const { submissionId = '' } = useParams();
  const {
    goToProduct,
    goToHome,
    goToSubmissions,
    goToUpdateDocumentContentDetails,
    goToReadOnlyDocumentContentDetails,
    goToReadOnlyProduct,
  } = useAppNavigation();
  const [selectedTab, setSelectedTab] = useState<string>(ProductGroupTabs.Content);
  const [contentType, setContentType] = useState(DocumentType.Smpc);
  const { htmlContentModalParams, handleViewContent, closeHtmlContentModal } = useHtmlContentModal();

  const queryClient = useQueryClient();

  const { enableWordPil } = useFeatureFlags();

  const { updateSubmission } = useUpdateSubmission();
  const { requestEpil } = useRequestEpil_Obsolete();

  const documents = submission?.documents;
  const products = submission?.products;
  const { prepareDocumentForRetire } = usePrepareDocumentForRetire();
  const { updateSubmissionSubstances } = useUpdateSubmissionSubstances();
  const { modalState, setModalState, closeModal } = useRetireContentModal();
  const { isCancelModalOpen, handleCancelModalConfirm, openCancelModal, closeCancelModal } = useCancelModal({
    confirmRedirect: goToHome,
  });
  const { email, refetchEmail } = useFetchEmail();

  const handleProductRemoveClick = (productId: string, title: string) =>
    handleOpenProductRemoveModal(submissionId, productId, title);

  const handleProductDetailsClick = (productId: string) => goToReadOnlyProduct(submissionId, productId);

  const handleDocumentRetireClick = (documentId: string) => {
    const document = documents.find((d) => d.documentId.toString() === documentId);
    const { requiresRetirementReason } = getDocumentAttributes(document?.type!, false);

    if (requiresRetirementReason) {
      setModalState({ isOpen: true, documentId });
    } else {
      handleRetire(documentId);
    }
  };

  const handleDocumentDetailsClick = (documentId: string) => {

    const document = documents.find((d) => d.documentId.toString() === documentId);
    //There is no route for customer UI to view epil directly, therefore revert to PIL
    //Components Will decided whether to load epil or pil
    const docType = document?.type === DocumentType.Epil ? DocumentType.Pil : document?.type;
    goToReadOnlyDocumentContentDetails(submissionId,  docType ?? contentType, documentId);
  
  };

  const handleRetire = (documentId: string, reason?: ISelectOption) =>
    prepareDocumentForRetire(
      { submissionId: parseInt(submissionId), documentId, reason },
      {
        onSuccess: () => {
          const document = documents.find((d) => d.documentId.toString() === documentId);
          const { requiresRetirementReason } = getDocumentAttributes(document?.type!, false);

          if (requiresRetirementReason) {
            goToUpdateDocumentContentDetails(submissionId, contentType, documentId);
          } else {
            successCallback();
          }
        },
      },
    );

  const handleProductUpdateClick = (productId: string) => goToProduct(submissionId, productId);

  const {
    handleCancel: handleProductRemoveCancel,
    handleConfirm: handleProductRemoveConfirm,
    handleOpenModal: handleOpenProductRemoveModal,
    isModalOpen: isProductRemoveModalOpen,
    removeDetails: removeProductDetails,
  } = useRemoveModal({ type: 'product', onSuccess: () => successCallback() });

  const {
    handleCancel: handleDocumentRemoveCancel,
    handleConfirm: handleDocumentRemoveConfirm,
    handleOpenModal: handleOpenDocumentRemoveModal,
    isModalOpen: isDocumentRemoveModalOpen,
    removeDetails: removeDocumentDetails,
  } = useRemoveModal({ type: 'document', onSuccess: () => successCallback() });

  const {
    productIdToDiscontinue,
    isDiscontinuingProduct,
    handleDiscontinueProductCancel,
    handleDiscontinueProductConfirm,
    handleDiscontinueClick,
  } = useDiscontinueProductModal({ onDiscontinueSuccess: (productId) => goToProduct(submissionId, productId) });

  const { handleRetireProductCancel, handleRetireProductConfirm, handleRetireClick, productIdToRetire, isRetiringProduct } =
    useRetireProductModal({onRetireSuccess: () => successCallback()});

  const { productIdToReinstate, handleReinstateProductCancel, handleReinstateProductConfirm, handleReinstatementClick } =
    useReinstatementProductModal({ onReinstatementSuccess: () => successCallback() });

  const tableCallbacks: IProductTableCallbacks = {
    onRemoveClick: handleProductRemoveClick,
    onUpdateClick: handleProductUpdateClick,
    onDiscontinueClick: handleDiscontinueClick,
    onDetailsClick: handleProductDetailsClick,
    onReinstatementClick: handleReinstatementClick,
    onRetirementClick: handleRetireClick,
  };

  const columns = prepareProductsColumns(true, tableCallbacks);
  const {
    methods,
    formState: { isDirty, errors },
    handleReset,
    watch,
    trigger,
    getValues,
    clearErrors: clearFormErrors,
    showProductTypeWarning,
  } = useProductGroupForm({
    productType: submission?.productGroup.productType,
    isSameAsSubmitterCompany: !!submission?.productGroup.maHolderCompanyId,
    companyName: submission?.productGroup.maHolderCompanyName,
    substances: submission?.productGroup.substances,
  });

  const {
    isValidateOpen,
    setIsValidateOpen,
    handleCancelValidationModal,
    handleProductGroupErrorClick,
    handleProductErrorClick,
    handleContentErrorClick,
    handleProductAssociationErrorClick,
    handleContentAssociationErrorClick,
  } = useValidateSubmissionModal(documents ?? [], (errorLog) => {
    if (errorLog.subError === 'MA Holder') setSelectedTab(ProductGroupTabs.MaHolder);
    if (errorLog.subError === 'Product Type') setSelectedTab(ProductGroupTabs.ProductType);
  });

  const {
    submissionSummaryMethods,
    showSummary,
    saveInProgress,
    handleConfirmationClose,
    setShowSummary,
    showConfirmation,
    handleSummaryCancel,
    handleFileOpen,
    handleSummarySubmit,
    contentWithSameProductGroupsModalState,
    handleContentWithSameProductGroupsModalClose,
  } = useSubmissionSummary({
    submissionId,
    onSubmitSuccess: () => successCallback(),
  });

  const configuration = useAppSelector(selectConfiguration);
  const cmsLink = configuration.cmsLink;

  const emailExists = (): boolean => {
    return !email ? false : email.trimEnd().length > 0;
  };
  const { tasks } = useFetchTasks({ submissionId, enabled: showSummary });

  const updatedDocumentsWithTask = useMemo(() => mapDocumentsAndTasks(tasks, documents), [tasks, documents]);

  const handleSuccessValidation = () => {
    setShowSummary(true);
    successCallback();
  };

  const { validationErrors, validateSubmissionData } = useValidateSubmissionData({
    submissionId,
    handleErrors: () => setIsValidateOpen(true),
    handleSuccess: handleSuccessValidation,
  });

  const { handleUnsavedChanges } = useUnsavedChanges({
    shouldRenderModal: isDirty,
    title: 'You currently have unsaved changes.\nAre you sure you want to exit without saving?\nAny changes will be lost.',
  });

  const {
    isVisible,
    isSameAsSubmitterCompany,
    showMaHolderWarning,
    companyName,
    handleSameAsSubmitterCompanyChange,
    handleCompanyNameChange,
  } = useMaHolder(methods);

  const isContentTabSelected = selectedTab && selectedTab === ProductGroupTabs.Content;

  const showSmpcPilOption = !documents?.some(
    (d) => d.status === DocumentStatus.Draft && [DocumentType.Smpc, DocumentType.Pil].includes(d.type),
  );

  const showProductOption = !products?.some((p) => p.status === ProductStatus.Draft);

  const handleSubmissionUpdateSuccess = () => {
    setSelectedTab(ProductGroupTabs.Content);
    handleReset(true);
    queryClient.invalidateQueries([queryKey]);
  };

  const addProductSuccessCallback = (productId: number) => goToProduct(submissionId, productId);

  const {
    isProductModalOpen,
    duplicateProduct,
    activeIngredientsNames,
    isCreatingProduct,
    handleProductPopupOpen,
    handleProductCreate,
    handleDuplicateErrorClose,
    handleProductView,
    handleProductModalClose,
    setProductIsOpen,
  } = useAddProduct({ submission, handleSuccess: addProductSuccessCallback });

  const {
    handleFileSubmit,
    handleUploadClick,
    uploadErrors,
    handleRemove: handleFileRemove,
    handleCancel: handleDocumentAddCancel,
    handleOpenModal: handleDocumentAddModalOpen,
    clearErrors: clearDocumentAddErrors,
    uploadProgress: documentUploadProgress,
    isModalOpen: isAddDocumentModalOpen,
    isLoading: isFileLoading,
    showMissingProducts,
    fileErrors,
    productsMatchingDocument,
    isChangingDocumentAssociation,
    secondaryDocument,
    fileUploadFormMethods,
  } = useAddDocumentModal({
    contentType,
    submissionId,
    productGroupId: submission?.productGroupId ?? null,
    products: submission?.products,
    existingDocuments: submission?.documents,
  });

  const handleRequestEpilModalUploadSuccess = () => {
    queryClient.invalidateQueries([queryKey]);
  };

  const {
    handleRequestEpilModalFileSubmit,
    handleRequestEpilModalUploadClick,
    handleRequestEpilModalCancel,
    handleRequestEpilModalRemove,
    handleRequestEpilModalOpen,
    clearRequestEpilModalErrors,
    requestEpilModalFileErrors,
    isRequestEpilModalOpen,
    isRequestEpilModalLoading,
    requestEpilModalUploadProgress,
    requestEpilModalMethods,
  } = useRequestEpilModal(handleRequestEpilModalUploadSuccess);

  const {
    isNextStepsOpen,
    isDontShowAgainChecked,
    handleDontShowAgainCheck,
    handleNextStepsClose,
    handleProductGetStarted,
    handleSmpcGetStarted,
    handlePilGetStarted,
  } = useNextStepsModal({
    initialShowModal: (showSmpcPilOption || showProductOption) && !isLoading,
    onProductGetStarted: () => setProductIsOpen(true),
    onSmpcGetStarted: () => handleDocumentAddModalOpen(submission?.productGroup.productGroupName!),
    onPilGetStarted: () => {
      setContentType(DocumentType.Pil);
      handleDocumentAddModalOpen(submission?.productGroup.productGroupName!);
    },
  });

  const errorTitle = `There was an issue uploading your ${documentTypeToCustomLabelMap[contentType]}`;
  const requestEpilErrorTitle = `There was an issue uploading your ${documentTypeToCustomLabelMap[DocumentType.Epil]}`;

  const handleClose = () => clearDocumentAddErrors();

  const handleRequestEpilErrorsModalClose = () => clearRequestEpilModalErrors();

  const productType = watch(ProductGroupFormFields.productType)?.value;

  const handleSaveChanges = () => {
    trigger();
    if (
      selectedTab !== ProductGroupTabs.MaHolder &&
      selectedTab !== ProductGroupTabs.Content &&
      selectedTab !== ProductGroupTabs.ProductType &&
      selectedTab !== ProductGroupTabs.ActiveIngredients
    ) {
      clearFormErrors(ProductGroupFormFields.maHolder);
    }

    if (
      (selectedTab === ProductGroupTabs.MaHolder && errors.maHolder) ||
      (selectedTab === ProductGroupTabs.ProductType && errors.productType) ||
      (selectedTab === ProductGroupTabs.ActiveIngredients &&
        getValues(ProductGroupFormFields.activeIngredients).length === 0 &&
        getValues(ProductGroupFormFields.noActiveIngredientsCheckbox) === false)
    ) {
      return;
    }

    if (selectedTab === ProductGroupTabs.ActiveIngredients) {
      const activeIngredients = getValues(ProductGroupFormFields.activeIngredients);
      updateSubmissionSubstances({ submissionId, payload: { activeIngredients } }, { onSuccess: handleSubmissionUpdateSuccess });

      return;
    }

    const payload: SubmissionUpdateCommand = {
      productType: productType ?? '',
      submissionMetadata: {
        maHolder: {
          isSameAsSubmitterCompany: !!isSameAsSubmitterCompany,
          companyName,
        },
      },
    };

    updateSubmission({ submissionId, payload }, { onSuccess: handleSubmissionUpdateSuccess });
  };

  const handleTabSelected = (label: string) =>
    handleUnsavedChanges(() => {
      setSelectedTab(label);
      handleReset();
    });

  const handleProductPopupOpenClick = () =>
    handleUnsavedChanges(() => {
      handleProductPopupOpen();
      handleReset();
    });

  const handleCancel = () => {
    if (isContentTabSelected) {
      return openCancelModal();
    }
    handleUnsavedChanges(() => {
      setSelectedTab(ProductGroupTabs.Content);
      handleReset();
    });
  };

  const handleRequestEpil = (documentId: string) => {
    if (enableWordPil) {
      handleRequestEpilModalOpen(documentId);
    } else {
      const document = submission.documents.find((d) => d.documentId === parseInt(documentId));
      requestEpil(
        { documentId, submissionId: submission.submissionId },
        {
          onSuccess: () =>
            goToReadOnlyDocumentContentDetails(
              submission.submissionId.toString(),
              document?.type!,
              document?.documentId.toString()!,
            ),
        },
      );
    }
  };

  const substances = submission?.productGroup.substances;
  const activeIngredients = getSubstanceNames(substances);

  const isActiveIngredientsTabEditable =
    !submission.productGroupId &&
    (submission.status === SubmissionStatus.Draft || submission.status === SubmissionStatus.InProgress);

  //If a submission only consists of Document Assignments, then there will be no Product Changes,
  //state will be unchanged and the Summary Screen will appear empty.
  //Therefore updated products has been modified to also consider all Products that have the
  //status of draft.
  const updatedProducts = Array.from(
    new Set(
      submission?.products.filter(
        (p) =>
          p.state !== ProductState.Unchanged ||
          p.status === ProductStatus.Draft ||
          p.linkedDocumentIds.filter((v) => updatedDocumentsWithTask.map((o) => o.documentId).includes(v)).length > 0,
      ) ?? [],
    ),
  );

  const productToReinstateName = productIdToReinstate
    ? products?.find((x) => x.productId.toString() === productIdToReinstate.toString())!.name
    : '';

  const productToRetireName = productIdToRetire ? products?.find((x) => x.productId.toString() === productIdToRetire.toString())!.name : '';

  const handleRetrySummarySubmit = async () => {
    if (!emailExists()) {
      const e = await refetchEmail();
      handleSummarySubmit(e.toString());
    } else {
      await handleSummarySubmit(email);
    }
  };

  const onContentWithSameProductGroupsConfirm = () => {
    handleContentWithSameProductGroupsModalClose();
  };

  return showSummary ? (
    <FormProvider {...submissionSummaryMethods}>
      <SubmissionSummary
        submissionTitle={submission?.title ?? ''}
        submissionStatus={submission?.status!}
        pleaseWait={saveInProgress}
        tasks={updatedDocumentsWithTask ?? []}
        productGroup={mapProductGroup(submission)}
        products={updatedProducts}
        onCancel={openCancelModal}
        onFileOpen={handleFileOpen}
        onSubmit={() => handleSummarySubmit(email)}
        onBackClick={handleSummaryCancel}
        onViewContent={handleViewContent}
      />
      <HtmlContentModal modalParams={htmlContentModalParams} onClose={() => closeHtmlContentModal()} />
      <NotifyModal
        confirmText={'Retry'}
        onClose={handleConfirmationClose}
        onConfirm={handleRetrySummarySubmit}
        title={'There was an issue with your submission'}
        isOpen={showConfirmation === SubmissionConfirmationType.Failure}
        width={emailExists() ? '32rem' : '36rem'}
      >
        {!emailExists() && (
          <p>
            You don't have an email address associated to your account, please update your email using our&nbsp;
            <a target="_blank" rel="noreferrer" href={cmsLink}>
              Contact Management System (CMS)
            </a>
            &nbsp; If you continue to experience problems submitting, please contact{' '}
            <a href="mailto:servicedesk@datapharm.com">servicedesk@datapharm.com</a>
          </p>
        )}
        {emailExists() && (
          <p>
            Please retry, if you continue to experience problems submitting, please contact&nbsp;
            <a href="mailto:servicedesk@datapharm.com">servicedesk@datapharm.com</a>
          </p>
        )}
      </NotifyModal>
      <NotifyModal
        noIcon={true}
        onConfirm={() => {
          handleConfirmationClose();
          successCallback && successCallback();
        }}
        isOpen={showConfirmation === SubmissionConfirmationType.Successfull}
        width="32rem"
      >
        <h3>
          Thank you for your submission, you can review its progress{' '}
          <Link
            style={{ cursor: 'pointer' }}
            onClick={() => {
              goToSubmissions();
            }}
          >
            here
          </Link>
        </h3>
      </NotifyModal>

      <NotifyModal
        isOpen={contentWithSameProductGroupsModalState.isOpen}
        onConfirm={onContentWithSameProductGroupsConfirm}
        title="Content associated to more than one product group"
        width="700px"
      >
        Some of the content being updated in this submission is associated to more than one product group, updating this content
        will not update the content on the following product groups:
        <Styled.SameContentModalText>
          <ul>
            {contentWithSameProductGroupsModalState.productGroupsNames.map((productGroupName) => (
              <li>{productGroupName}</li>
            ))}
          </ul>
        </Styled.SameContentModalText>
        A draft submission has been created for these product groups which you can access from your submissions.
      </NotifyModal>

      <CancelCreateSubmissionModal isOpen={isCancelModalOpen} onClose={closeCancelModal} onConfirm={handleCancelModalConfirm} />
    </FormProvider>
  ) : (
    <>
      <LayoutWrapper>
        <SubmissionFlowHeader
          submissionTitle={submission?.title ?? ''}
          submissionStatus={submission?.status!}
          title={submission?.productGroup.productGroupName}
          activeIngredients={activeIngredients}
          productGroupId={submission?.productGroupId ?? null}
        >
          <HeaderBreadcrumb>
            <Link>Product Family</Link> &gt; Product Group
          </HeaderBreadcrumb>
        </SubmissionFlowHeader>
        <ProductGroupWrap>
          <Card>
            <MainContentHeaderWrapper>
              <ContentHeaderSummary>
                <Title>Product group management</Title>
                <Description>This is where you can add products and content that relate to this product group</Description>
              </ContentHeaderSummary>
            </MainContentHeaderWrapper>
            <FormProvider {...methods}>
              <Tabs
                selectedTab={selectedTab}
                onTabSelected={handleTabSelected}
                items={[
                  {
                    label: ProductGroupTabs.Content,
                    content: (
                      <ContentTab
                        documents={documents ?? []}
                        onContentAdd={(productGroupName, documentId, mode) =>
                          handleDocumentAddModalOpen(productGroupName, documentId, undefined, undefined, mode)
                        }
                        onContentRemove={(documentId: string, title: string) =>
                          handleOpenDocumentRemoveModal(submissionId, documentId, title)
                        }
                        onContentRetire={handleDocumentRetireClick}
                        onContentTypeSelect={(contentType) => setContentType(contentType)}
                        onContentDetailsClick={handleDocumentDetailsClick}
                        onRequestEpil={handleRequestEpil}
                        contentType={contentType}
                      />
                    ),
                  },
                  {
                    label: ProductGroupTabs.ActiveIngredients,
                    content: (
                      <ActiveIngredientsTab
                        isEditable={isActiveIngredientsTabEditable}
                        activeIngredientsNames={activeIngredientsNames}
                        activeIngredientsFieldName={ProductGroupFormFields.activeIngredients}
                        noActiveIngredientsCheckboxFieldName={ProductGroupFormFields.noActiveIngredientsCheckbox}
                      />
                    ),
                    'data-testid': 'active-ingredients-tab',
                  },
                  {
                    label: ProductGroupTabs.MaHolder,
                    content: (
                      <MaHolderTab
                        isVisible={isVisible}
                        isSameAsSubmitterCompany={isSameAsSubmitterCompany}
                        companyName={companyName}
                        showWarning={showMaHolderWarning}
                        onSameAsSubmitterCompanyChange={handleSameAsSubmitterCompanyChange}
                        onCompanyNameChange={handleCompanyNameChange}
                        onUnmount={handleReset}
                      />
                    ),
                    icon: showMaHolderWarning ? errorIcon : undefined,
                    'data-testid': 'ma-holder-tab',
                  },
                  {
                    label: ProductGroupTabs.ProductType,
                    content: <ProductTypeTab />,
                    icon: showProductTypeWarning ? errorIcon : undefined,
                    'data-testid': 'product-type-tab',
                  },
                ]}
              />
            </FormProvider>
          </Card>
          <Card>
            <ContentWrapper>
              <ContentHeaderWrapper>
                <ContentHeaderSummary>
                  <BadgeElementWrapper>
                    <Subtitle>Products</Subtitle>
                    <IconWithTooltip tooltipId="emc" iconSrc="/icons/info_circle.svg" tooltipText={productsTooltipText} />
                    <Badge value={submission?.products.length} data-testid="products-counter" />
                  </BadgeElementWrapper>
                  <Description>Products can only have one SmPC or PIL associated to them at a time.</Description>
                </ContentHeaderSummary>
                <Button
                  onClick={handleProductPopupOpenClick}
                  text="Add Product"
                  type={ButtonTypes.PRIMARY_MEDIUM_BORDER}
                  icon="/icons/plus-sign.svg"
                  width={150}
                />
              </ContentHeaderWrapper>
              <TableWrapper data-testid="products-table">
                {!submission?.products.length || submission?.products.length === 0 ? (
                  <NoContentPanel
                    title="No products found"
                    helpMessage="How do I add products?"
                    helpLink={process.env.REACT_APP_HELP_PRODUCT_URL}
                  />
                ) : (
                  <DatapharmTable documents={submission?.products ?? []} columns={columns} data-testid="products-table" />
                )}
              </TableWrapper>
              {isProductModalOpen && (
                <AddProductPopup
                  isOpen={isProductModalOpen}
                  substances={substances ?? []}
                  productGroup={{
                    id: submission?.productGroupId || null,
                    name: submission?.productGroup.productGroupName || '',
                    productType: submission?.productGroup.productType || '',
                  }}
                  isAddingProduct={isCreatingProduct}
                  handleCreate={handleProductCreate}
                  handleClose={handleProductModalClose}
                />
              )}
              <DuplicateProductError
                isOpen={!!duplicateProduct}
                productName={duplicateProduct ?? ''}
                onClose={handleDuplicateErrorClose}
                onProductView={handleProductView}
              />
              <RemoveModal
                isOpen={isProductRemoveModalOpen}
                onCancelClick={handleProductRemoveCancel}
                onConfirmClick={handleProductRemoveConfirm}
                type={'product'}
                title={removeProductDetails.title}
              />
            </ContentWrapper>
          </Card>
        </ProductGroupWrap>
        <SubmissionFlowFooter
          onContinue={isContentTabSelected ? validateSubmissionData : handleSaveChanges}
          onCancel={handleCancel}
          continueText={isContentTabSelected ? 'Continue' : 'Save Changes'}
        />
      </LayoutWrapper>
      <NotifyModal
        isOpen={isValidateOpen}
        title="Please add the following missing information"
        onClose={handleCancelValidationModal}
        onConfirm={handleHelpClick}
        confirmText="Help"
        width="50rem"
      >
        <ValidateSubmissionErrors
          onCancel={handleCancelValidationModal}
          validationErrors={validationErrors!}
          onProductGroupErrorClick={handleProductGroupErrorClick}
          onProductErrorClick={handleProductErrorClick}
          onContentErrorClick={handleContentErrorClick}
          onProductAssociationErrorClick={handleProductAssociationErrorClick}
          onContentAssociationErrorClick={handleContentAssociationErrorClick}
        />
      </NotifyModal>
      {isNextStepsOpen && (
        <NextStepsModal
          isDontShowAgainChecked={isDontShowAgainChecked}
          onClose={handleNextStepsClose}
          onDontShowAgainCheck={handleDontShowAgainCheck}
        >
          {showSmpcPilOption && (
            <Item
              title="Add a new SmPC"
              linkText="How to add an SmPC or PIL?"
              onClick={handleSmpcGetStarted}
              helpLink={process.env.REACT_APP_HELP_CONTENT_URL}
              additionalInfo="Use this option if you are uploading a new document or replacing a combined SmPC with an Individual SmPC"
            />
          )}
          {showSmpcPilOption && (
            <Item
              title="Add a new PIL"
              linkText="How to add an SmPC or PIL?"
              onClick={handlePilGetStarted}
              helpLink={process.env.REACT_APP_HELP_CONTENT_URL}
              additionalInfo="Use this option if you are uploading a new document or replacing a combined PIL with an Individual PIL"
            />
          )}
          {showSmpcPilOption && (
            <Item
              title="Update an existing SmPC/PIL"
              linkText="How to add an SmPC or PIL?"
              onClick={handleNextStepsClose}
              helpLink={process.env.REACT_APP_HELP_CONTENT_URL}
              additionalInfo="Use this option if you are uploading an amendment to an existing document"
            />
          )}
          {showProductOption && (
            <Item
              title="Add a Product"
              linkText="How to add or update a Product?"
              helpLink={process.env.REACT_APP_HELP_PRODUCT_URL}
              onClick={handleProductGetStarted}
              additionalInfo="Use this option if you are adding a completely new product"
            />
          )}
          {showProductOption && (
            <Item
              title="Update a Product"
              linkText="How to add or update a Product?"
              helpLink={process.env.REACT_APP_HELP_PRODUCT_URL}
              onClick={handleNextStepsClose}
              additionalInfo="'Use this option if you are making an amendment to an existing product"
            />
          )}
        </NextStepsModal>
      )}
      {productIdToDiscontinue && (
        <DiscontinueProductModal
          isLoading={isDiscontinuingProduct}
          onCancel={handleDiscontinueProductCancel}
          onConfirm={handleDiscontinueProductConfirm}
        />
      )}
      {productIdToRetire && (
        <RetireProductModal
          isLoading={isRetiringProduct}
          onCancel={handleRetireProductCancel}
          onConfirm={handleRetireProductConfirm}
          productName={productToRetireName}
        />
      )}
      {productIdToReinstate && (
        <ReinstatementProductModal
          onCancel={handleReinstateProductCancel}
          onConfirm={handleReinstateProductConfirm}
          productName={productToReinstateName}
        />
      )}
      {isAddDocumentModalOpen && (
        <FormProvider {...fileUploadFormMethods}>
          <AddDocumentModal
            type={contentType}
            onFileSubmit={handleFileSubmit}
            onUploadClick={handleUploadClick}
            onCancelClick={handleDocumentAddCancel}
            uploadErrors={uploadErrors}
            productsMatchingDocument={productsMatchingDocument}
            isOpen={isAddDocumentModalOpen}
            isUploading={isFileLoading}
            uploadProgress={documentUploadProgress}
            onRemove={handleFileRemove}
            secondaryDocumentProps={secondaryDocument}
          />
          {isChangingDocumentAssociation && <PleaseWaitOverlay />}
        </FormProvider>
      )}
      {isRequestEpilModalOpen && (
        <FormProvider {...requestEpilModalMethods}>
          <RequestEpilModal
            isUploading={isRequestEpilModalLoading}
            uploadProgress={requestEpilModalUploadProgress}
            onFileSubmit={handleRequestEpilModalFileSubmit}
            onUploadClick={handleRequestEpilModalUploadClick}
            onCancelClick={handleRequestEpilModalCancel}
            onRemove={handleRequestEpilModalRemove}
            isOpen={isRequestEpilModalOpen}
          />
        </FormProvider>
      )}
      <ModalWrapper isOpen={modalState.isOpen}>
        <RetireModal
          onConfirmClick={handleRetire}
          submission={submission}
          onCancelClick={closeModal}
          modalState={modalState}
          contentType={contentType}
        />
      </ModalWrapper>
      <RemoveModal
        isOpen={isDocumentRemoveModalOpen}
        onCancelClick={handleDocumentRemoveCancel}
        onConfirmClick={handleDocumentRemoveConfirm}
        type={'document'}
        title={removeDocumentDetails.title}
      />
      <NotifyModal title={errorTitle} isOpen={fileErrors.length > 0} width="32rem">
        <FileError onClose={handleClose} errors={fileErrors} type={contentType} />
      </NotifyModal>

      <NotifyModal title={requestEpilErrorTitle} isOpen={requestEpilModalFileErrors.length > 0} width="32rem">
        <FileError onClose={handleRequestEpilErrorsModalClose} errors={requestEpilModalFileErrors} />
      </NotifyModal>

      <NotifyModal title={'Missing Products'} onClose={handleClose} isOpen={showMissingProducts} width="32rem">
        <p>At least one product must be specified</p>
      </NotifyModal>
      <CancelCreateSubmissionModal isOpen={isCancelModalOpen} onClose={closeCancelModal} onConfirm={handleCancelModalConfirm} />
    </>
  );
};

export default SubmissionEditableProductGroup;
