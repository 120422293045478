import React from 'react';
import { useFormContext } from 'react-hook-form';
import { DocumentType, ICheckboxEntry } from '@common/types';
import { Button, ButtonTypes, ModalWithFooterWrapper } from '@common/components';
import * as Styled from '@common/features/document/styles';
import FileUpload from '@components/FileUpload/FileUpload';
import { UNITS_DIVIDER } from '@common/constants';
import { Title } from '@common/styles';
import { AddModalOpenModes, FormFields } from '@common/features/document/types';

export interface IReplaceAlternativeTextFileModal {
  type: DocumentType;
  isOpen: boolean;
  modalVariant: AddModalOpenModes;
  isUploading: boolean;
  uploadProgress: number;
  productsMatchingDocument?: ICheckboxEntry[];
  onFileSubmit: (files: FileList | null) => void;
  onUploadClick: () => void;
  onCancelClick: () => void;
  onRemove: () => void;
}

const ReplaceAlternativeTextFileModal = ({
  type,
  isOpen,
  modalVariant,
  uploadProgress,
  isUploading,
  onFileSubmit,
  onCancelClick,
  onUploadClick,
  onRemove,
}: IReplaceAlternativeTextFileModal) => {
  const { watch } = useFormContext();

  const uploadedFile = watch(FormFields.file);

  const fileSize = Math.round((uploadedFile?.size ?? 0) / UNITS_DIVIDER);

  if (!type) {
    return null;
  }

  const modalContent = {
    title: `Do any of the images, graphs or tables in this PIL need alternative text?`,
    fileUploadTitle: 'Upload your document',
    description: (
      <Styled.Description>
        <Styled.DescriptionText>
          If the images, graphs or tables give essential information that is not covered in the text of the PIL, please provide
          alternative text that can be displayed instead.
          <p>&nbsp;</p>
        </Styled.DescriptionText>
      </Styled.Description>
    ),
    fileSubText: '.DOCX 3MB max',
  };

  const modalTitleTestId = `${modalVariant === 'Add' ? 'add' : 'update'}-${type}-document-modal-title`;

  const handleFileUpload = () => onUploadClick();

  const renderFooter = () => {
    return (
      <Styled.FooterWrapper>
        <Styled.Bottom>
          <Styled.Right>
            <Styled.CancelButtonWrap>
              <Button
                onClick={() => {
                  onCancelClick();
                }}
                text="Cancel"
                type={ButtonTypes.TEXT}
              />
            </Styled.CancelButtonWrap>
            <Button disabled={isUploading} onClick={handleFileUpload} width={163} text={'Upload'} type={ButtonTypes.PRIMARY} />
          </Styled.Right>
        </Styled.Bottom>
      </Styled.FooterWrapper>
    );
  };
  return (
    <ModalWithFooterWrapper isOpen={isOpen} footer={renderFooter()}>
      <Styled.Wrap>
        <Styled.Top>
          <Title data-testid={modalTitleTestId}>{modalContent.title}</Title>
          <> {modalContent.description && modalContent.description}</>
          <FileUpload
            title={modalContent.fileUploadTitle}
            onFileSubmit={onFileSubmit}
            loadingPresents={uploadProgress}
            isLoading={isUploading}
            fileName={uploadedFile?.name}
            fileSize={fileSize + 'KB'}
            fileInputSubText={modalContent.fileSubText}
            onRemove={onRemove}
          />
        </Styled.Top>
      </Styled.Wrap>
    </ModalWithFooterWrapper>
  );
};

export default ReplaceAlternativeTextFileModal;
