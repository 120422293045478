import React from 'react';
import { FooterWrap, FooterLink, Left, Right, FooterImage } from './styles';

interface IFooter {
  hideLinks?: boolean;
};

const Footer = ({ hideLinks = false }: IFooter) => {
  return (
    <FooterWrap>
      <Left>
        {!hideLinks && [
          { display: 'Content Policy', url: '/documents/contentPolicy.pdf' },
          { display: 'About', url: process.env.REACT_APP_ABOUT_URL },
          { display: 'Legal', url: process.env.REACT_APP_LEGAL_URL },
          { display: 'Help', url: process.env.REACT_APP_HELP_URL },
          { display: 'Contact', url: process.env.REACT_APP_CONTACT_URL },
        ].map((item, index) => (
          <FooterLink key={index} href={item.url} target="_blank">
            {item.display}
          </FooterLink>
        ))}
      </Left>
      <Right>
        Delivered to you by
        <FooterLink href={process.env.REACT_APP_DATAPHARM_URL} target="_blank">
          <FooterImage src="/dataPharmLogo.png" />
        </FooterLink>
      </Right>
    </FooterWrap>
  );
};

export default Footer;
