import { IDocumentAttributes } from '../../helpers';
import { DocumentDetailsReadonlySections } from '../DocumentContentDetails/types';
import { ReadonlyWeblink } from './ReadonlyWeblink';
import { Weblink } from './Weblink';
import * as Styled from './styles';
import { FieldErrors } from 'react-hook-form';

interface IProps {
  documentAttributes: IDocumentAttributes;
  customerUrl: string | undefined;
  url: string | null;
  datapharmLinkFieldName: string | null;
  customerLinkFieldName: string;
  documentReadonlySections?: DocumentDetailsReadonlySections;
  onClearInput: (inputName: 'customer' | 'datapharm') => void;
  errors?: FieldErrors<any>;
}

export const ContentDetailsWeblink = ({
  documentAttributes,
  customerUrl,
  url,
  datapharmLinkFieldName,
  customerLinkFieldName,
  documentReadonlySections,
  onClearInput,
  errors,
}: IProps) => {
  if (documentAttributes.allowAdminWeblinkUpload && datapharmLinkFieldName) {
    return (
      <Styled.WeblinksWrap>
        {customerUrl && <ReadonlyWeblink url={customerUrl!} title="Customer Link" />}
        {documentReadonlySections?.adminWeblink ? (
          url && <ReadonlyWeblink url={url!} title="Datapharm Link" />
        ) : (
          <Weblink
            fieldName={datapharmLinkFieldName}
            onClearInput={() => onClearInput('datapharm')}
            label="Datapharm Link"
            errors={errors}
            data-testid="datapharm-weblink"
          />
        )}
      </Styled.WeblinksWrap>
    );
  }

  if (documentAttributes.showRmmWebLink && datapharmLinkFieldName)
    return (
      <Styled.WeblinksWrap>
        <Weblink
          fieldName={datapharmLinkFieldName}
          onClearInput={() => onClearInput('datapharm')}
          errors={errors}
          data-testid="datapharm-weblink"
        />
      </Styled.WeblinksWrap>
    );

  if (documentAttributes.showAudioVideoWebLink)
    return (
      <Styled.WeblinksWrap>
        <Weblink
          fieldName={customerLinkFieldName}
          onClearInput={() => onClearInput('customer')}
          errors={errors}
          data-testid="customer-weblink"
        />
      </Styled.WeblinksWrap>
    );

  return null;
};
