import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { ProductDetailsFormErrors } from '../types';
import { IProductForm, ProductFormPaths } from '@common/features/product/components/ProductContent/hooks/useProductForm';
import { IAmpId } from '@common/features/product/types';

export const useAmpIdSelection = () => {
  const {
    getValues,
    setValue,
    clearErrors,
    setError,
    watch,
    trigger,
    formState: { errors },
  } = useFormContext<IProductForm>();

  const ampIdToAdd = watch(ProductFormPaths.ampIdInput);
  const selectedAmpIds = watch(ProductFormPaths.ampIds) || [];


  const noAmpId = watch(ProductFormPaths.noAmpIds);

  useEffect(() => {
    trigger(ProductFormPaths.ampIds);
  }, [noAmpId, trigger]);

  const addAmpId = (ampId: IAmpId) => {
    const ampIds = getValues(ProductFormPaths.ampIds) ?? [];
    setValue(ProductFormPaths.ampIds, [...ampIds, ampId]);
  };

  
  const removeAmpId = (index: number) => {
    const ampIds = getValues(ProductFormPaths.ampIds) ?? [];
    setValue(
      ProductFormPaths.ampIds,
      ampIds.filter((_, i) => i !== index),
      { shouldValidate: true },
    );
  };

  const clearAmpIdError = () => clearErrors(ProductFormPaths.ampIds);
  const clearAmpIdInputError = () => clearErrors(ProductFormPaths.ampIdInput);

  const handleAmpIdAdd = () => {
    const idTooShort = ampIdToAdd.length < 13;
    const idTooLong = ampIdToAdd.length > 18;
    const idIsNegative = Number(ampIdToAdd) < 0;

    if (idTooShort) {
      setError(ProductFormPaths.ampIdInput, {
        type: 'numberTooShort',
        message: ProductDetailsFormErrors.ampNumberTooShort,
      });
    }

    if (idTooLong) {
      setError(ProductFormPaths.ampIdInput, {
        type: 'numberTooLong',
        message: ProductDetailsFormErrors.ampNumberTooLong,
      });
    }

    if (idIsNegative) {
      setError(ProductFormPaths.ampIdInput, {
        type: 'numberIsNegative',
        message: ProductDetailsFormErrors.ampNumberIsNegative,
      });
    }

    if (ampIdToAdd !== '' && !idTooShort && !idTooLong && !idIsNegative) {
      addAmpId({ id: ampIdToAdd });

      setValue(ProductFormPaths.ampIdInput, '');
      clearErrors([ProductFormPaths.ampIds]);
    }
  };
  const handleAmpIdRemove = (index: number) => {
    clearAmpIdError();
    removeAmpId(index);
  };

  const handleNoAmpIdChange = () => {
    const isChecked = getValues(ProductFormPaths.noAmpIds);
    if (!isChecked) {
      setValue(ProductFormPaths.ampIdInput, '');
    }
    clearAmpIdInputError();
    setValue(ProductFormPaths.noAmpIds, !isChecked);
  };

  return {
    ampIdToAdd,
    selectedAmpIds,
    errors,
    noAmpId,
    handleAmpIdAdd,
    handleAmpIdRemove,
    handleNoAmpIdChange,
  };
};
