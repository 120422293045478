import styled from 'styled-components';

export const StyledLink = styled.span<{ link?: boolean }>`
  color: ${({ theme }) => theme.elements.linkBlue};
  text-decoration: underline;
  cursor: pointer;
`;

export const StyledSpan = styled.span<{ link?: boolean }>`
  color: ${({ theme }) => theme.elements.linkBlue};
`;

export const StyledInput = styled.input.attrs({ type: 'file' })``;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FileNameWrapper = styled.div`
  display: flex;
  align-items: center;

  svg {
    cursor: pointer;
  }
`;
