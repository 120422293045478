import React from "react";
import { MaintenanceText, MaintenanceTextHeader, MaintenanceTextRegular, MaintenanceTop, MaintenanceWrap } from "./styles";
import Footer from "../Footer/Footer";

interface IMaintenance {
  maintenanceMessage: string;
};

export const MaintenanceInfo = ({ maintenanceMessage }: IMaintenance) => {
  return (
    <MaintenanceWrap>
      <MaintenanceTop>
        <MaintenanceText>
          <MaintenanceTextHeader>Down for Maintenance</MaintenanceTextHeader><br />
          <MaintenanceTextRegular>The emc publishing platform is temporarily unavailable due to planned maintenance</MaintenanceTextRegular><br />
          <MaintenanceTextRegular>{maintenanceMessage}</MaintenanceTextRegular>
        </MaintenanceText>
      </MaintenanceTop>
      <Footer hideLinks={true} />
    </MaintenanceWrap>
  );
};
