import useWaitingMutation from '@common/hooks/useWaitingMutation';
import { useAppSelector } from '@common/hooks/redux';
import { selectToken } from '@common/features/user/selectors';
import { Id } from '@common/types';
import { replaceTaskContent } from '@common/services/submissionService';
import { usePleaseWaitOverlay } from '@components/PleaseWaitOverlay/usePleaseWaitOverlay';
import { DocumentType } from '@common/types';
import { getDocumentAttributes } from '@common/features/document/helpers';
import { enhanceWithHttps } from '@common/helpers';

interface IPostReplacementDocumentDetails {
  title: string;
  includeSecondaryDocument: boolean;
}

export const useReplaceDocument = (
  isAdmin: boolean,
  taskId: Id,
  contentType: DocumentType,
  replacementFileName: string,
  replacementOriginalFileName: string,
  replacementWeblink: string,
  replacementHtmlContent: string,
  includeSecondaryDocument? : boolean,
  replacementOriginalSecondaryFileName? : string,
  replacementSecondaryFileName? : string,
) => {
  const token = useAppSelector(selectToken);
  const { handleShowOverlay, handleHideOverlay } = usePleaseWaitOverlay();

  const documentAttributes = getDocumentAttributes(contentType, isAdmin);

  const { mutate, data, isLoading } = useWaitingMutation(
    ({ title }: IPostReplacementDocumentDetails) => {
      const payload = {
        replacementFileName,
        replacementOriginalFileName,
        replacementHtmlContent,
        title,
        includeSecondaryDocument,
        replacementOriginalSecondaryFileName,
        replacementSecondaryFileName,
        ...(documentAttributes.shouldHandleCustomerLink
          ? { replacementCustomerLink: enhanceWithHttps(replacementWeblink) }
          : { replacementWeblink: enhanceWithHttps(replacementWeblink) }),
      };

      return replaceTaskContent(token, taskId, payload);
    },
    { onMutate: handleShowOverlay, onSuccess: handleHideOverlay, onError: handleHideOverlay },
  );

  return { replaceDocument: mutate, data, isLoading };
};
