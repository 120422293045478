import React, { useState } from 'react';
import Tabs from '@components/Tabs/Tabs';
import { ProductsTab } from './Tabs/ProductsTab';
import { NotesTab } from './Tabs/NotesTab';
import {
  IAssignmentChangesDto,
  IDocumentDto,
  INotesQueryDependencies,
  IProductChangesDto,
  IProductDto,
} from '@common/features/submission/types';
import * as Styled from '../../styles';
import { Id, SubmissionTaskType } from '@common/types';

interface Props {
  isLoading: boolean;
  products: IProductDto[];
  documents: IDocumentDto[];
  submissionTaskId: string;
  submissionTaskType: SubmissionTaskType;
  productsChanges?: IProductChangesDto[];
  removeButtonText?: string;
  notesQueryDependencies?: INotesQueryDependencies;
  isReadonly?: boolean;
  showDocumentCountChanges: boolean;
  assignments: IAssignmentChangesDto[];
  showAttachmentsColumn: boolean;
  onEditXml?: () => void;
  onUpdateClick?: (productId: Id) => void;
  onRemoveClick?: (productId: Id) => void;
  onDetailsClick?: (productId: Id) => void;
  onFileDownload?: (noteId: number) => void;
}

export const DetailsTabs = ({
  isLoading,
  removeButtonText,
  products,
  documents,
  submissionTaskId,
  submissionTaskType,
  productsChanges,
  notesQueryDependencies,
  isReadonly,
  showDocumentCountChanges,
  assignments,
  showAttachmentsColumn,
  onEditXml,
  onUpdateClick,
  onRemoveClick,
  onDetailsClick,
  onFileDownload,
}: Props) => {
  const [selectedTab, setSelectedTab] = useState<string>('Products');

  const handleTabSelected = (label: string) => {
    setSelectedTab(label);
  };

  const items = [
    {
      label: 'Products',
      content: (
        <ProductsTab
          isLoading={isLoading}
          removeButtonText={removeButtonText}
          products={products}
          documents={documents}
          isReadonly={isReadonly}
          onEditXml={onEditXml}
          onUpdateClick={onUpdateClick}
          onRemoveClick={onRemoveClick}
          onDetailsClick={onDetailsClick}
          assignments={assignments}
          showDocumentCountChanges={showDocumentCountChanges}
          productsChanges={productsChanges}
          submissionTaskType={submissionTaskType}
        />
      ),
    },
    {
      label: 'Notes',
      content: (
        <NotesTab
          submissionTaskId={submissionTaskId}
          notesQueryDependencies={notesQueryDependencies}
          onFileDownload={onFileDownload}
          showAttachmentsColumn={showAttachmentsColumn}
        />
      ),
    },
  ];

  return (
    <Styled.SubmissionTaskDetailsUnitWrapper style={{ padding: '15px 0px 5px 0px' }}>
      <Tabs items={items} selectedTab={selectedTab} onTabSelected={handleTabSelected} />
    </Styled.SubmissionTaskDetailsUnitWrapper>
  );
};
