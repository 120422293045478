import { createSelector } from '@reduxjs/toolkit';
import { IUserState } from './types';
import { RootState } from '@common/store';

const selectUserState = (state: RootState) => state.user;

export const selectToken = createSelector(selectUserState, (state: IUserState) => state.token);

export const selectUsername = createSelector(selectUserState, (state: IUserState) =>
  state.userData && state.userData.user ? state.userData.user.name : '',
);

export const selectUserCompanies = createSelector(selectUserState, (state: IUserState) => state.companyPermissions || []);

export const selectActiveCompany = createSelector(selectUserState, (state: IUserState) => state.activeCompany);

export const selectUserIsAdmin = createSelector(selectUserState, (state: IUserState) => state.userData.isAdmin);

export const selectUserEmail = createSelector(
  selectUserState,
  (state: IUserState) => state.userData && state.userData.user.email,
);

export const selectUserId = createSelector(selectUserState, (state: IUserState) => state.userData.user.userId);

