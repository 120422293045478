import { useForm } from 'react-hook-form';
import { DocumentType, IDocumentDto } from '@common/features/submission/types';
import { validateDocumentTitleUniqueness } from '@common/features/document/helpers';
import { alreadyExistingDocumentTitleMessage } from '@common/components/DocumentTitle/constants';
import { AddModalOpenModes, FormFields, IAddDocumentForm } from '@common/features/document/types';

interface IUsePrepareFileUploadFormProps {
  documents?: IDocumentDto[];
  contentType: DocumentType;
  isAddDocumentModalOpen: AddModalOpenModes;
}

export const usePrepareFileUploadForm = ({ documents, contentType, isAddDocumentModalOpen }: IUsePrepareFileUploadFormProps) => {
  const methods = useForm<IAddDocumentForm>({
    mode: 'onChange',
    defaultValues: {
      [FormFields.documentTitle]: '',
      [FormFields.file]: undefined,
      [FormFields.productIds]: [],
      [FormFields.hasDigitalVersion]: undefined,
      [FormFields.isCustomerApprovalRequired]: null,
      [FormFields.includeSecondaryDocument]: undefined,
      [FormFields.secondaryfile]: undefined,
      [FormFields.noAudioVideoUrl]: false,
    },
  });

  const { register, getValues } = methods;

  register(FormFields.documentTitle, {
    validate: () => {
      const documentTitle = getValues(FormFields.documentTitle);
      const isDocumentTitleUnique = validateDocumentTitleUniqueness(documents!, documentTitle!, contentType!, null);

      return isDocumentTitleUnique && isAddDocumentModalOpen === 'Add'
        ? alreadyExistingDocumentTitleMessage(contentType!)
        : undefined;
    },
  });

  return { methods, ...methods };
};
