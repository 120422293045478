import React, { forwardRef } from 'react';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';
import './styles.css';

import * as Styled from './styles';
import { Icons } from '@common/icons';

interface IDatePickerWrapper {
  onChange: (date: Date | null) => void;
  placeholder?: string;
  label?: string;
  name?: string;
  value?: Date | null;
  maxDate?: Date | null;
  disabled?: boolean;
}

const DateInput = forwardRef(({ value, onClick, onChange, onFocus, placeholder, id }: any, ref: any) => (
  <Styled.DateInputWrapper className="react-datepicker-ignore-onclickoutside" ref={ref} onClick={onClick}>
    <Styled.DateInput onChange={onChange} onFocus={onFocus} placeholder={placeholder} value={value} id={id} />
    <Icons.Calendar />
  </Styled.DateInputWrapper>
));

DateInput.displayName = 'Date Input';

export const DatePickerWrapper = ({
  onChange,
  label,
  name,
  value,
  maxDate,
  placeholder = 'Please pick a date',
  disabled = false,
}: IDatePickerWrapper) => {
  const thisYear = ((maxDate as Date) ?? new Date()).getFullYear();

  const years = [...Array(25).keys()].map((i) => thisYear - i);

  const months = [...Array(12).keys()].map((key) => new Date(0, key).toLocaleString('default', { month: 'long' }));

  return (
    <Styled.DatePickerWrapper>
      {label && <Styled.DatePickerLabel htmlFor={name}>{label}</Styled.DatePickerLabel>}
      <DatePicker
        placeholderText={placeholder}
        name={name}
        id={name}
        customInput={<DateInput />}
        selected={value ? new Date(value) : null}
        onChange={(date) => onChange(date)}
        maxDate={maxDate}
        dateFormat="dd/MM/yyyy"
        disabled={disabled}
        renderCustomHeader={({
          date,
          changeYear,
          changeMonth,
          increaseMonth,
          decreaseMonth,
          nextMonthButtonDisabled,
          prevMonthButtonDisabled,
        }) => (
          <Styled.DateInputHeader>
            <Styled.DateAndButtonsWrapper>
              <button
                disabled={prevMonthButtonDisabled}
                onClick={decreaseMonth}
                type="button"
                className="react-datepicker__navigation react-datepicker__navigation--previous"
                aria-label="Previous Month"
              >
                <span className="react-datepicker__navigation-icon react-datepicker__navigation-icon--previous">
                  Previous Month
                </span>
              </button>
              <Styled.MonthYearTitle className="react-datepicker__current-month">
                {date.toLocaleString('default', { month: 'long', year: 'numeric' })}
              </Styled.MonthYearTitle>
              <button
                disabled={nextMonthButtonDisabled}
                onClick={increaseMonth}
                type="button"
                className="react-datepicker__navigation react-datepicker__navigation--next"
                aria-label="Next Month"
              >
                <span className="react-datepicker__navigation-icon react-datepicker__navigation-icon--next">Next Month</span>
              </button>
            </Styled.DateAndButtonsWrapper>
            <Styled.DateInputMonthYear>
              <Styled.DropDown
                value={months[date.getMonth()]}
                onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}
              >
                {months.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </Styled.DropDown>
              <Styled.DropDown
                value={date.getFullYear().toString()}
                onChange={({ target: { value } }) => changeYear(parseInt(value))}
              >
                {years.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </Styled.DropDown>
            </Styled.DateInputMonthYear>
          </Styled.DateInputHeader>
        )}
      />
    </Styled.DatePickerWrapper>
  );
};
