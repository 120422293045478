import styled from "styled-components";

export const MaintenanceWrap = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

export const MaintenanceTop = styled.div`
  height: 100%;
  width: 100%;
  background-image: url(publisherLogo.png), url(/icons/logo-background.svg);
  background-position: 70px 50px, left top;
  background-repeat: no-repeat;
  background-size: auto, auto;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const MaintenanceText = styled.div`
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  border: 1px solid var(--unnamed-color-b7b5b6);
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #B7B5B6;
  border-radius: 10px;
  opacity: 1;
  width: 555px;
  height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const MaintenanceTextHeader = styled.div`
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-600) 20px/27px var(--unnamed-font-family-open-sans);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-231f20);
  font: normal normal 600 20px/27px Open Sans;
  letter-spacing: 0px;
  color: #231F20;
  opacity: 1;
`;

export const MaintenanceTextRegular = styled.div`
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-12)/var(--unnamed-line-spacing-17) var(--unnamed-font-family-open-sans);
  letter-spacing: var(--unnamed-character-spacing--0-24);
  color: var(--unnamed-color-5d5b5c);
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: -0.24px;
  color: #5D5B5C;
  opacity: 1;
`;
