import styled from 'styled-components';

export const NoContentPanelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 234px;
  height: 134px;
  border: 1px solid ${({ theme }) => theme.elements.borderGrey};
  border-radius: 10px;
  padding: 25px 0;
  justify-content: center;
  align-items: center;
  margin: auto;
`;

export const NoContentTitle = styled.span`
  color: ${({ theme }) => theme.elements.textGrey};
  font-weight: 600;
  font-size: ${({ theme }) => theme.elementFontSizes.default};
  margin-top: 12px;
`;
