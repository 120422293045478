import { useEffect, useState } from 'react';
import { Id } from '@common/types';
import { useDownloadDocumentFile, useDownloadSecondaryFile } from '@common/hooks/useDownloadDocumentFile';
import { useDownloadTaskFile } from '@common/hooks/useDownloadTaskFile';
import { useDownloadTaskFileByRole } from './useDownloadTaskFileByRole';
import { useDownloadNoteFile } from './useDownloadNoteFile';
import { DocumentType } from '@common/types';

interface IDownloadFileProps {
  taskId?: Id;
  submissionId?: Id;
  documentId?: Id;
  fileRole?: string;
  noteId?: number;
  contentType?: DocumentType | undefined;
  lastUpdated?: string;
}

interface IAnchorParams {
  url: string;
  cleanFileName: string;
  contentType: string;
}

export enum ResponseContentType {
  docx = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  pdf = 'application/pdf',
  csv = 'text/csv',
}

const getFileName = (contentDisposition?: string) => contentDisposition?.split('filename=')[1].split(';')[0] as string;

export const useDownloadAndOpenFile = () => {
  const [downloadFileParams, setDownloadFileParams] = useState<IDownloadFileProps>();

  const { documentFileResponse, refetchDownloadDocumentFile } = useDownloadDocumentFile(
    downloadFileParams?.submissionId ?? '',
    downloadFileParams?.documentId ?? '',
  );

  const { secondaryDocumentFileResponse, secondaryRefetchDownloadDocumentFile } = useDownloadSecondaryFile(
    downloadFileParams?.submissionId ?? '',
    downloadFileParams?.documentId ?? '',
    downloadFileParams?.contentType ?? DocumentType.Pil,
    downloadFileParams?.lastUpdated ?? '',
  );

  const { taskFileResponse, refetchDownloadTaskFile } = useDownloadTaskFile(downloadFileParams?.taskId ?? '');

  const { taskFileByRoleResponse, refetchDownloadTaskFileByRole } = useDownloadTaskFileByRole(
    downloadFileParams?.taskId ?? '',
    downloadFileParams?.fileRole ?? '',
  );

  const { noteFile, refetchDownloadNoteFile } = useDownloadNoteFile(
    downloadFileParams?.taskId ?? '',
    downloadFileParams?.noteId!,
  );

  const response =
    taskFileResponse ?? documentFileResponse ?? taskFileByRoleResponse ?? noteFile ?? secondaryDocumentFileResponse;

  const handleFileResponse = (response: any) => {
    const { url, cleanFileName, contentType } = getAnchorParams(response);

    const a = document.createElement('a');
    a.href = url;
    if (contentType === ResponseContentType.docx || contentType === ResponseContentType.csv) {
      a.download = cleanFileName;
    }
    a.target = '_blank';
    a.click();

    setTimeout(function () {
      a.remove();
      URL.revokeObjectURL(url);
    }, 100);
    setDownloadFileParams(undefined);
  };

  const getAnchorParams = (response: any): IAnchorParams => {
    const fileName = getFileName(response?.headers['content-disposition'] as string) || 'Document file';
    const cleanFileName = fileName.replace(/"/g, '');
    const contentType = response?.headers['content-type'] as string;
    const file = new File([response.data], cleanFileName, { type: contentType });

    const url = window.URL.createObjectURL(file);

    return {
      url,
      cleanFileName,
      contentType,
    };
  };

  useEffect(() => {    
    if (downloadFileParams?.taskId && downloadFileParams?.noteId) refetchDownloadNoteFile();
    else if (
      downloadFileParams?.submissionId &&
      downloadFileParams?.documentId &&
      downloadFileParams?.contentType &&
      downloadFileParams.lastUpdated
    ){
      
      secondaryRefetchDownloadDocumentFile();
    }
    else if (downloadFileParams?.submissionId && downloadFileParams?.documentId) refetchDownloadDocumentFile();
    else if (downloadFileParams?.taskId && !downloadFileParams.fileRole) refetchDownloadTaskFile();
    else if (downloadFileParams?.taskId && downloadFileParams?.fileRole) refetchDownloadTaskFileByRole();
  }, [
    downloadFileParams?.taskId,
    downloadFileParams?.submissionId,
    downloadFileParams?.fileRole,
    downloadFileParams?.documentId,
    downloadFileParams?.noteId,
    downloadFileParams?.lastUpdated,
    downloadFileParams?.contentType,
    refetchDownloadDocumentFile,
    refetchDownloadTaskFile,
    refetchDownloadTaskFileByRole,
    refetchDownloadNoteFile,
    secondaryRefetchDownloadDocumentFile,
  ]);

  const downloadAndOpen = (downloadFileParams: IDownloadFileProps) => {        
    setDownloadFileParams(downloadFileParams);
  };

  if (response) {
    handleFileResponse(response);
  }

  return { downloadAndOpen, handleFileResponse, getAnchorParams };
};
