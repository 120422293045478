import styled from 'styled-components';
import { ReactSVG } from 'react-svg';

export const TitleIcon = styled(ReactSVG)`
  svg {
    width: 70px;
    height: auto;
  }

  margin-bottom: 10px;
`;

export const Title = styled.div.attrs((props: { isSubtextPresent: boolean }) => props)`
  font-size: 22px;
  color: ${({ theme }) => theme.elements.textGrey};
  font-weight: 600;
  text-align: ${(props) => props.isSubtextPresent ? 'left' : 'center'};
  white-space: pre;
  margin-bottom: ${(props) => props.isSubtextPresent ? '1rem' : '3rem'};
`;

export const Subtext = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.small};
  color: ${({ theme }) => theme.elements.textLightGrey};
  text-align: left;
  white-space: pre;
  margin-bottom: 1rem;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: right;
  align-items: center;
`;
