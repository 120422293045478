import { RegisterOptions, useFormContext } from 'react-hook-form';
import { ISelectOptions } from './Select';
import { Select } from './Select';
import { ErrorMessage } from '../ErrorMessage';
import { Wrapper } from './styles';
import { useState } from 'react';
import { ISelectOption } from '@common/types';

interface IHookFormSelect {
  name: string;
  label?: string;
  options: ISelectOptions | undefined;
  rules?: RegisterOptions<any, string>;
  error?: string;
  disabled?: boolean;
  readonly?: boolean;
  isClearable?: boolean;
  placeholder?: string;
  isLoading?: boolean;
  allowCustomOption?: boolean;
  onKeyDown?: (e: string) => void;
  onChangeValue?: (value: ISelectOption) => void;
  noOptionsMessage?: string;
}

export const HookFormSelect = ({
  name,
  label,
  options,
  rules,
  error,
  disabled,
  readonly,
  placeholder,
  isClearable,
  isLoading,
  allowCustomOption,
  onKeyDown,
  onChangeValue,
  noOptionsMessage,
}: IHookFormSelect) => {
  const { setValue, watch, register } = useFormContext();

  const [customOption, setCustomOption] = useState<ISelectOption>({ label: '', value: '' });

  if (rules) {
    register(name, {
      ...rules,
    });
  }

  const handleChange = (e: any) => {
    setValue(name, e, { shouldValidate: true, shouldDirty: true });
    onChangeValue && onChangeValue(e);
  };

  const handleKeyDown = (newValue: string) => {
    setCustomOption({ label: newValue, value: newValue, isCustomOption: true });

    onKeyDown && onKeyDown(newValue);
  };

  const value = watch(name);

  const optionExists = options?.map((o) => o.label.toLowerCase()).includes(customOption.label.toLowerCase());
  const shouldUseProvidedOptionsOnly = customOption.label === '' || optionExists || !allowCustomOption;
  const optionsWithCustomOne = shouldUseProvidedOptionsOnly ? options : [customOption, ...(options ?? [])];

  return (
    <Wrapper>
      <Select
        placeholder={placeholder}
        disabled={disabled}
        label={label}
        isClearable={isClearable}
        name={name}
        value={value}
        onChange={handleChange}
        options={optionsWithCustomOne}
        isLoading={isLoading}
        onInputChange={handleKeyDown}
        noOptionsMessage={noOptionsMessage}
        readonly={readonly}
      />
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </Wrapper>
  );
};
