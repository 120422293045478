import React from 'react';

import { Checkbox } from '../Checkbox/Checkbox';
import IconWithTooltip from '../IconWithTooltip/IconWithTooltip';

import { ICheckboxEntry } from '@common/types';

import * as Styled from './styles';

interface ICheckboxOption {
  item: any;
  name: string;
  isChecked: boolean;
  onChange: (item: ICheckboxEntry) => void;
  tooltipText?: string;
  value?: string;
  disabled?: boolean;
}

export const CheckboxOption = ({ item, name, isChecked = false, onChange, tooltipText, disabled }: ICheckboxOption) => {
  const handleChange = () => {
    onChange(item);
  };
  return (
    <Styled.CheckboxOptionWrap>
      <Checkbox data-testid={item.text} isChecked={isChecked} onChange={handleChange} disabled={disabled} name={name} />
      <Styled.CheckboxOptionText>
        <label>{item.text}</label>
        {tooltipText && <IconWithTooltip tooltipId={name} iconSrc="/icons/info_circle.svg" tooltipText={tooltipText} />}
      </Styled.CheckboxOptionText>
    </Styled.CheckboxOptionWrap>
  );
};
