import styled from 'styled-components';
import { StyledLabel } from '@common/components/Select/styles';
import { TabContentWrapper, TabIcon, TabsWrapper } from '@common/components/Tabs/styles';

export const ProductWrap = styled.div`
  display: flex;
  flex-flow: column;
  flex: 1;
  width: 100%;
  height: 100%;
  overflow: hidden;

  .tabLabelsWrapper {
    padding-left: 20px;
    padding-right: 20px;
  }

  ${TabsWrapper} {
    height: 100%;
  }

  ${TabContentWrapper} {
    height: calc(100% - 20px);
  }

  ${TabIcon} {
    margin-left: 10px;
  }
`;
export const LayoutWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-grow: 1;
  background-color: ${({ theme }) => theme.elements.defaultWhite};
`;

export const TabContentWrap = styled.div`
  display: flex;
  height: 100%;
  padding: 20px 40px;
  background-color: ${({ theme }) => theme.elements.defaultWhite};
`;

export const TabTitle = styled.div`
  display: flex;
  flex-direction: column;
  font-size: ${({ theme }) => theme.elementFontSizes.modalTitle};
  font-weight: bold;
  margin-bottom: 30px;
`;

export const FormRow = styled.div`
  width: 100%;
  display: grid;
  grid-gap: 30px;
  grid-template-columns: repeat(2, 1fr);

  ${StyledLabel} {
    font-size: 14px;
  }
`;

export const SkeletonFormRow = styled(FormRow)`
  grid-template-columns: repeat(1, 1fr);
`;

export const LeftCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  max-width: 75%;

  @media (min-width: 1439px) {
    max-width: 70%;
  }
`;

export const RightCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 20px;
  max-width: 50%;
`;

export const ProductInformation = styled.div``;
