import { useFormContext } from 'react-hook-form';
import { HookFormInput } from '../FormInput/HookFormInput';
import { DocumentType } from '@common/types';
import { Title, Text, InputWrap } from '@common/styles';
import { HttpsPrefix } from '@common/features/document/components/ContentDetailsWeblink/Weblink';
import { generalFormErrors } from '@common/constants';

interface IFormWebLinkInput {
  fieldName: string;
  onClearInput: () => void;
  disabled?: boolean;
  contentType?: DocumentType;
}

const FormWebLinkInput = ({ fieldName, onClearInput, disabled = false, contentType }: IFormWebLinkInput) => {
  const {
    formState: { errors },
  } = useFormContext();

  return (
    <>
      <Title small>Link</Title>
      <Text>Please enter a direct weblink to your {contentType}</Text>
      <InputWrap>
        <HookFormInput
          disabled={disabled}
          label="WebLink"
          labelEndSign="*"
          name={fieldName}
          id="documentWebLinkInput"
          placeholder="Web link"
          onClearInput={onClearInput}
          width={389}
          height={48}
          error={errors[fieldName]?.message?.toString()}
          PrefixElement={HttpsPrefix}
          rules={{
            maxLength: {
              value: 200,
              message: generalFormErrors.documentTitleLength,
            },
            validate: (value) => {
              return value.startsWith('http') || value.startsWith('https')
                ? 'please check that your link does not include https:// or http://'
                : undefined;
            },
          }}
        />
      </InputWrap>
    </>
  );
};

export default FormWebLinkInput;
